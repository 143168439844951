import wait from "@soenergy/frontend-library/src/helpers/wait"
import store from "../store/store.js"

const MAX_TOTAL_WAIT_TIME_MS = 8000
const FIRST_DELAY_MS = 2000
const SUBSEQUENT_DELAY_MS = 3000

const waitForJuniferCacheRefreshToFinish = async ({
  maxTimeout = MAX_TOTAL_WAIT_TIME_MS,
  pollingStartedAt = Date.now(),
  retryCount = 0,
} = {}) => {
  if (!retryCount) await wait(FIRST_DELAY_MS)
  const status = await store.dispatch("fetchBlobRefreshStatus")

  if (status !== "pending" && store.getters.isJuniferDataCurrent) {
    return { status }
  }
  const elapsedTimeMs = Date.now() - pollingStartedAt
  if (maxTimeout && elapsedTimeMs > maxTimeout) {
    return { status: "timeout" }
  } else {
    const delayTime = retryCount > 0 ? SUBSEQUENT_DELAY_MS : FIRST_DELAY_MS
    await wait(delayTime)
    retryCount++

    return waitForJuniferCacheRefreshToFinish({
      maxTimeout,
      pollingStartedAt,
      retryCount,
    })
  }
}

export default waitForJuniferCacheRefreshToFinish
