import dayjs from "dayjs"
import GatewayPortal from "../../services/GatewayPortal"

export const namespaced = true

const getDefaultState = () => {
  return {
    isEligible: false,
    currentPaymentPlan: {
      paymentPlanStatus: null,
      planLength: 0,
      setupDate: null,
      timeRemaining: null,
      paymentDate: null,
      totalDebt: 0,
      debtMonthlyPayment: 0,
      remainingBalance: 0,
    },
  }
}

export const state = getDefaultState()

export const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  SET_CURRENT_PAYMENT_PLAN(state, currentPaymentPlan) {
    state.currentPaymentPlan = currentPaymentPlan
  },
  SET_IS_ELIGIBLE(state, isEligible) {
    state.isEligible = isEligible
  },
}

export const actions = {
  fetchEligibility({ commit }, accountId) {
    return GatewayPortal.getPaymentPlanEligibility(accountId).then(
      ({ data }) => {
        if (!data?.success) return
        commit("SET_IS_ELIGIBLE", data.eligible)
      }
    )
  },
  fetchCurrentPaymentPlan({ commit }, accountId) {
    return GatewayPortal.getCurrentPaymentPlan(accountId).then(({ data }) => {
      if (!data?.success) return
      commit("SET_CURRENT_PAYMENT_PLAN", {
        paymentPlanStatus: data.paymentPlanStatus,
        planLength: data.planLength,
        setupDate: dayjs(data.setupDate),
        timeRemaining: data.timeRemaining,
        paymentDate: dayjs(data.paymentDate),
        totalDebt: Number(data.totalDebt),
        debtMonthlyPayment: Number(data.debtMonthlyPayment),
        remainingBalance: Number(data.remainingBalance),
      })
    })
  },
}

export const getters = {
  hasPaymentPlanPending(state) {
    return state.currentPaymentPlan?.paymentPlanStatus === "pending"
  },
  hasPaymentPlanActive(state) {
    return state.currentPaymentPlan?.paymentPlanStatus === "active"
  },
  isEligible(state, getters) {
    return (
      state.isEligible &&
      !getters.hasPaymentPlanPending &&
      !getters.hasPaymentPlanActive
    )
  },
}
