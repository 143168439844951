import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import minMax from "dayjs/plugin/minMax"
import timezone from "dayjs/plugin/timezone"
import advancedFormat from "dayjs/plugin/advancedFormat"

dayjs.extend(utc)
dayjs.extend(minMax)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

export function formatDate(date, format = "D MMM YYYY") {
  return dayjs(date).format(format)
}

export function formatDateRange(start, end, format = "D MMM YYYY") {
  return `${this.formatDate(start, format)} - ${this.formatDate(end, format)}`
}

export function currentTime() {
  return dayjs().tz("Europe/London")
}

export function getLatestSupplyStartDate(supplyStartDates) {
  return dayjs.max(supplyStartDates.map((date) => dayjs(date)))
}

export function getOldestSupplyStartDate(supplyStartDates) {
  return dayjs.min(supplyStartDates.map((date) => dayjs(date)))
}

export function daysFromStartDate(supplyStartDate) {
  let startOfDay = dayjs(supplyStartDate).startOf("day")
  let minutesFromStartDate = startOfDay.diff(dayjs(), "minutes")
  const minutesInOneDay = 1440

  return minutesFromStartDate / minutesInOneDay
}

export function inCoolingPeriod(accountCreatedOn, supplyStartDate) {
  const created = dayjs(accountCreatedOn)

  return (
    created.isBefore(this.currentTime()) &&
    this.endOfCoolingPeriod(supplyStartDate).isAfter(this.currentTime())
  )
}

export function endOfCoolingPeriod(supplyStartDate) {
  return dayjs
    .tz(supplyStartDate, "Europe/London")
    .subtract(1, "day")
    .set("hour", 17)
}

export function afterSwitchingPeriod(supplyStartDate) {
  const currentDate = dayjs()
  const end = dayjs(supplyStartDate).add(5, "day")

  return currentDate.isAfter(end)
}

export function endOfSwitchingPeriod(supplyStartDate) {
  return dayjs(supplyStartDate).add(5, "day")
}

export function dayOfTheMonth(date) {
  return dayjs(date).format("Do") + " of the month"
}

export const datesMixin = {
  methods: {
    formatDate,
    formatDateRange,
    currentTime,
    getLatestSupplyStartDate,
    getOldestSupplyStartDate,
    daysFromStartDate,
    inCoolingPeriod,
    endOfCoolingPeriod,
    afterSwitchingPeriod,
    endOfSwitchingPeriod,
    dayOfTheMonth,
  },
}
