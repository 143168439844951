import tariffProductHelper from "@/methods/tariffProductHelper"
import { SO_FLEX_GREEN_TARIFF_CODE } from "@/constants"
import dayjs, { Dayjs } from "dayjs"
import minMax from "dayjs/plugin/minMax"
dayjs.extend(minMax)

export interface ITariffRaw {
  display: string
  code: string
  startDate: string
  variable: boolean
  recommended: boolean
  durationMonths: number
  dualFuelDiscount: number
  isFutureSoocTariff: boolean
  futureSoocPricingDate: string
  earlyTerminationCharge: number
  dayUnitRate: number
  nightUnitRate: number
  standardUnitRate: number
  standingCharge: number
  ukGspGroup: string
}

export class Tariff {
  tariffCode: string
  isVariableGreen: boolean
  isVariable: boolean
  tariffVariantCode: string
  tariffName: string
  fullTariffName: string
  tariffDisplayName: string
  isRecommended: boolean
  durationMonths: number
  expectedStartDate: Dayjs
  isFutureSoocTariff: boolean
  futureSoocPricingDate: string
  exitFee: number
  dayUnitRate: number
  nightUnitRate: number
  standardUnitRate: number
  standingCharge: number
  isFixed: boolean

  constructor(rawQuote: ITariffRaw) {
    // Parsing BE response
    this.durationMonths = rawQuote.durationMonths ?? 0

    // tariffCode
    const codeName = rawQuote.code.split("-")[1]
    const isFuture = rawQuote.isFutureSoocTariff ? "-F" : ""
    this.tariffCode = `${codeName}-${this.durationMonths}${isFuture}`

    // tariff type
    this.isVariableGreen = codeName === SO_FLEX_GREEN_TARIFF_CODE
    this.isVariable =
      this.isVariableGreen || rawQuote.variable || !this.durationMonths
    this.isFixed = !this.isVariable && !this.isVariableGreen

    this.tariffVariantCode = rawQuote.code

    let futureStartMonth = ""
    if (rawQuote.futureSoocPricingDate) {
      futureStartMonth = dayjs(rawQuote.futureSoocPricingDate).format("MMM")
    }
    this.isFutureSoocTariff = rawQuote.isFutureSoocTariff
    const nameChunkSplitBySpace = rawQuote.display.split(" ")
    this.tariffName = (
      this.isVariableGreen
        ? `So Flex Green ${futureStartMonth}`
        : `${nameChunkSplitBySpace[0]} ${nameChunkSplitBySpace[1]} ${futureStartMonth}`
    ).trim()
    const nameChunkSplitByDash = rawQuote.display.split("-")
    this.fullTariffName = nameChunkSplitByDash[0].trim()
    this.tariffDisplayName = (
      rawQuote.display ? `${this.fullTariffName} ${futureStartMonth}` : ""
    ).trim()
    this.expectedStartDate = rawQuote.isFutureSoocTariff
      ? dayjs(rawQuote.futureSoocPricingDate)
      : dayjs(rawQuote.startDate)
    this.isRecommended = rawQuote.recommended
    this.exitFee = rawQuote.earlyTerminationCharge
    this.futureSoocPricingDate = rawQuote.futureSoocPricingDate
    this.dayUnitRate = rawQuote.dayUnitRate
    this.nightUnitRate = rawQuote.nightUnitRate
    this.standardUnitRate = rawQuote.standardUnitRate
    this.standingCharge = rawQuote.standingCharge
  }

  get isGas(): boolean {
    return tariffProductHelper.isGas(this.tariffVariantCode)
  }

  get isElec(): boolean {
    return tariffProductHelper.isElec(this.tariffVariantCode)
  }

  get isElecMultiRate(): boolean {
    return tariffProductHelper.isElecMultiRate(this.tariffVariantCode)
  }

  get tariffDurationFormatted(): string {
    if (this.isVariable) return "Variable Tariff"

    if (this.durationMonths % 12 === 0) {
      const durationInYears = ["One", "Two", "Three", "Four", "Five", "Six"][
        Math.trunc(this.durationMonths / 12) - 1
      ]
      return `${durationInYears} Year Tariff`
    }
    return `${this.durationMonths} Month Tariff`
  }
}
