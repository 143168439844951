import dayjs from "dayjs"
import RailsService from "../../services/RailsService.js"
import RegisterInterest from "../../services/RegisterInterest.js"
import SolarQuote from "../../services/SolarQuote.js"
export const namespaced = true

export const state = {
  userDataFetched: false,
  currentEnergySourceVote: null,
  email: null,
  referral: null,
  voucher: null,
  renewals: [],
  meterReadReminder: undefined,
  customer: {},
  cancellations: [],
  brokerAgent: null,
  evCampaign: null,
  lastLoginTime: null,
  showMarketingOptOutBanner: false,
}

export const getters = {
  brokerAgentName(state) {
    return Boolean(state.brokerAgent && state.brokerAgent.length)
  },
  meterReadReminderFrequency(state) {
    return state.meterReadReminder ? state.meterReadReminder.frequency : null
  },
  meterReadReminderAlignment(state) {
    return state.meterReadReminder ? state.meterReadReminder.alignment : null
  },
  meterReadReminderEnabled(state) {
    return !!state.meterReadReminder && state.meterReadReminder.active !== false
  },
  meterReadReminderSendoutDay(state, getters, rootState, rootGetters) {
    const periodStartDate = rootGetters.currentAgreements
      .map((agreement) => agreement.fromDt)
      .sort((a, b) => a.localeCompare(b))[0]
    const periodStartDay = dayjs(periodStartDate).date()
    return periodStartDay - 2 > 0 ? periodStartDay - 2 : 28
  },
}

export const mutations = {
  SET_CURRENT_ENERGY_VOTE(state, current_energy_source_vote) {
    state.currentEnergySourceVote = current_energy_source_vote
  },
  SET_EMAIL(state, email) {
    state.email = email
  },
  SET_VOUCHER(state, voucher) {
    state.voucher = voucher
  },
  SET_REFERRAL(state, referral) {
    state.referral = referral
  },
  SET_RENEWALS(state, renewals) {
    state.renewals = renewals
  },
  SET_METER_READ_REMINDER(state, meterReadReminder) {
    state.meterReadReminder = meterReadReminder
  },
  SET_USER_DATA_FETCHED(state, fetched) {
    state.userDataFetched = fetched
  },
  SET_CUSTOMER_DATA(state, customer) {
    state.customer = customer
  },
  SET_SECONDARY_PHONE_NUMBER(state, phoneNumber) {
    if (state.customer && state.customer.primaryContact) {
      state.customer.primaryContact.phoneNumber2 = phoneNumber
    }
  },
  SET_MARKETING_OPT_OUT(state, value) {
    state.customer.marketingOptOutFl = value
  },
  SET_SHOW_MARKETING_OPT_OUT_BANNER(state, value) {
    state.showMarketingOptOutBanner = value
  },
  SET_SOLAR_INTERESTED_STATUS(state, value) {
    state.customer.solarInterestedStatus = value
  },
  SET_SOLAR_INTERESTED_STATUS_UPDATED_TIME(state, value) {
    state.customer.solarInterestedStatusUpdatedTime = value
  },
  SET_REQUESTED_SOLAR_QUOTE_IN_LAST_SIX_MONTHS(state, value) {
    state.customer.hasRequestedSolarQuoteInLastSixMonths = value
  },
  SET_OUTSTANDING_COMPLAINTS_COUNT(state, value) {
    state.customer.outstandingComplaintsCount = value
  },
  SET_CANCELLATIONS(state, value) {
    state.cancellations = value
  },
  SET_BROKER_AGENT(state, value) {
    state.brokerAgent = value
  },
  SET_EV_CAMPAIGN(state, value) {
    state.evCampaign = value
  },
  SET_LAST_LOGIN_TIME(state, value) {
    state.lastLoginTime = value
  },
}

export const actions = {
  setUserData({ commit }, data) {
    commit("SET_CURRENT_ENERGY_VOTE", data.current_energy_source_vote)
    commit("SET_VOUCHER", data.voucher)
    commit("SET_EMAIL", data.email)
    commit("SET_REFERRAL", data.referral)
    commit("SET_USER_DATA_FETCHED", true)
    commit("SET_RENEWALS", data.renewals)
    commit("SET_CANCELLATIONS", data.cancellations)
    commit("SET_BROKER_AGENT", data.broker_agent)
    commit("SET_EV_CAMPAIGN", data.ev_campaign)
    commit("SET_SHOW_MARKETING_OPT_OUT_BANNER", data.show_banner)
  },
  async updateMeterReadReminder({ state, commit, rootState }, reminderData) {
    if (!state.meterReadReminder) {
      await RailsService.createMeterReadReminder(
        rootState.currentAccount,
        reminderData
      )
    } else {
      await RailsService.updateMeterReadReminder(
        rootState.currentAccount,
        reminderData
      )
    }
    commit("SET_METER_READ_REMINDER", reminderData)
  },
  async fetchMeterReadReminderDetails({ state, commit, rootState }) {
    if (state.meterReadReminder !== undefined) {
      return
    }
    try {
      const { data } = await RailsService.getMeterReadReminder(
        rootState.currentAccount
      )
      commit("SET_METER_READ_REMINDER", data)
    } catch (error) {
      const errorType =
        error.response && error.response.data && error.response.data.message
      if (
        errorType ==
        "No meter read reminder associated with provided account number"
      ) {
        commit("SET_METER_READ_REMINDER", null)
      } else {
        throw error
      }
    }
  },
  updateSecondaryPhoneNumber({ commit }, phoneNumber) {
    commit("SET_SECONDARY_PHONE_NUMBER", phoneNumber)
  },
  async updateMarketingOptOut({ commit }, value) {
    const response = await RailsService.setMarketingPreferences(!value)
    commit("SET_MARKETING_OPT_OUT", value)
    return response
  },
  async fetchSolarInterestedStatus({ commit }, { accountNumber }) {
    try {
      const response = await RegisterInterest.getInterestStatus(accountNumber)
      commit("SET_SOLAR_INTERESTED_STATUS", response.data.interested)
      commit(
        "SET_SOLAR_INTERESTED_STATUS_UPDATED_TIME",
        response.data.updated_at
      )
    } catch (error) {
      commit("SET_SOLAR_INTERESTED_STATUS", null)
    }
  },
  async fetchHasRequestedSolarQuoteInLastSixMonths(
    { commit },
    { accountNumber }
  ) {
    try {
      const now = dayjs().format("YYYY-MM-DDThh:mm:ss")
      const sixMonthsAgo = dayjs()
        .subtract(6, "month")
        .format("YYYY-MM-DDThh:mm:ss")
      const response = await SolarQuote.getHasRequestedQuoteInTimeRange(
        accountNumber,
        {
          from: sixMonthsAgo,
          to: now,
        }
      )
      commit(
        "SET_REQUESTED_SOLAR_QUOTE_IN_LAST_SIX_MONTHS",
        response.data.hasSolarQuote
      )
    } catch (error) {
      commit("SET_REQUESTED_SOLAR_QUOTE_IN_LAST_SIX_MONTHS", null)
    }
  },
  async fetchOutstandingComplaintsCount({ commit }, { accountId }) {
    try {
      const response = await RailsService.getOutstandingComplaintsCount(
        accountId
      )
      commit("SET_OUTSTANDING_COMPLAINTS_COUNT", response.data.total)
    } catch (error) {
      commit("SET_OUTSTANDING_COMPLAINTS_COUNT", null)
    }
  },
}
