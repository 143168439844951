import HttpClient from "@soenergy/frontend-library/src/services/http/HttpClient"
import authenticate from "@soenergy/frontend-library/src/services/http/requestInterceptors/authenticate"
import reportError from "@soenergy/frontend-library/src/services/http/responseErrorInterceptors/reportError"

const apiClient = HttpClient({
  baseURL: process.env.VUE_APP_ACCOUNT_SITE_URL,
  requestInterceptors: [authenticate],
  responseErrorInterceptors: [reportError],
})

export default {
  sendVote(vote) {
    return apiClient.post("api/v1/customer/energy-source-vote", {
      energy_source: vote,
    })
  },
}
