import HttpClient from "@soenergy/frontend-library/src/services/http/HttpClient"
import authenticate from "@soenergy/frontend-library/src/services/http/requestInterceptors/authenticate"
import reportError from "@soenergy/frontend-library/src/services/http/responseErrorInterceptors/reportError"

const apiClient = HttpClient({
  baseURL: process.env.VUE_APP_ACCOUNT_SITE_URL,
  requestInterceptors: [authenticate],
  responseErrorInterceptors: [reportError],
})

export default {
  getApplication() {
    return apiClient.get("/api/v1/gengame/application")
  },
  async isInvited() {
    try {
      const response = await apiClient.get("/api/v1/gengame/invite")
      return response.data.invitation_received
    } catch (error) {
      const notInvited =
        error.response &&
        error.response.data &&
        error.response.data.invitation_received === false
      if (notInvited) {
        return false
      }
      throw error
    }
  },
  async hasApplied() {
    try {
      await this.getApplication()
      return true
    } catch (error) {
      const errorType =
        error.response && error.response.data && error.response.data.error
      if (errorType === "No application found") {
        return false
      }
      throw error
    }
  },
}
