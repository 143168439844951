import RailsService from "../../services/RailsService.js"

export const namespaced = true

const getDefaultState = () => {
  return {
    annualConsumption: [],
    annualConsumptionError: false,
    accountDataBelongs: null,
  }
}

export const state = getDefaultState()

export const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  SET_ANNUAL_CONSUMPTION(state, annualConsumption) {
    state.annualConsumption = annualConsumption
  },
  SET_ANNUAL_CONSUMPTION_ERROR(state, hasError) {
    state.annualConsumptionError = hasError
  },
  SAVE_ACCOUNT(state, accountId) {
    state.accountDataBelongs = accountId
  },
}

export const actions = {
  async fetchEstimatedAnnualConsumption({
    state,
    commit,
    rootState,
    rootGetters,
  }) {
    if (
      rootState.currentAccount === state.accountDataBelongs &&
      state.annualConsumption.length > 0 &&
      !state.annualConsumptionError
    ) {
      return
    }

    const meterpoints = rootGetters["meters/allCurrent"]
    let meterpointIds = []
    for (const id in meterpoints) {
      if (
        meterpoints[id] &&
        meterpoints[id][0] &&
        meterpoints[id][0].endpointID
      ) {
        meterpointIds.push(meterpoints[id][0].endpointID)
      }
    }

    await Promise.all(
      meterpointIds.map((meterpointId) => {
        return RailsService.getEstimatedAnnualUsage(meterpointId)
      })
    )
      .then((response) => {
        if (response) {
          const meterpointUsageArray = response.map((meterpointUsage) => {
            return {
              ...meterpointUsage.data,
            }
          })
          commit("SET_ANNUAL_CONSUMPTION_ERROR", false)
          commit("SET_ANNUAL_CONSUMPTION", meterpointUsageArray)
          commit("SAVE_ACCOUNT", rootState.currentAccount)
        }
      })
      .catch((error) => {
        commit("SET_ANNUAL_CONSUMPTION_ERROR", true)
        throw error
      })
  },
}

export const getters = {
  getAnnualConsumption(state, getters, rootState, rootGetters) {
    return function () {
      const getMeterpointIdentifier = (meterpointId) => {
        if (!meterpointId) {
          return null
        }
        const allMeterpoints = rootGetters["meters/allCurrent"]
        const meterpointFromId = Object.keys(allMeterpoints)
          .map((meterpoint) => {
            return allMeterpoints[meterpoint] &&
              allMeterpoints[meterpoint].length
              ? allMeterpoints[meterpoint][0]
              : null
          })
          .find((meterpoint) => {
            return meterpoint.endpointID === meterpointId
          })

        return meterpointFromId && meterpointFromId.meterpointIdentifier
          ? meterpointFromId.meterpointIdentifier
          : null
      }
      const filteredMeterpoints = state.annualConsumption
        .filter((meterpoint) => meterpoint.success)
        .map((meterpoint) => {
          return {
            identifier: getMeterpointIdentifier(meterpoint.meterpoint_id),
            type: meterpoint.meterpoint_type,
            usage:
              meterpoint.rates && meterpoint.rates.length
                ? meterpoint.rates.reduce((a, b) => a + (b.usage || 0), 0)
                : null,
          }
        })
      const electricityMeterpoints = filteredMeterpoints.filter(
        (meterpoint) => meterpoint.type === "MPAN"
      )
      const gasMeterpoints = filteredMeterpoints.filter(
        (meterpoint) => meterpoint.type === "MPRN"
      )
      const formatMeterpointUsage = (meterpoints) => {
        if (!meterpoints || !meterpoints.length) {
          return null
        }
        return {
          totalUsage: meterpoints.reduce((a, b) => a + (b.usage || 0), 0),
          meterpoints,
        }
      }

      return {
        gas: formatMeterpointUsage(gasMeterpoints),
        electricity: formatMeterpointUsage(electricityMeterpoints),
      }
    }
  },
}
