import dayjs from "dayjs"
import { localStore } from "@soenergy/frontend-library/src/services/StorageFactory"
import { ITransaction } from "@/types/ITransaction"

const PAYMENT_STORE_KEY = "pendingPayments"
const PAYMENT_EXPIRATION_TIME_IN_HOURS = 24

interface Payment {
  amountInPence: number
  reference: string
  createdAt: string
}

type PaymentStoreData = Record<number, Payment>

const getStoredPaymentData = (): PaymentStoreData | null => {
  return JSON.parse(localStore.getItem(PAYMENT_STORE_KEY))
}

const isPaymentExpired = (payment: Payment) => {
  const createdAt = dayjs(payment.createdAt)
  const now = dayjs()
  return now.diff(createdAt, "hour") > PAYMENT_EXPIRATION_TIME_IN_HOURS
}

const deleteStoredPayment = (customerId: number) => {
  const paymentData = getStoredPaymentData()
  if (paymentData?.[customerId]) {
    delete paymentData[customerId]
    localStore.setItem(PAYMENT_STORE_KEY, JSON.stringify(paymentData))
  }
}

const isPaymentProcessed = (
  transactions: ITransaction[],
  paymentReference: string
) => {
  return transactions.some((transaction) =>
    transaction.reference.includes(paymentReference)
  )
}

export const storePayment = (
  customerId: number,
  reference: string,
  amountInPence: number,
  authorizedAt?: string | null
) => {
  const createdAt = authorizedAt
    ? dayjs(authorizedAt).format()
    : dayjs().format()

  const payment: Payment = {
    amountInPence,
    reference,
    createdAt,
  }

  const paymentData = getStoredPaymentData() ?? {}
  paymentData[customerId] = payment
  localStore.setItem(PAYMENT_STORE_KEY, JSON.stringify(paymentData))
}

export const getPendingPayment = (customerId, transactions) => {
  const storedPayment = getStoredPaymentData()?.[customerId]

  if (!storedPayment) return null

  if (isPaymentProcessed(transactions, storedPayment.reference)) {
    deleteStoredPayment(customerId)
    return null
  }

  if (isPaymentExpired(storedPayment)) {
    deleteStoredPayment(customerId)
    return null
  }

  return storedPayment
}
