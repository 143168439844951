import PaymentApiService from "../../services/api/PaymentApiService"
import ErrorReporter from "../../services/ErrorReporter"
import wait from "../../helpers/wait"

export default {
  async requestPayment(paymentPayload) {
    const { data } = await PaymentApiService.requestPayment(paymentPayload)
    return data
  },

  async getPaymentDetails({ ckoSessionId, externalId }) {
    const RETRY_DELAY_MS = 3000
    const MAX_RETRIES = 5

    try {
      let response
      let retry = true
      let attempts = 0

      while (retry) {
        if (ckoSessionId) {
          response = await PaymentApiService.getPaymentDetailsBySessionId(
            ckoSessionId
          )
        } else if (externalId) {
          response = await PaymentApiService.getPaymentDetailsByExternalId(
            externalId
          )
        } else {
          throw new Error("Either ckoSessionId or externalId must be provided.")
        }

        if (response?.data?.status === "PENDING") {
          attempts++
          if (attempts >= MAX_RETRIES) {
            throw new Error(
              "Maximum retries reached. Payment is still pending."
            )
          }
          await wait(RETRY_DELAY_MS)
          continue
        }

        retry = false
      }

      const { reference, authorisedAt, amount } = response.data

      return {
        confirmationNumber: reference,
        amountInPence: amount,
        authorisedAt,
        status: response.data.status
      }
    } catch (error) {
      ErrorReporter.report(error)
      throw error
    }
  }
}
