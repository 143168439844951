<template>
  <Modal :is-modal-open="show" @close-modal="handleModalClose">
    <template #header>
      <h4 class="summary__modal-heading">Do you want to log out?</h4>
    </template>
    <template #footer>
      <AppButton variant="invertedPrimary" @click="handleModalClose">
        Cancel
      </AppButton>
      <AppButton variant="primary" @click="logout"> Yes, log out </AppButton>
    </template>
  </Modal>
</template>

<script>
import Modal from "@soenergy/frontend-library/src/components/Modal"
import AppButton from "@soenergy/frontend-library/src/components/AppButton"
import { clearUser } from "@soenergy/frontend-library/src/services/datadog"

export default {
  components: {
    Modal,
    AppButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleModalClose() {
      this.$store.dispatch("modals/closeLogoutModal")
    },
    logout() {
      this.$store.dispatch("modals/closeLogoutModal")
      this.$router.push("/logout").catch(() => {})
      clearUser()
    },
  },
}
</script>
