import UserStatusApiService from "./UserStatusApiService"
import storeModule from "./storeModule"

let store = null
let moduleName = null

const init = ({ store: applicationStore, storeModuleName = "userStatus" }) => {
  applicationStore.registerModule(storeModuleName, storeModule)
  store = applicationStore
  moduleName = storeModuleName
}

const getUserStatus = async () => {
  const response = await UserStatusApiService.getUserStatus()
  setUserStatus(response.data)
  return response.data
}

const getUserStatusWithToken = async () => {
  const response = await UserStatusApiService.getUserStatusWithToken()
  setUserStatus(response.data)
  return response.data
}

const clearUserStatus = () => {
  store.commit(`${moduleName}/CLEAR_USER_STATUS`)
}

const setUserStatus = value => {
  store.commit(`${moduleName}/SET_USER_STATUS`, value)
}

export default {
  init,
  getUserStatus,
  getUserStatusWithToken,
  clearUserStatus
}
