import GengameService from "@/services/GengameService"

export const namespaced = true

export const state = {
  isEligibleForSurvey: null,
}

export const mutations = {
  SET_ELIGIBLE_FOR_SURVEY(state, eligibleForSurvey) {
    state.isEligibleForSurvey = eligibleForSurvey
  },
}

export const actions = {
  async checkIfEligibleForSurvey({ state, rootGetters, commit }) {
    if (state.isEligibleForSurvey !== null) {
      return state.isEligibleForSurvey
    }
    const featureData = rootGetters["help/getFeatureData"]
    const isEligibleForSurvey = !!(
      featureData.gengamerecruitment &&
      (await GengameService.isInvited()) &&
      !(await GengameService.hasApplied())
    )
    commit("SET_ELIGIBLE_FOR_SURVEY", isEligibleForSurvey)

    return isEligibleForSurvey
  },
}
