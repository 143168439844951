import Bugsnag from "@bugsnag/js"

export default error => {
  const { skipErrorReporting, reportedErrorCodes = [500] } = error.config
  const serverResponse = error.response

  if (!skipErrorReporting && serverResponse) {
    const shouldReportError =
      !reportedErrorCodes || reportedErrorCodes.includes(serverResponse.status)

    if (shouldReportError) {
      Bugsnag.notify(error, event => {
        const serverResponseData = serverResponse && serverResponse.data

        if (serverResponse) {
          event.addMetadata("server response", serverResponse)
        }

        if (serverResponseData) {
          event.addMetadata("server response data", serverResponseData)
        }
      })
    }
  }

  return Promise.reject(error)
}
