import HttpClient from "@soenergy/frontend-library/src/services/http/HttpClient"
import authenticate from "@soenergy/frontend-library/src/services/http/requestInterceptors/authenticate"

const apiClient = HttpClient({
  baseURL: process.env.VUE_APP_API_GATEWAY_URL,
  requestInterceptors: [authenticate],
})

export default {
  registerInterest(accountNumber, interested) {
    return apiClient.post("quotes-interest", {
      account_number: accountNumber,
      interested,
    })
  },
  getInterestStatus(accountNumber) {
    return apiClient.get("quotes-interest/" + accountNumber)
  },
}
