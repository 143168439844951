import dayjs from "dayjs"
import RailsService from "@/services/RailsService"
import { Fuel } from "@soenergy/frontend-library/src/services/Quote/QuoteFormConstants"
import {
  CotMoveWithUs,
  CotMoveWithUsForm,
  CotMoveWithUsPayload,
  CotMoveWithUsRequest,
} from "@/models/CotMoveWithUs"
import { CotAddress } from "@/models/CotAddress"
import TariffQuote from "@soenergy/frontend-library/src/models/TariffQuote"
import registrationHelper from "@soenergy/frontend-library/src/helpers/registrationHelper"
import ErrorReporter from "@soenergy/frontend-library/src/services/ErrorReporter"

const parseFormInfoToPayload = (
  form: CotMoveWithUsForm,
  quote: TariffQuote
): CotMoveWithUsPayload => {
  const parseAddress = (address: CotAddress) => ({
    address_line_1: address.addressLine1,
    address_line_2: address.addressLine2 || "",
    address_line_3: address.addressLine3 || "",
    postcode: address.postcode,
    mpan: address.mpan,
    mprn: address.mprn,
    country_code: "GB",
  })

  const mprn = form.supplyAddress.mprn || form.supplyAddressGas?.mprn
  const payload = {
    move_in_date: dayjs(form.moveInDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
    supply_address: parseAddress({ ...form.supplyAddress, mprn: mprn }),
    direct_debit_details: {
      use_existing_direct_debit: Boolean(form.useExistingDirectDebit),
      bank_account_name: form.bankAccountHolderName,
      bank_account_sort_code: form.sortCode,
      bank_account_number: form.bankAccountNumber,
      payment_amount: Number(quote.monthlyCost()),
    },
    products: [
      {
        code: quote.elecProductCode as string,
        meterPointIdentifiers: [form.supplyAddress.mpan as string],
        type: "ELECTRICITY",
        consumptionEstimation:
          !form.estimatedUsage && form.exactAmountElec?.usage
            ? {
                consumption: Number(form.exactAmountElec.usage),
                period: form.exactAmountElec.period,
              }
            : undefined,
      },
    ],
  }

  if (form.fuel === Fuel.ElecAndGas) {
    payload.products.push({
      code: quote.gasProductCode,
      meterPointIdentifiers: [mprn as string],
      type: "GAS",
      consumptionEstimation:
        !form.estimatedUsage && form.exactAmountGas?.usage
          ? {
              consumption: Number(form.exactAmountGas.usage),
              period: form.exactAmountGas.period,
            }
          : undefined,
    })
  }

  return payload
}

export const getCotMoveWithUs = async () => {
  try {
    const response = await RailsService.fetchCotMoveWithUs()
    if (!response.data) return null

    return new CotMoveWithUs(response.data)
  } catch (error: any) {
    const isExpectedError = error?.response?.status == 400
    if (!isExpectedError) throw error

    return null
  }
}

export const submitCotMoveWithUs = async ({
  form,
  quote,
}: CotMoveWithUsRequest) => {
  if (!form.useExistingDirectDebit) {
    const response = await registrationHelper.validateBankingDetails({
      accountNumber: form.bankAccountNumber,
      accountSortCode: form.sortCode,
    })
    if (response.errors) {
      return { errors: response.errors, success: false }
    }
  }
  try {
    const response = await RailsService.cotMoveWithUs(
      parseFormInfoToPayload(form, quote)
    )
    return { response, success: true }
  } catch (error: any) {
    ErrorReporter.report(error, {}, "error")
    return { success: false }
  }
}

export const cancelCotMoveWithUs = () => RailsService.cancelMoveWithUs()
