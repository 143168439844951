import dayjs, { Dayjs } from "dayjs"
import { CotAddressRaw, CotAddress } from "./CotAddress"

export const CotStatus = {
  SUCCESSFUL: "SUCCESSFUL",
  PENDING: "PENDING",
  FAILED: "FAILED",
} as const
export type CotStatusType = (typeof CotStatus)[keyof typeof CotStatus]

interface CotMoveOutRaw {
  submitted_time: string
  last_date_of_responsibility: string
  status: CotStatusType
  forwarding_address: CotAddressRaw
}

class CotMoveOut {
  createdDate: Dayjs
  lastDateOfResponsibility: Dayjs
  status: CotStatusType
  forwardingAddress: CotAddress

  constructor(rawCotMoveOut: CotMoveOutRaw) {
    this.status = rawCotMoveOut.status
    this.createdDate = dayjs(rawCotMoveOut.submitted_time)
    this.lastDateOfResponsibility = dayjs(
      rawCotMoveOut.last_date_of_responsibility
    )
    this.forwardingAddress = new CotAddress(rawCotMoveOut.forwarding_address)
  }
  get isFailed() {
    return this.status === CotStatus.FAILED
  }
}

export default CotMoveOut
