import dayjs from "dayjs"
import axios from "axios"
import store from "../../store/store.js"
import Rails from "../../services/RailsService.js"

export const namespaced = true

export const state = {
  featureTimeout: 0,
  featureData: {},
  holiday: {
    lastChecked: "",
    isHoliday: false,
  },
  customerServiceOpen: false,
}

export const mutations = {
  SET_TIMEOUT(state, timeoutId) {
    state.featureTimeout = timeoutId
  },
  SET_FEATURE_DATA(state, featureData) {
    state.featureData = featureData
  },
  SET_HOLIDAY_DATA(state, holidayData) {
    state.holiday = Object.assign({}, holidayData)
  },
  SET_OPENINGS(state, customerServiceOpen) {
    state.customerServiceOpen = customerServiceOpen
  },
}

export const actions = {
  getHolidayData({ commit }) {
    axios
      .get("https://www.gov.uk/bank-holidays.json")
      .then((response) => {
        if (response.data) {
          let events = response.data["england-and-wales"].events
          let today = dayjs()
          let dateToday = today.format("YYYY-MM-DD")
          let weekend = today.day() === 0 || today.day() === 6

          let findHoliday = events.filter((event) => event.date === dateToday)
          let holiday = {
            lastChecked: dateToday,
            isHoliday: !!findHoliday.length || weekend,
          }

          commit("SET_HOLIDAY_DATA", holiday)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  },
  setFeatureTimeout({ commit, dispatch }, time) {
    // if we already have a timer and receive nothing: let timer continue
    if (state.featureTimeout !== 0 && typeof time === "undefined") return
    // if timer is set to 0, reset it and dont do any request.
    if (time === 0) {
      clearTimeout(state.featureTimeout)
      commit("SET_TIMEOUT", 0)
      return
    }

    // check if time was undefined, set a number in any case
    let requestTime = isNaN(time) ? 0 : time
    let timeoutId = 0

    // if we dont have timer yet, and time is 0, requets datas now
    if (state.featureTimeout === 0 && requestTime === 0) {
      dispatch("requestFeatureData")
      requestTime = getTimeForFeatureRequest()
    }

    // if we have time start time loop and run until its cleared
    if (requestTime > 0) {
      timeoutId = setTimeout(() => {
        dispatch("requestFeatureData")
        dispatch("setFeatureTimeout", getTimeForFeatureRequest())
      }, requestTime)
      commit("SET_TIMEOUT", timeoutId)
    }
  },
  requestFeatureData({ commit }) {
    Rails.getFeaturesStates()
      .then((response) => {
        if (response.data) {
          commit("SET_FEATURE_DATA", response.data)
        }
      })
      .catch((error) => {
        console.log("There was an error: ", error)
      })
  },
  updateOpenings({ commit }, open) {
    commit("SET_OPENINGS", open)
  },
}

export const getters = {
  getFeatureData(state) {
    return state.featureData
  },
}

const getTimeForFeatureRequest = () => {
  if (state.holiday.isHoliday) {
    return 0
  }

  let timeout = 0
  let timeNow = dayjs()
  const hourNow = dayjs().hour()

  if (hourNow < 9) {
    const opening = dayjs(timeNow.format("YYYY-MM-DD") + " 09:00")
    timeout = opening.diff(timeNow, "milliseconds", true) // time until opening
    store.dispatch("help/updateOpenings", false)
  } else if (hourNow >= 9 && hourNow < 16) {
    // between 9:00 and 15:59
    timeout = 3600000 // one hour
    store.dispatch("help/updateOpenings", true)
  } else if (hourNow === 16) {
    // between 16:00 and 16:59
    const closing = dayjs(timeNow.format("YYYY-MM-DD") + " 17:00")
    timeout = closing.diff(timeNow, "milliseconds", true) // time until closing
    store.dispatch("help/updateOpenings", true)
  } else {
    timeout = 0
    store.dispatch("help/updateOpenings", false)
  }

  return timeout
}
