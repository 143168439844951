/* eslint-disable no-undef */
import ErrorReporter from "@soenergy/frontend-library/src/services/ErrorReporter"
import EventBus from "@/methods/eventBus"

export const handleCriticalError = (error, title) => {
  const message =
    error.response && error.response.statusText
      ? `${title}: ${error.response.statusText}`
      : error.message

  ErrorReporter.report(error, { title, message })
  if (message === "Network Error") {
    // show network error page
    EventBus.emit("goToNetworkErrorPage")
  } else {
    showGenericErrorPage()
    console.error(error)
  }
}
