import renewalStatusHelper from "@soenergy/frontend-library/src/helpers/renewalStatusHelper"
import dayjs from "dayjs"
import store from "../store/store.js"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
dayjs.extend(isSameOrAfter)

const meterReadBannerDate = (
  dateToday,
  lastReadMinMax,
  anyOutOfContract,
  hasActiveAgreements
) => {
  if (lastReadMinMax?.max && (anyOutOfContract || hasActiveAgreements)) {
    const lastMeterRead = dayjs(lastReadMinMax.max).startOf("day")

    const oneMonth = lastMeterRead.isSameOrAfter(
      dayjs(dateToday).subtract(31, "day")
    )
    const ninetyDays = lastMeterRead.isSameOrAfter(
      dayjs(dateToday).subtract(90, "day")
    )

    if (!oneMonth && ninetyDays) {
      return "oneMonthBanner"
    } else if (!oneMonth && !ninetyDays) {
      return "ninetyDayBanner"
    } else return
  }
}

const updateRenewDays = (dateToday, agreementsArray) => {
  let renewDays
  agreementsArray.some((agreement) => {
    const firstBanner = dayjs(agreement.toDt).subtract(
      store.state.agreements.renewalDays,
      "day"
    )
    const secondBanner = dayjs(agreement.toDt).subtract(30, "day")
    const toDate = dayjs(agreement.toDt)

    if (
      !agreement.hasFutureAgreement &&
      !agreement.isVariable &&
      dayjs(dateToday).isAfter(dayjs(secondBanner), "minute") &&
      dayjs(dateToday).isBefore(dayjs(toDate).endOf("day"))
    ) {
      renewDays = "thirtyDayRenewBanner"
    } else if (
      !agreement.hasFutureAgreement &&
      !agreement.isVariable &&
      dayjs(dateToday).isAfter(dayjs(firstBanner), "minute") &&
      dayjs(dateToday).isBefore(dayjs(secondBanner), "minute")
    ) {
      renewDays = "fiftyFourDayRenewBanner"
    }
    if (renewDays) {
      let dayDifference = dayjs(agreement.toDt)
        .endOf("day")
        .diff(dayjs(dateToday), "day")
      if (dayDifference === 0) {
        store.commit("home/SET_DAYS_TO_RENEW", "today!")
      } else if (dayDifference === 1) {
        store.commit("home/SET_DAYS_TO_RENEW", "tomorrow!")
      } else {
        store.commit("home/SET_DAYS_TO_RENEW", "in " + dayDifference + " days!")
      }
    }
  })
  return renewDays
}

const hasUpcomingSmartMeterBooking = (date) => {
  return dayjs(date).isSameOrAfter(dayjs(), "date")
}

const renewReminders = (dateToday, agreementsArray, eligibleForRenewal) => {
  if (eligibleForRenewal) {
    return updateRenewDays(dateToday, agreementsArray)
  }
}

const hasSmartMeterBooking = (
  smbpData,
  anyOutOfContract,
  hasActiveAgreements
) => {
  return (
    (anyOutOfContract || hasActiveAgreements) &&
    smbpData?.booked &&
    smbpData?.bookingData?.date &&
    smbpData?.bookingData?.startTime &&
    hasUpcomingSmartMeterBooking(smbpData?.bookingData?.date)
  )
}

const isSeasonalPaymentPlan = (
  hasSeasonalPaymentPlan,
  dateToday,
  hasActiveAgreements,
  anyOutOfContract
) => {
  let seasonalBannerMonth
  if (
    hasSeasonalPaymentPlan &&
    (hasActiveAgreements || anyOutOfContract) &&
    dateToday.includes("Mar")
  ) {
    seasonalBannerMonth = "marchBanner"
  } else if (
    hasSeasonalPaymentPlan &&
    (hasActiveAgreements || anyOutOfContract) &&
    dateToday.includes("Sep")
  ) {
    seasonalBannerMonth = "septBanner"
  }
  return seasonalBannerMonth
}

const showFriendReferBanner = (
  hasActiveAgreements,
  anyOutOfContract,
  agreementsArray,
  smbpData,
  lastReadMinMax,
  hasSeasonalPaymentPlan,
  dateToday,
  isInFirstReadingPeriod,
  hasActiveDirectDebit,
  isSwitchComplete,
  isProspectSwitchJourneyEnabled,
  eligibleForRenewal,
  isEligibleForSmartMeter,
  isSwitchingAway
) => {
  const fiftyFourDayRenewBanner =
    renewReminders(dateToday, agreementsArray, eligibleForRenewal) ===
    "fiftyFourDayRenewBanner"
  const smartMeterBooking = hasSmartMeterBooking(
    smbpData,
    anyOutOfContract,
    hasActiveAgreements
  )
  const oneMonthReadBanner =
    meterReadBannerDate(
      dateToday,
      lastReadMinMax,
      anyOutOfContract,
      hasActiveAgreements
    ) === "oneMonthBanner"
  const seasonalBanner = isSeasonalPaymentPlan(
    hasSeasonalPaymentPlan,
    dateToday,
    anyOutOfContract,
    hasActiveAgreements
  )

  return (
    (hasActiveAgreements || anyOutOfContract) &&
    !fiftyFourDayRenewBanner &&
    !isEligibleForSmartMeter &&
    !smartMeterBooking &&
    !oneMonthReadBanner &&
    !seasonalBanner &&
    !isSwitchingAway &&
    !isInFirstReadingPeriod &&
    hasActiveDirectDebit &&
    !isSwitchComplete &&
    isProspectSwitchJourneyEnabled
  )
}

const showEnergySourceBanner = (
  currentEnergySourceVote,
  hasActiveAgreements,
  anyOutOfContract,
  agreementsArray,
  smbpData,
  lastReadMinMax,
  hasSeasonalPaymentPlan,
  dateToday,
  isInFirstReadingPeriod,
  hasActiveDirectDebit,
  isSwitchComplete,
  isProspectSwitchJourneyEnabled,
  eligibleForRenewal,
  isEligibleForSmartMeter,
  isSwitchingAway
) => {
  const displayFriendReferBanner = showFriendReferBanner(
    hasActiveAgreements,
    anyOutOfContract,
    agreementsArray,
    smbpData,
    lastReadMinMax,
    hasSeasonalPaymentPlan,
    dateToday,
    isInFirstReadingPeriod,
    hasActiveDirectDebit,
    isSwitchComplete,
    isProspectSwitchJourneyEnabled,
    eligibleForRenewal,
    isEligibleForSmartMeter,
    isSwitchingAway
  )
  return (
    currentEnergySourceVote === null &&
    ((displayFriendReferBanner &&
      document.cookie.indexOf(
        "myaccount_banner-dismissal_friend-referral-banner"
      ) != -1) ||
      !isProspectSwitchJourneyEnabled)
  )
}

const directDebitBanner = {
  id: "set-up-direct-debit-banner",
  cookieName: "myaccount_banner-dismissal_set-up-direct-debit-banner",
  shouldShow: ({
    hasActiveDirectDebit,
    hasPendingDirectDebit,
    hasActiveAgreements,
    anyOutOfContract,
    hasNonDDTariff,
    hasCancelledDirectDebit,
    isSwitchStopped,
  }) =>
    !hasActiveDirectDebit &&
    !hasPendingDirectDebit &&
    (hasActiveAgreements || anyOutOfContract) &&
    !hasNonDDTariff &&
    !hasCancelledDirectDebit &&
    !isSwitchStopped,
}

const nonDDFullscreenDirectDebitBanner = {
  id: "non-dd-fullscreen-direct-debit-banner",
  cookieName:
    "myaccount_banner-dismissal_non-dd-fullscreen-direct-debit-banner",
  shouldShow: ({
    hasNonDDTariff,
    hasActiveDirectDebit,
    hasPendingDirectDebit,
    isSwitchStopped,
  }) =>
    hasNonDDTariff &&
    !hasActiveDirectDebit &&
    !hasPendingDirectDebit &&
    !isSwitchStopped,
}

const nonDDdirectDebitBanner = {
  id: "non-dd-direct-debit-banner",
  cookieName: "myaccount_banner-dismissal_non-dd-direct-debit-banner",
  shouldShow: ({
    hasNonDDTariff,
    hasActiveDirectDebit,
    hasPendingDirectDebit,
    isSwitchStopped,
  }) =>
    hasNonDDTariff &&
    !hasActiveDirectDebit &&
    !hasPendingDirectDebit &&
    !isSwitchStopped,
}

const cancelledDirectDebitBanner = {
  id: "cancelled-dd-fullscreen-banner",
  cookieName: "myaccount_banner-dismissal_cancelled-dd-fullscreen-banner",
  shouldShow: ({
    hasCancelledDirectDebit,
    hasNonDDTariff,
    hasPendingDirectDebit,
    hasActiveDirectDebit,
    isSwitchStopped,
  }) =>
    hasCancelledDirectDebit &&
    !hasNonDDTariff &&
    !hasPendingDirectDebit &&
    !hasActiveDirectDebit &&
    !isSwitchStopped,
}

const renewThirtyDayBanner = {
  id: "renew-thirty-day-banner",
  cookieName: "myaccount_banner-dismissal_renew-thirty-day-banner",
  shouldShow: ({
    canRenewOnFutureTariff,
    dateToday,
    agreementsArray,
    eligibleForRenewal,
  }) =>
    !canRenewOnFutureTariff &&
    renewReminders(dateToday, agreementsArray, eligibleForRenewal) ===
      "thirtyDayRenewBanner",
}

const renewFiftyFourDayBanner = {
  id: "renew-fifty-four-days-banner",
  cookieName: "myaccount_banner-dismissal_renew-fifty-four-days-banner",
  shouldShow: ({
    canRenewOnFutureTariff,
    dateToday,
    agreementsArray,
    eligibleForRenewal,
  }) => {
    let reminderType = renewReminders(
      dateToday,
      agreementsArray,
      eligibleForRenewal
    )
    return (
      !canRenewOnFutureTariff &&
      (reminderType === "fiftyFourDayRenewBanner" ||
        reminderType === "thirtyDayRenewBanner")
    )
  },
}

const soocBanner = {
  id: "sooc-banner",
  cookieName: "myaccount_banner-dismissal_sooc-banner",
  shouldShow: ({ anyOutOfContract, user, accountNumber }) =>
    anyOutOfContract &&
    !renewalStatusHelper.hasPendingRenewal(user, accountNumber),
}

const openingReadsSmartMeterBanner = {
  id: "omr-smart-meter-banner",
  shouldShow: ({
    isInFirstReadingPeriod,
    hasSmartMeter,
    hasLegacyMeter,
    hasFirstReads,
    hasLegacyMeterMissingFirstReads,
  }) => {
    if (!isInFirstReadingPeriod || !hasSmartMeter) return false

    const hasOnlySmartMeters = !hasLegacyMeter // all meters are smart meters (regardless if submitted or not)
    const hasLegacyMeterWithReadsSubmitted =
      !hasFirstReads && !hasLegacyMeterMissingFirstReads && hasLegacyMeter // has a mixture of smart & legacy meters, but only smart missing reads

    return hasOnlySmartMeters || hasLegacyMeterWithReadsSubmitted
  },
}

const openingReadsSubmittedLegacyMeterBanner = {
  id: "omr-submitted-all-legacy-meter-banner",
  shouldShow: ({ isInFirstReadingPeriod, hasFirstReads, hasLegacyMeter }) =>
    isInFirstReadingPeriod && hasFirstReads && hasLegacyMeter,
}

// Banner shown within OMR window when first reads are missing on all fuels
// if at least one meter is legacy
const openingReadsAllMissingLegacyMeterBanner = {
  id: "omr-missing-both-fuels-legacy-meter-banner",
  shouldShow: ({
    isInFirstReadingPeriod,
    hasLegacyMeterMissingFirstReads,
    hasFirstReadsForElectricity,
    hasFirstReadsForGas,
  }) =>
    isInFirstReadingPeriod &&
    hasLegacyMeterMissingFirstReads &&
    !hasFirstReadsForGas &&
    !hasFirstReadsForElectricity,
}

// Banner shown within OMR window when first reads are submitted for one fuel
// but missing on another and if at least one meter is legacy
const openingReadsOneFuelMissingLegacyMeterBanner = {
  id: "omr-one-fuel-submitted-one-outstanding-legacy-meter-banner",
  shouldShow: ({
    isInFirstReadingPeriod,
    hasLegacyMeterMissingFirstReads,
    hasFirstReadsForElectricity,
    hasFirstReadsForGas,
  }) =>
    isInFirstReadingPeriod &&
    hasLegacyMeterMissingFirstReads &&
    (hasFirstReadsForGas || hasFirstReadsForElectricity),
}

const smartReadsFailedBanner = {
  id: "smart-meter-reads-banner",
  cookieName: "myaccount_banner-dismissal_smart-meter-reads-banner",
  shouldShow: ({
    hasNonCommunicatingSmartMeter,
    dateToday,
    lastReadMinMax,
    anyOutOfContract,
    hasActiveAgreements,
  }) =>
    hasNonCommunicatingSmartMeter &&
    !!meterReadBannerDate(
      dateToday,
      lastReadMinMax,
      anyOutOfContract,
      hasActiveAgreements
    ),
}

const ninetyDayReadReminderBanner = {
  id: "ninety-day-reading-reminder-banner",
  cookieName: "myaccount_banner-dismissal_ninety-day-reading-reminder-banner",
  shouldShow: ({
    dateToday,
    lastReadMinMax,
    anyOutOfContract,
    hasActiveAgreements,
  }) =>
    meterReadBannerDate(
      dateToday,
      lastReadMinMax,
      anyOutOfContract,
      hasActiveAgreements
    ) == "ninetyDayBanner",
}

const oneMonthReadReminderBanner = {
  id: "one-month-read-banner",
  cookieName: "myaccount_banner-dismissal_one-month-read-banner",
  shouldShow: ({
    dateToday,
    lastReadMinMax,
    anyOutOfContract,
    hasActiveAgreements,
  }) =>
    meterReadBannerDate(
      dateToday,
      lastReadMinMax,
      anyOutOfContract,
      hasActiveAgreements
    ) == "oneMonthBanner",
}

const summerSeasonalPaymentsBanner = {
  id: "summer-seasonal-payments-banner",
  cookieName: "myaccount_banner-dismissal_summer-seasonal-payments-banner",
  shouldShow: ({
    hasSeasonalPaymentPlan,
    dateToday,
    anyOutOfContract,
    hasActiveAgreements,
  }) =>
    isSeasonalPaymentPlan(
      hasSeasonalPaymentPlan,
      dateToday,
      anyOutOfContract,
      hasActiveAgreements
    ) == "marchBanner",
}

const winterSeasonalPaymentsBanner = {
  id: "winter-seasonal-payments-banner",
  cookieName: "myaccount_banner-dismissal_winter-seasonal-payments-banner",
  shouldShow: ({
    hasSeasonalPaymentPlan,
    dateToday,
    anyOutOfContract,
    hasActiveAgreements,
  }) =>
    isSeasonalPaymentPlan(
      hasSeasonalPaymentPlan,
      dateToday,
      anyOutOfContract,
      hasActiveAgreements
    ) == "septBanner",
}

const directDebitSwitchBanner = {
  id: "direct-debit-switch-banner",
  cookieName: "myaccount_banner-dismissal_direct-debit-switch-banner",
  shouldShow: ({ isSwitchingAway }) => isSwitchingAway,
}

const smartMeterEligibilityBanner = {
  id: "smart-meter-banner",
  cookieName: "myaccount_banner-dismissal_smart-meter-banner",
  shouldShow: ({ isEligibleForSmartMeter }) => isEligibleForSmartMeter,
}

const hasSmartMeterAppointmentBookedBanner = {
  id: "smart-meter-booking-banner",
  cookieName: "myaccount_banner-dismissal_smart-meter-booking-banner",
  shouldShow: ({ smbpData, anyOutOfContract, hasActiveAgreements }) =>
    hasSmartMeterBooking(smbpData, anyOutOfContract, hasActiveAgreements),
}

const accountClosedBanner = {
  id: "account-closed-banner",
  cookieName: "myaccount_banner-dismissal_account-closed-banner",
  shouldShow: ({ hasActiveAgreements, accountOpen }) =>
    !accountOpen && !hasActiveAgreements,
}

const friendReferBanner = {
  id: "friend-referral-banner",
  cookieName: "myaccount_banner-dismissal_friend-referral-banner",
  shouldShow: ({
    hasActiveAgreements,
    anyOutOfContract,
    agreementsArray,
    smbpData,
    lastReadMinMax,
    hasSeasonalPaymentPlan,
    dateToday,
    isInFirstReadingPeriod,
    hasActiveDirectDebit,
    isSwitchComplete,
    isProspectSwitchJourneyEnabled,
    eligibleForRenewal,
    isEligibleForSmartMeter,
    isSwitchingAway,
  }) => {
    return showFriendReferBanner(
      hasActiveAgreements,
      anyOutOfContract,
      agreementsArray,
      smbpData,
      lastReadMinMax,
      hasSeasonalPaymentPlan,
      dateToday,
      isInFirstReadingPeriod,
      hasActiveDirectDebit,
      isSwitchComplete,
      isProspectSwitchJourneyEnabled,
      eligibleForRenewal,
      isEligibleForSmartMeter,
      isSwitchingAway
    )
  },
}

const energySourceVoteBanner = {
  id: "energy-source-vote",
  cookieName: "myaccount_banner-dismissal_energy-source-vote",
  shouldShow: ({
    currentEnergySourceVote,
    hasActiveAgreements,
    anyOutOfContract,
    agreementsArray,
    smbpData,
    lastReadMinMax,
    hasSeasonalPaymentPlan,
    dateToday,
    isInFirstReadingPeriod,
    hasActiveDirectDebit,
    isSwitchComplete,
    isProspectSwitchJourneyEnabled,
    eligibleForRenewal,
    isEligibleForSmartMeter,
    isSwitchingAway,
  }) => {
    return showEnergySourceBanner(
      currentEnergySourceVote,
      hasActiveAgreements,
      anyOutOfContract,
      agreementsArray,
      smbpData,
      lastReadMinMax,
      hasSeasonalPaymentPlan,
      dateToday,
      isInFirstReadingPeriod,
      hasActiveDirectDebit,
      isSwitchComplete,
      isProspectSwitchJourneyEnabled,
      eligibleForRenewal,
      isEligibleForSmartMeter,
      isSwitchingAway
    )
  },
}

const switchCompleteBanner = {
  id: "switch-complete-banner",
  cookieName: "myaccount_banner-dismissal_switch-complete-banner",
  shouldShow: ({ isSwitchComplete }) => isSwitchComplete,
}

const switchInProgressBanner = {
  id: "switch-in-progress-banner",
  cookieName: "myaccount_banner-dismissal_switch-in-progress-banner",
  shouldShow: ({ isSwitchInProgress }) => isSwitchInProgress,
}

const evChargerInstallationBanner = {
  id: "ev-charger-installation-banner",
  shouldShow: ({ isEligibleForEvCampaign }) => isEligibleForEvCampaign,
}

const whdBanner = {
  id: "whd-banner",
  shouldShow: ({
    featureData,
    accountOpen,
    hasElectricity,
    isWHDRegionEligible,
  }) =>
    featureData.warmhomediscount &&
    accountOpen &&
    hasElectricity &&
    isWHDRegionEligible,
}

const fsdtBanner = {
  id: "fsdt-banner",
  cookieName: "myaccount_banner-dismissal_fsdt-banner",
  shouldShow: ({
    canRenewOnFutureTariff,
    hasTooHighDebtToRenewOnFutureTariff,
    isProspectSwitchJourneyEnabled,
    eligibleForRenewal,
  }) =>
    canRenewOnFutureTariff &&
    !hasTooHighDebtToRenewOnFutureTariff &&
    isProspectSwitchJourneyEnabled &&
    eligibleForRenewal,
}

const fsdtFullscreenBanner = {
  id: "fsdt-fullscreen-banner",
  cookieName: "myaccount_banner-dismissal_fsdt-fullscreen-banner",
  shouldShow: ({
    canRenewOnFutureTariff,
    hasTooHighDebtToRenewOnFutureTariff,
    isProspectSwitchJourneyEnabled,
    eligibleForRenewal,
  }) =>
    canRenewOnFutureTariff &&
    !hasTooHighDebtToRenewOnFutureTariff &&
    isProspectSwitchJourneyEnabled &&
    eligibleForRenewal,
}

const marketingBanner = {
  id: "marketing-opt-in-banner",
  cookieName: "myaccount_banner-dismissal_marketing-opt-in-banner",
  shouldShow: ({ marketingOptOutBanner }) => marketingOptOutBanner,
  action: () => store.dispatch("user/updateMarketingOptOut", false),
}

const smartMeterFullscreenBanner = {
  id: "smart-meter-fullscreen-banner",
  cookieName: "myaccount_banner-dismissal_smart-meter-fullscreen-banner",
  shouldShow: ({ isEligibleForSmartMeter }) => isEligibleForSmartMeter,
}

const paymentPlanEligibleFullscreenBanner = {
  id: "payment-plan-eligible-fullscreen-banner",
  cookieName:
    "myaccount_banner-dismissal_payment-plan-eligible-fullscreen-banner",
  shouldShow: ({ isEligibleForPaymentPlan }) => isEligibleForPaymentPlan,
}

const HOME_PAGE_BANNERS = [
  directDebitBanner,
  renewThirtyDayBanner,
  renewFiftyFourDayBanner,
  soocBanner,
  openingReadsSmartMeterBanner,
  openingReadsSubmittedLegacyMeterBanner,
  openingReadsAllMissingLegacyMeterBanner,
  openingReadsOneFuelMissingLegacyMeterBanner,
  smartReadsFailedBanner,
  ninetyDayReadReminderBanner,
  oneMonthReadReminderBanner,
  summerSeasonalPaymentsBanner,
  winterSeasonalPaymentsBanner,
  directDebitSwitchBanner,
  smartMeterEligibilityBanner,
  hasSmartMeterAppointmentBookedBanner,
  accountClosedBanner,
  friendReferBanner,
  energySourceVoteBanner,
  switchCompleteBanner,
  switchInProgressBanner,
  evChargerInstallationBanner,
  whdBanner,
  fsdtBanner,
  fsdtFullscreenBanner,
  marketingBanner,
  smartMeterFullscreenBanner,
  nonDDFullscreenDirectDebitBanner,
  nonDDdirectDebitBanner,
  cancelledDirectDebitBanner,
  paymentPlanEligibleFullscreenBanner,
]

export default (bannerData) => {
  const filteredBanners = []
  HOME_PAGE_BANNERS.forEach(({ cookieName, action, id, shouldShow }) => {
    cookieName = cookieName || `myaccount_banner-dismissal_${id}`
    const storyblokBanner = bannerData.allBanners.find(
      (storyblokBanner) => storyblokBanner.banner_id === id
    )
    if (storyblokBanner) {
      const shoudShow = shouldShow({
        allBanners: bannerData.allBanners,
        hasSeasonalPaymentPlan: bannerData.hasSeasonalPaymentPlan,
        agreementsArray: bannerData.agreementsArray,
        anyOutOfContract: bannerData.anyOutOfContract,
        hasActiveAgreements: bannerData.hasActiveAgreements,
        supplyStatus: bannerData.supplyStatus,
        smbpData: bannerData.smbpData,
        currentEnergySourceVote: bannerData.currentEnergySourceVote,
        hasActiveDirectDebit: bannerData.hasActiveDirectDebit,
        hasPendingDirectDebit: bannerData.hasPendingDirectDebit,
        isInFirstReadingPeriod: bannerData.isInFirstReadingPeriod,
        hasFirstReads: bannerData.hasFirstReads,
        hasLegacyMeter: bannerData.hasLegacyMeter,
        hasLegacyMeterMissingFirstReads:
          bannerData.hasLegacyMeterMissingFirstReads,
        hasFirstReadsForGas: bannerData.hasFirstReadsForGas,
        hasFirstReadsForElectricity: bannerData.hasFirstReadsForElectricity,
        lastReadMinMax: bannerData.lastReadMinMax,
        dateToday: bannerData.dateToday,
        variables: bannerData.variables,
        isSwitchComplete: bannerData.isSwitchComplete,
        isSwitchInProgress: bannerData.isSwitchInProgress,
        isSwitchStopped: bannerData.isSwitchStopped,
        hasSmartMeter: bannerData.hasSmartMeter,
        hasNonCommunicatingSmartMeter: bannerData.hasNonCommunicatingSmartMeter,
        user: bannerData.user,
        accountNumber: bannerData.accountNumber,
        accountOpen: bannerData.accountOpen,
        isProspectSwitchJourneyEnabled:
          bannerData.isProspectSwitchJourneyEnabled,
        isEligibleForEvCampaign: bannerData.isEligibleForEvCampaign,
        featureData: bannerData.featureData,
        hasElectricity: bannerData.hasElectricity,
        isWHDRegionEligible: bannerData.isWHDRegionEligible,
        canRenewOnFutureTariff: bannerData.canRenewOnFutureTariff,
        hasTooHighDebtToRenewOnFutureTariff:
          bannerData.hasTooHighDebtToRenewOnFutureTariff,
        eligibleForRenewal: bannerData.eligibleForRenewal,
        hasNonDDTariff: bannerData.hasNonDDTariff,
        hasCancelledDirectDebit: bannerData.hasCancelledDirectDebit,
        isEligibleForPaymentPlan: bannerData.isEligibleForPaymentPlan,
        isEligibleForSmartMeter: bannerData.isEligibleForSmartMeter,
        isSwitchingAway: bannerData.isSwitchingAway,
        marketingOptOutBanner: bannerData.marketingOptOutBanner,
      })

      if (shoudShow) {
        filteredBanners.push({
          ...storyblokBanner,
          cookieName,
          action,
        })
      }
    }
  })

  return filteredBanners
}
