export const headerNavItems = [
  {
    title: "",
    navLinks: [
      {
        link: "/renew",
        name: "Renewal",
        component: "NavItemAccountApp"
      },
      {
        link: "/renew?fixed-contract",
        name: "Fixed Contract",
        component: "NavItemAccountApp"
      },
      {
        link: "/",
        name: "My So Account",
        component: "NavItemAccountApp"
      },
      {
        link: "/readings",
        name: "Submit a Reading",
        component: "NavItemAccountApp"
      },
      {
        link: "/balance",
        name: "Balance & Statements",
        component: "NavItemAccountApp"
      },
      {
        link: "/payments",
        name: "Payments",
        component: "NavItemAccountApp"
      },
      {
        link: "/choose",
        name: "Change Account",
        component: "NavItemAccountApp"
      },
      {
        link: "/usage",
        name: "My Energy Usage",
        component: "NavItemAccountApp"
      },
      {
        link: "/personal-details",
        name: "My Personal Details",
        component: "NavItemAccountApp"
      },
      {
        link: "/tariff",
        name: "My So Tariff",
        component: "NavItemAccountApp"
      }
    ],
    navTitle: [
      {
        link: "",
        name: "Account",
        component: "NavItemAccount"
      }
    ],
    hideWhenLoggedIn: false,
    hideWhenLoggedOut: true
  },
  {
    title: "",
    navTitle: [
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/energy/quote`,
        name: "Get a quote",
        component: "NavItem"
      }
    ],
    showAsButton: true,
    hideWhenLoggedIn: true,
    hideWhenLoggedOut: false
  },
  {
    title: "",
    navLinks: [],
    navTitle: [
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/help/moving`,
        name: "Moving Home",
        component: "NavItem"
      },
      {
        link: "https://soenergy.typeform.com/to/rPtlwK",
        name: "Moving home",
        component: "NavItem"
      }
    ],
    tracking: [],
    component: "Nav",
    hideWhenLoggedIn: false,
    hideWhenLoggedOut: false
  },
  {
    title: "",
    navLinks: [],
    navTitle: [
      {
        link: "/move-out/status",
        name: "Moving Home",
        component: "NavItemAccountApp"
      }
    ],
    tracking: [],
    component: "Nav",
    hideWhenLoggedIn: false,
    hideWhenLoggedOut: true
  },
  {
    title: "",
    navLinks: [],
    navTitle: [
      {
        link: "/readings",
        name: "Meter Readings",
        component: "NavItemAccountApp"
      }
    ],
    tracking: [],
    component: "Nav",
    hideWhenLoggedIn: false,
    hideWhenLoggedOut: false
  },
  {
    title: "",
    navLinks: [
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/about-us`,
        name: "About So Energy",
        component: "NavItem"
      },
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/about-us/reviews`,
        name: "Customer Reviews",
        component: "NavItem"
      },
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/news`,
        name: "News & Views",
        component: "NavItem"
      },
      {
        link: "https://careers.so.energy/",
        name: "Work for So",
        component: "NavItem"
      },
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/our-energy`,
        name: "Our Energy",
        component: "NavItem"
      }
    ],
    navTitle: [
      {
        link: "",
        name: "About us",
        component: "NavItem"
      }
    ],
    hideWhenLoggedIn: false,
    hideWhenLoggedOut: false
  },
  {
    title: "",
    navLinks: [
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/support/home`,
        name: "Get Support",
        component: "NavItem"
      },
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/energy/saving`,
        name: "Energy Saving Tips",
        tracking: [],
        component: "NavItem"
      },
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/help/unhappy`,
        name: "Unhappy with Us?",
        component: "NavItem"
      },
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/help/emergency`,
        name: "Emergency",
        component: "NavItem"
      },
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/energy/price-cap`,
        name: "Price Cap",
        component: "NavItem"
      },
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/troubleshooting-ihd`,
        name: "IHD Troubleshooting",
        component: "NavItem"
      }
    ],
    navTitle: [
      {
        link: "",
        name: "Help",
        component: "NavItem"
      }
    ],
    fontColor: "#1A1A1A",
    backgroundColor: "#939393",
    hideWhenLoggedIn: false,
    hideWhenLoggedOut: false
  },
  {
    title: "",
    navLinks: [
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/solar`,
        name: "Solar",
        tracking: [
          {
            area: "",
            name: "Click - Solar",
            gaLabel: "",
            section: "",
            gaAction: "",
            component: "AnalyticsEvent",
            gaCategory: "",
            mixpanelName: "Click - Solar",
            captureCurrentPage: true
          }
        ],
        component: "NavItem",
        hideForSites: []
      },
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/heating/so-home-cover`,
        name: "Home Cover",
        component: "NavItemAccountApp"
      },
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/heating/so-heat-pumps`,
        name: "Heat Pumps",
        component: "NavItem"
      },
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/energy/smart-meters`,
        name: "Smart Meters",
        component: "NavItem"
      }
    ],
    navTitle: [
      {
        link: "",
        name: "Products"
      }
    ],
    hideWhenLoggedIn: false,
    hideWhenLoggedOut: false
  }
]
