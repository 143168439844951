import { handleCriticalError } from "@/methods/handleErrors"
import Rails from "@/services/RailsService.js"
import TariffService from "@/services/TariffService"
import tariffProductHelper from "../../methods/tariffProductHelper"

export const namespaced = true

const getDefaultState = () => {
  return {
    tariffAgreements: [],
    isOutOfContractRenewal: false,
  }
}

export const state = getDefaultState()

export const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  SET_TARIFF_AGREEMENTS(state, agreements) {
    state.tariffAgreements = Object.assign([], agreements)
  },
  SET_OUT_OF_CONTRACT_RENEWAL(state, outOfContractRenewal) {
    state.isOutOfContractRenewal = outOfContractRenewal
  },
}

export const actions = {
  async fetchTariffs({ getters }, agreement) {
    let result
    let fuelPromises = []

    let getData = (fuel, gspGroup, tariffKey) => {
      let tariff = {
        type: fuel.type,
        gspGroup: gspGroup,
        code: fuel.productReference,
      }
      let fetchedTariff = Rails.getProduct(tariff)
        .then((response) => {
          if (Array.isArray(response.data) && response.data.length) {
            // save tariff key
            response.data[0].currentTariffKey = tariffKey

            return response.data[0]
          }
        })
        .catch((error) => handleCriticalError(error, "fetchTariffs API"))
      return fetchedTariff
    }

    try {
      for (let i = 0; i < agreement.fuels.length; i++) {
        let fuel = agreement.fuels[i]
        let tariffKey = getters.tariffKeyThis(
          agreement.gspGroup,
          fuel.productReference
        )
        const prodTariff = getters.tariffFromProducts(
          fuel.type,
          agreement.gspGroup,
          fuel.productReference
        )
        // check tariffFromProducts if it exists before triggering lookup
        if (prodTariff) {
          prodTariff.currentTariffKey = tariffKey
          fuelPromises.push(prodTariff)
        } else {
          // do lookup
          fuelPromises.push(getData(fuel, agreement.gspGroup, tariffKey))
        }
      }
    } catch (error) {
      handleCriticalError(error, "fetchTariffs API")
    }
    let promiseArray = await Promise.all(fuelPromises)
    result = getters.getTariffObject(promiseArray, "currentTariffKey")

    return result
  },
  async fetchAvailableTariffs(
    { rootState, commit },
    { agreement, getTariffByCode }
  ) {
    let result
    let isPackage
    let fuelPromises = []

    try {
      isPackage =
        agreement.fuels.length === 2 &&
        agreement.fuels[0].fuel !== agreement.fuels[1].fuel

      for (let i = 0; i < agreement.fuels.length; i++) {
        // FETCHES RENEW TARIFFS PER METERPOINT
        fuelPromises.push(
          await TariffService.getRenewTariffsNotParsed(
            rootState.currentAccount,
            { ukGspGroup: agreement.gspGroup },
            {
              type: agreement.fuels[i].type,
              productReference: agreement.fuels[i].productReference,
              availableTariffKey: agreement.fuels[i].availableTariffKey,
            },
            getTariffByCode
          )
        )
      }
    } catch (error) {
      handleCriticalError(error, "fetchAvailableTariffs API")
    }

    let promiseArray = await Promise.all(fuelPromises)
    if (
      isPackage &&
      promiseArray.length &&
      typeof promiseArray[0] !== "undefined"
    ) {
      result = []
      for (let i = 0; i < promiseArray[0].length; i++) {
        let firstType = promiseArray[0][i]
        let secondType
        if (promiseArray && promiseArray[1]) {
          secondType = promiseArray[1].find((item) => {
            return (
              item.code.split("-")[1] === firstType.code.split("-")[1] &&
              item.duration_months === firstType.duration_months &&
              item.future_sooc_tariff === firstType.future_sooc_tariff
            )
          })
        }
        result.push({
          electricity: tariffProductHelper.isElec(firstType.code)
            ? firstType
            : secondType,
          gas: tariffProductHelper.isGas(firstType.code)
            ? firstType
            : secondType,
        })
      }
    } else {
      result = promiseArray.reduce((allTariffs, tariff) => {
        return tariff ? allTariffs.concat(tariff) : allTariffs
      }, [])
    }

    if (agreement?.isVariable) {
      commit("SET_OUT_OF_CONTRACT_RENEWAL", true)
    }
    return result
  },
  async createTariffAgreements({ dispatch, commit, rootGetters }) {
    const agreementsArray = JSON.parse(
      JSON.stringify(rootGetters["agreements/agreementsArray"])
    )

    let result = await Promise.all(
      agreementsArray.map(async (agreement) => {
        await dispatch(
          "agreements/requestTariffsForAgreement",
          {
            agreement,
            getFuture: agreement.hasFutureAgreement,
          },
          {
            root: true,
          }
        )
        return agreement
      })
    )
    commit("SET_TARIFF_AGREEMENTS", result)
  },
}

export const getters = {
  tariffKeyThis() {
    return function (gspGroup, code) {
      return `${gspGroup}|${code}`
    }
  },
  tariffFromProducts(state, getters, rootState, rootGetters) {
    return function (type, gspGroup, code) {
      const fuel = type.startsWith("elec") ? "Electricity" : "Gas"
      const tariff = rootGetters.productDetails[fuel]
      if (tariff.gspGroup === gspGroup && tariff.code === code) return tariff
      return null
    }
  },
  tariffKeyAvailable() {
    return function (gspGroup, type) {
      return `${gspGroup}|${type}|available`
    }
  },
  getTariffObject() {
    return (tariffArray, keyType) => {
      let resultObject = {}

      if (tariffArray) {
        for (let i = 0; i < tariffArray.length; i++) {
          if (tariffArray[i]) {
            let tariffKey = tariffArray[i][keyType]
            resultObject[tariffKey] = tariffArray[i]
          }
        }
      }

      return resultObject
    }
  },
  getAvailableTariffRates(state) {
    let tariffData = {}
    let tariffsArray = Object.values(state.availableTariffs)
    let elecData = tariffsArray.filter(
      (tariff) => tariff.availableTariffKey.indexOf("elec") > 0
    )
    if (elecData.length) {
      tariffData.electricity = {
        standingCharge: elecData[0].standingCharge,
        electricityProductCode: elecData[0].code,
      }
      if (elecData[0].unitRates.Standard) {
        tariffData.electricity.unitRate = elecData[0].unitRates.Standard
      } else {
        tariffData.electricity.unitRates = elecData[0].unitRates
      }
    }

    let gasData = tariffsArray.filter(
      (tariff) => tariff.availableTariffKey.indexOf("gas") > 0
    )
    if (gasData.length) {
      tariffData.gas = {
        standingCharge: gasData[0].standingCharge,
        gasProductCode: gasData[0].code,
        unitRate: gasData[0].unitRate,
      }
    }
    return tariffData
  },
  getAvailableTariffName(state) {
    const tariffKeys = Object.keys(state.availableTariffs)
    if (!tariffKeys.length) return null
    return state.availableTariffs[tariffKeys[0]].display.split("-")[0]
  },
}
