export const namespaced = true

export const state = () => ({
  userStatus: {}
})

export const mutations = {
  SET_USER_STATUS(state, userStatus) {
    state.userStatus = userStatus
  },
  CLEAR_USER_STATUS(state) {
    state.userStatus = {}
  }
}

export const getters = {
  isLoggedIn: state => state.userStatus && state.userStatus.userSignedIn
}

export default { namespaced, state, mutations, getters }
