import { recordEventToDatadog } from "./datadog"
import { recordEventToHotjar } from "./Hotjar"

export default {
  async sendEvent(eventName, metadata) {
    recordEventToDatadog(eventName, metadata)
  },

  sendHotjarEvent(eventName) {
    recordEventToHotjar(eventName)
  }
}
