import { isSmartMeter as isSmartMeterType } from "@soenergy/frontend-library/src/helpers/meterTypeHelper"

// Raw interfaces
interface IMeterpointRaw {
  id: string
  identifier: string
  type: string
  ukProfileClass: number
  ukGspGroup: string
  meters: IMeterRaw[]
}
interface IMeterRaw {
  meterType: string
  registers: IRegisterRaw[]
}
interface IRegisterRaw {
  rateName: string
}

// Core models
interface IMeter {
  meterType: string
  registers: IRegister[]
}
interface IRegister {
  rateName: string
}

export class Meterpoint {
  id: string
  identifier: string
  profileClass: number
  type: string
  ukGspGroup: string
  meters: IMeter[]

  constructor(rawMeterpoint: IMeterpointRaw) {
    this.id = rawMeterpoint.id
    this.identifier = rawMeterpoint.identifier
    this.profileClass = rawMeterpoint.ukProfileClass
    this.type = rawMeterpoint.type
    this.meters = rawMeterpoint.meters
    this.ukGspGroup = rawMeterpoint.ukGspGroup
  }

  get isElectricity() {
    return this.type === "MPAN"
  }

  get registers(): IRegister[] {
    return this.meters.reduce(
      (registers: IRegister[], meter: IMeter) => [
        ...registers,
        ...meter.registers,
      ],
      []
    )
  }
  get hasTwoRateMeter() {
    return (
      this.registers.some(
        (register) => register.rateName && register.rateName !== "Standard"
      ) || this.profileClass == 2
    )
  }

  get hasSmartMeter() {
    return this.meters.some((meter) => isSmartMeterType(meter.meterType))
  }

  get hasOnlySmartMeters() {
    return this.meters?.every((meter) => isSmartMeterType(meter.meterType))
  }
}
