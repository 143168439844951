import { DAYS_IN_YEAR } from "@/constants"
import tariffProductHelper from "./tariffProductHelper"

export const elecCalculator = (tariff, fuelAmount, consumption) => {
  let totalElec = 0
  if (tariffProductHelper.isElecMultiRate(tariff.code)) {
    const dayRate = tariff.day_rate || tariff.unitRates.Day
    const nightRate = tariff.night_rate || tariff.unitRates.Night
    const standingCharge = tariff.standing_charge ?? tariff.standingCharge
    totalElec +=
      consumption.electricity.day * dayRate +
      consumption.electricity.night * nightRate +
      standingCharge * DAYS_IN_YEAR * fuelAmount
  } else if (tariffProductHelper.isElecSingleRate(tariff.code)) {
    const standardRate = tariff.standard_rate || tariff.unitRates.Standard
    const standingCharge = tariff.standing_charge ?? tariff.standingCharge
    totalElec +=
      consumption.electricity.sum * standardRate +
      standingCharge * DAYS_IN_YEAR * fuelAmount
  }
  return totalElec
}

export const gasCalculator = (tariff, fuelAmount, consumption) => {
  const standardRate = tariff.standard_rate || tariff.unitRate
  const standingCharge = tariff.standing_charge ?? tariff.standingCharge
  return (
    consumption.gas * standardRate + standingCharge * DAYS_IN_YEAR * fuelAmount
  )
}
