export const namespaced = true
import { RenewQuote } from "@/models/RenewQuote"
import { IUsageEstimation } from "@/types/IUsageEstimation"
import TariffService from "@/services/TariffService"
import {
  getAnnualMeterpointConsumptions,
  fetchTariffsForAgreements,
  calcCostsForAgreements,
} from "@/helpers/agreementCostHelper"
import { groupObjectsByProperty } from "@/methods/groupObjectsByProperty"
import {
  TIMES_PAYED_TO_RENEW_WITH_CREDIT_IN_MONTHS,
  LAST_READ_THRESHOLD_TO_RENEW_WITH_CREDIT_IN_DAYS,
  LAST_BILL_CREATED_THRESHOLD_TO_RENEW_WITH_CREDIT_IN_DAYS,
} from "@/constants"
import { IMeter } from "@/types/IMeter"
import dayjs from "dayjs"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import { IAgreement } from "@/types/IAgreement"
import { Tariff } from "@/models/Tariff"
import { Meterpoint } from "@/models/Meterpoint"

interface IMeterpointAgreementDetails {
  tariff: Tariff
  agreement: IAgreement
  meterpoint: Meterpoint
  consumption: IUsageEstimation
}

dayjs.extend(isSameOrAfter)

const getDefaultState = () => {
  return {
    directDebit: {},
    preferences: {},
    submissionError: false,
    renewAgreements: [],
    currentAgreementsByTariff: [],
    renewQuotes: [],
    selectedQuote: null,
    isSeasonalPlan: false,
    renewingWithCredit: false,
    isPendingAgreements: false,
  }
}

const sortQuotes = (renewQuotes: RenewQuote[]) => {
  const sortedRenewQuotes: RenewQuote[] = []
  const variableTariffs: RenewQuote[] = []
  renewQuotes.forEach((quote) => {
    if (quote.tariff.isRecommended) {
      sortedRenewQuotes.unshift(quote)
    } else if (quote.tariff.isVariable && !quote.tariff.isVariableGreen) {
      variableTariffs.push(quote)
    } else {
      sortedRenewQuotes.push(quote)
    }
  })
  return sortedRenewQuotes.concat(variableTariffs)
}

const filterForEligibleForRenewal = (meterpointAgreementPairs) => {
  return meterpointAgreementPairs.filter(
    ([_, agreement]) => agreement.eligibleForRenewal
  )
}

export const state = getDefaultState()

export const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  SET_SELECTED_QUOTE(state, selectedQuote) {
    state.selectedQuote = selectedQuote
  },
  SET_SEASONAL_PLAN(state, isSeasonalPlan) {
    state.isSeasonalPlan = isSeasonalPlan
  },
  SET_IS_RENEWING_WITH_CREDIT(state, renewingWithCredit) {
    state.renewingWithCredit = renewingWithCredit
  },
  SET_PREFERENCES(state, preferencesObject) {
    state.preferences = Object.assign({}, state.preferences, preferencesObject)
  },
  SET_RENEW_OFFERS(state, renewQuotes) {
    state.renewQuotes = renewQuotes
  },
  SET_DIRECT_DEBIT(state, directDebit) {
    state.directDebit = Object.assign({}, state.directDebit, directDebit)
  },
  SET_SUBMISSION_ERROR(state, submissionError) {
    state.submissionError = submissionError
  },
  SET_RENEW_AGREEMENTS(state, agreements) {
    state.renewAgreements = Object.assign([], agreements)
  },
  SET_CURRENT_AGREEMENTS_BY_TARIFF(state, currentAgreementsByTariff) {
    state.currentAgreementsByTariff = currentAgreementsByTariff
  },
  PENDING_RENEW_AGREEMENTS(state, isPending) {
    state.isPendingAgreements = isPending
  },
}

export const actions = {
  async createRenewQuotes({ getters, commit, rootGetters, rootState }) {
    // get tariffs per meterpoint
    const availableMeterpointTariffs = await Promise.all(
      getters.renewableAgreements.map(async ([meterpoint, agreement]) => {
        const tariffs = await TariffService.getRenewTariffs(
          rootState.currentAccount,
          meterpoint,
          agreement
        )
        return tariffs.map((tariff) => ({
          tariff,
          bundleCode: tariff.tariffCode,
          meterpoint,
          agreement,
        }))
      })
    )

    const renewalMeterpointAgreementsByTariff = groupObjectsByProperty(
      availableMeterpointTariffs.flat(),
      "bundleCode"
    )

    const renewQuotes: RenewQuote[] = []
    for (const renewalMeterpointAgreements of renewalMeterpointAgreementsByTariff) {
      const commonTariffData = renewalMeterpointAgreements[0].tariff

      const meterpointAgreementList: IMeterpointAgreementDetails[] = []
      for (const {
        tariff,
        agreement,
        meterpoint,
      } of renewalMeterpointAgreements) {
        const consumption = getAnnualMeterpointConsumptions(
          agreement.type,
          rootGetters.estimated_usage[meterpoint.id]
        )

        meterpointAgreementList.push({
          tariff,
          agreement,
          meterpoint,
          consumption,
        })
      }

      const balance = -rootGetters.account.balance
      renewQuotes.push(
        new RenewQuote(commonTariffData, meterpointAgreementList, balance)
      )
    }

    commit("SET_RENEW_OFFERS", sortQuotes(renewQuotes))
  },
  async fetchCurrentAgreementData({ commit, getters, rootGetters }) {
    const agreementsByTariff = await fetchTariffsForAgreements(
      getters.renewableAgreements
    )

    const currentAgreementsDataByTariff = agreementsByTariff.map((agreements) =>
      calcCostsForAgreements(agreements, rootGetters.estimated_usage)
    )

    commit("SET_CURRENT_AGREEMENTS_BY_TARIFF", currentAgreementsDataByTariff)
  },
  updatePreferences({ commit }, preferences) {
    commit("SET_PREFERENCES", preferences)
  },
  updateDirectDebit({ commit }, directDebit) {
    commit("SET_DIRECT_DEBIT", directDebit)
  },
  async setPreferences({ dispatch, rootGetters, rootState }) {
    await dispatch("user/fetchMeterReadReminderDetails", null, { root: true })
    const marketing = !rootState.user.customer.marketingOptOutFl

    dispatch("updatePreferences", {
      meterReadReminder: rootGetters["user/meterReadReminderEnabled"],
      showMeterReadReminder:
        !rootGetters["user/meterReadReminderEnabled"] &&
        rootGetters["meter/hasLegacyMeter"],
      marketing: marketing,
      showMarketing: !marketing,
    })
  },
}

export const getters = {
  getIsSeasonalPlan(state) {
    return state.isSeasonalPlan
  },
  getRenewingWithCredit(state) {
    return state.renewingWithCredit
  },
  getSelectedQuote(state) {
    return state.selectedQuote
  },
  getPreferences(state) {
    return state.preferences
  },
  getDirectDebit(state) {
    return state.directDebit
  },
  getRenewAgreements(state) {
    return state.renewAgreements
  },
  getFixedRenewQuote(state) {
    return state.renewQuotes.find((quote) => quote.tariff.isFixed)
  },
  eligibleToUseCredit(state, getters, rootState, rootGetters) {
    const allCurrentMeters = Object.values(
      rootGetters["meters/allCurrent"]
    ).flat(1) as IMeter[]

    const allRenewMetersHadRealReadWithinPeriod =
      getters.renewableAgreements.every(([meterpoint, _]) => {
        return allCurrentMeters
          .filter(
            (meter) => meter.meterpointIdentifier === meterpoint.identifier
          )
          .every((meter) => {
            return dayjs(meter.lastReadDate).isSameOrAfter(
              dayjs().subtract(
                LAST_READ_THRESHOLD_TO_RENEW_WITH_CREDIT_IN_DAYS,
                "day"
              )
            )
          })
      })
    const lastBillCreatedWithinPeriod = dayjs(
      rootGetters["bills/latestBillCreatedDate"]
    ).isSameOrAfter(
      dayjs().subtract(
        LAST_BILL_CREATED_THRESHOLD_TO_RENEW_WITH_CREDIT_IN_DAYS,
        "day"
      )
    )

    const hasMinimumSuccessfulMonthlyPayments =
      rootGetters.payments.filter(
        (payment) => payment.requestStatus === "Successful"
      ).length >= TIMES_PAYED_TO_RENEW_WITH_CREDIT_IN_MONTHS

    return (
      allRenewMetersHadRealReadWithinPeriod &&
      lastBillCreatedWithinPeriod &&
      hasMinimumSuccessfulMonthlyPayments
    )
  },
  anyRenewableAgreementIsMissingConsumption(
    state,
    getters,
    rootState,
    rootGetters
  ) {
    return getters.renewableAgreements.some(
      ([meterpoint, _]) => !rootGetters.estimated_usage[meterpoint.id]
    )
  },
  allRenewableAgreementsHaveValidConsumption(
    state,
    getters,
    rootState,
    rootGetters
  ) {
    return getters.renewableAgreements.every(([meterpoint, _]) => {
      return (
        !rootGetters.estimated_usage[meterpoint.id] ||
        rootGetters.estimated_usage[meterpoint.id]?.rates?.every(
          (rate) => !!rate.usage
        )
      )
    })
  },
  renewableAgreements(state, getters, rootState, rootGetters) {
    return filterForEligibleForRenewal(
      rootGetters["meters/meterpointAgreementPairs"]
    )
  },
  hasAgreementsEligibleForRenewal(state, getters, rootState, rootGetters) {
    return !!rootGetters["agreements/agreementsArray"].filter(
      (agreement) => agreement.eligibleForRenewal
    ).length
  },
  nextPaymentDateAfterRenewal(state, getters, rootState, rootGetters) {
    if (rootGetters["payments/hasVariableDirectDebit"]) {
      return dayjs().add(14, "days")
    }
    const currentPaymentSchedule =
      rootGetters["payments/currentPaymentSchedule"]

    return currentPaymentSchedule
      ? dayjs(currentPaymentSchedule.nextPaymentDt)
      : null
  },
}
