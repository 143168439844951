import MixpanelService from "@soenergy/frontend-library/src/services/Mixpanel"

export default function (currentAmount, newAmount, minAmount, maxAmount) {
  const up = newAmount > currentAmount ? "up" : null
  const down = newAmount < currentAmount ? "down" : null
  const change = up ?? down ?? "not changed"

  const max = newAmount === maxAmount ? "maximum" : null
  const min = newAmount === minAmount ? "minimum" : null
  const minOrMax = min ?? max ?? "neither"

  return MixpanelService.sendEvent(`Payment Amount Update - ${change}`, {
    "Payment Amount Update": change,
    "Maximum or Minimum": minOrMax,
  })
}
