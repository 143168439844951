import HttpClient from "../http/HttpClient"
import authenticate from "../http/requestInterceptors/authenticate"

const apiClient = HttpClient({
  baseURL: process.env.VUE_APP_ACCOUNT_SITE_URL,
  requestInterceptors: [authenticate]
})

export default {
  getUserStatus() {
    return apiClient.get("api/v1/status", {
      withCredentials: true,
      skipAuthentication: true
    })
  },
  getUserStatusWithToken() {
    return apiClient.get("api/v1/status", {
      withCredentials: false
    })
  }
}
