export function absoluteGBP(pounds, fractionDigits = 2) {
  return !isNaN(pounds) ? "£" + Math.abs(pounds).toFixed(fractionDigits) : ""
}

export function twoDecimalPlaces(number) {
  return !isNaN(number) ? "" + Math.abs(number).toFixed(2) : ""
}

export function crdr(pounds) {
  return `${absoluteGBP(pounds)} ${
    pounds == 0 ? "\u2004".repeat(4) : pounds <= 0 ? "in Credit" : "in Debit"
  }`
}

export function balanceType(pounds) {
  return pounds <= 0 ? "credit" : "debit"
}

export function kwh(value) {
  return !isNaN(value) ? Math.round(value).toLocaleString() + " kWh" : ""
}

export function fullAddress(address) {
  if (!address) return null
  return [
    address.address1,
    address.address2,
    address.address3,
    address.address4,
    address.address5,
    address.address6,
    address.address7,
    address.address8,
    address.address9,
    address.postcode?.toUpperCase(),
  ]
    .filter((value) => !!value) // remove empty
    .join(", ")
}

export const formatMixin = {
  methods: {
    absoluteGBP,
    twoDecimalPlaces,
    crdr,
    balanceType,
    kwh,
    fullAddress,
  },
}
