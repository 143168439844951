<template>
  <div class="modal-backdrop" @click.self="closeModal">
    <div
      ref="modal"
      class="modal"
      role="dialog"
      aria-modal="true"
      tabindex="0"
      @keydown="checkKeyEvent"
      @keydown.esc="closeModal"
    >
      <AppButton
        class="close-button"
        aria-label="close"
        variant="tertiary"
        @click="closeModal"
      >
        <i class="icon icon-close"></i>
      </AppButton>
      <section class="modal__header">
        <slot name="header"></slot>
      </section>

      <section id="modal-body" class="modal__body">
        <slot name="body"></slot>
      </section>

      <section class="modal__footer">
        <slot name="footer">
          <AppButton
            variant="secondary"
            class="cancel-button"
            @click="closeModal"
          >
            <slot name="cancelButton">Cancel</slot>
          </AppButton>
          <AppButton
            v-if="$slots.confirmButton"
            class="confirm-button"
            @click="clickConfirmButton"
          >
            <slot name="confirmButton"></slot>
          </AppButton>
        </slot>
      </section>
    </div>
  </div>
</template>

<script>
import AppButton from "@soenergy/frontend-library/src/components/AppButton"

export default {
  name: "Modal",
  components: {
    AppButton,
  },
  emits: ["closeModal", "clickConfirmButton"],
  mounted() {
    this.preventBodyScroll(true)
    this.$refs.modal.focus()
  },
  unmounted() {
    this.preventBodyScroll(false)
  },
  methods: {
    closeModal() {
      this.$emit("closeModal")
    },
    clickConfirmButton() {
      this.$emit("clickConfirmButton")
    },
    preventBodyScroll(preventScroll) {
      const body = document.body

      if (preventScroll) {
        body.classList.add("no-scroll")
      } else {
        body.classList.remove("no-scroll")
      }
    },
    checkKeyEvent(event) {
      const focusableList = this.$refs.modal.querySelectorAll(
        "button, [href], input, select, textarea"
      )
      if (focusableList.length < 2 && event.key === "Tab") {
        event.preventDefault()
        return
      }
      const last = focusableList.length - 1
      if (
        event.key === "Tab" &&
        event.shiftKey === false &&
        event.target === focusableList[last]
      ) {
        event.preventDefault()
        focusableList[0].focus()
      } else if (
        event.key === "Tab" &&
        event.shiftKey === true &&
        event.target === focusableList[0]
      ) {
        event.preventDefault()
        focusableList[last].focus()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: zindex(modal);
}

.modal {
  position: relative;
  background-color: $day;
  box-shadow: 2px 2px 20px 1px;
  width: 100%;
  margin: 0 12px;
  padding: 14px 32px 28px 32px;
  border-radius: 6px;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
    0 1px 3px 1px rgba(60, 64, 67, 0.16);
  max-width: 400px;

  @include lg {
    padding: 20px 48px 40px 48px;
    max-width: 550px;
    margin: 0;
  }

  &:focus {
    outline: 0;
  }

  &__header {
    font-size: 26px;
    margin-top: 14px;

    @include lg {
      font-size: 32px;
      margin-top: 20px;
    }
  }

  &__body {
    margin-bottom: 48px;
    margin-top: 14px;

    @include lg {
      margin-bottom: 64px;
      font-size: 20px;
      margin-top: 20px;
    }
  }

  &__footer {
    text-align: right;
  }
}

.close-button {
  position: absolute;
  right: 3px;
  top: 3px;
  color: rgba(0, 0, 0, 0.4);
  border: 0;
  width: 40px;
  height: 40px;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;

  @include lg {
    font-size: 20px;
  }

  &:hover {
    color: rgba(0, 0, 0, 0.6);
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
  }
}

.confirm-button {
  margin-left: 8px;
  float: right;

  @include lg {
    margin-left: 16px;
  }
}
</style>
