export const footerNavItems = [
  {
    title: "The Company",
    navTitle: [],
    navLinks: [
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/about-us`,
        name: "About So Energy",
        component: "NavItemWebsite"
      },
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/energy/tariffs`,
        name: "Our Tariffs",
        component: "NavItemWebsite"
      },
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/help/emergency`,
        name: "Emergency",
        component: "NavItem"
      },
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/accessibility-statement`,
        name: "Accessibility Statement",
        component: "NavItem"
      },
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/support/solutions/articles/7000070564-feed-in-tariff-fit-faqs`,
        name: "Feed-In-Tariff",
        component: "NavItem"
      },
      {
        link: "https://careers.so.energy/",
        name: "Careers",
        component: "NavItem"
      }
    ],
    hideWhenLoggedIn: false,
    hideWhenLoggedOut: false
  },
  {
    title: "Important information",
    navTitle: [],
    navLinks: [
      {
        text: "Cookie Preferences",
        emitEvent: "reopenCookieModal",
        component: "eventLink"
      },
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/cookies`,
        name: "Cookie Policy",
        component: "NavItemWebsite"
      },
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/privacy`,
        name: "Privacy Policy",
        component: "NavItemWebsite"
      },
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/terms`,
        name: "Ts & Cs",
        component: "NavItemWebsite"
      },
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/modern-slavery`,
        name: "Modern Slavery Statement",
        component: "NavItemWebsite"
      },
      {
        link: `${process.env.VUE_APP_WEBSITE_URL}/pay-report`,
        name: "Gender & Ethnicity Pay Report",
        component: "NavItemWebsite"
      }
    ],
    hideWhenLoggedIn: false,
    hideWhenLoggedOut: false
  },
  {
    title: "",
    navLinks: [
      {
        icon: {
          icon: "",
          name: "icon-logo-facebook",
          plugin: "nucleo-icon"
        },
        link: {
          url: "https://www.facebook.com/SoEnergyUK",
          linktype: "url",
          fieldtype: "multilink",
          cached_url: "https://www.facebook.com/SoEnergyUK"
        },
        title: "So Energy Facebook",
        tracking: [
          {
            area: "Footer",
            name: "Click So Energy Facebook link",
            component: "AnalyticsEvent",
            captureCurrentPage: true
          }
        ],
        component: "iconLink"
      },
      {
        icon: {
          icon: "",
          name: "icon-logo-linkedin",
          plugin: "nucleo-icon"
        },
        link: {
          url: "https://www.linkedin.com/company/so-energy",
          linktype: "url",
          fieldtype: "multilink",
          cached_url: "https://www.linkedin.com/company/so-energy"
        },
        title: "So Energy LinkedIn",
        tracking: [
          {
            area: "Footer",
            name: "Click So Energy Linkedin link",
            component: "AnalyticsEvent",
            captureCurrentPage: true
          }
        ],
        component: "iconLink"
      }
    ],
    navTitle: [],
    component: "Nav",
    hideWhenLoggedIn: false,
    hideWhenLoggedOut: false
  }
]
