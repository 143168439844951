<template>
  <div class="global-banner">
    <Banner
      v-if="showBanner && variant == 'trustpilot'"
      class="trustpilot-banner"
    >
      <template slot="content">
        <div
          class="columns container trustpilot-banner__container is-flex is-vcentered"
        >
          <div class="column is-8">
            <Article v-if="heading" :text-content="heading" no-margin />
          </div>
          <div class="column is-4 is-12-mobile">
            <div class="columns is-mobile is-flex is-vcentered">
              <div class="column is-6">
                <img
                  v-if="bannerImage"
                  class="trustpilot-banner__inline-content trustpilot-banner__img"
                  :src="bannerImage.filename"
                  :alt="bannerImage.alt"
                />
              </div>
              <div
                v-if="actionButton && actionButton[0]"
                class="column is-6 trustpilot-banner__action-button"
              >
                <AppButton
                  size="small"
                  v-bind="actionButton[0]"
                  class="trustpilot-banner__inline-content"
                >
                  {{ actionButton[0].label }}
                </AppButton>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Banner>
    <Banner v-else-if="showBanner" :dropdown-content="dropdownContent">
      <Article v-if="heading" :text-content="heading" no-margin />
    </Banner>
  </div>
</template>

<script>
import dayjs from "dayjs"
import Article from "./Article"
import Banner from "./Banner"
import AppButton from "./AppButton"
import projectUrlResolverMixin from "../mixins/projectUrlResolverMixin"

const removeTrailingSlash = str => str && str.replace(/\/$/, "")

export default {
  components: {
    Article,
    Banner,
    AppButton
  },
  mixins: [projectUrlResolverMixin],
  props: {
    heading: {
      type: Object,
      default: () => {}
    },
    pagesToShowOn: {
      type: Array,
      default: () => []
    },
    expiryDate: {
      type: String,
      default: null
    },
    dropdownContent: {
      type: Object,
      default: () => {}
    },
    enabled: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: ""
    },
    bannerImage: {
      type: Object,
      default: null,
      required: false
    },
    actionButton: {
      type: Array,
      default: () => [],
      required: false
    }
  },
  computed: {
    showBanner() {
      return this.enabled && !this.expired && this.currentPageEnablesBanner
    },
    expired() {
      if (!this.expiryDate) return false

      const now = dayjs()
      const expiryDate = dayjs(this.expiryDate)

      if (!expiryDate.isValid()) return true

      return expiryDate.endOf("day").isBefore(now)
    },
    currentPageEnablesBanner() {
      if (this.pagesToShowOn.length === 0) {
        return true
      }
      // $route is not available in web component context
      let currentUrl = this.$route ? this.$route.path : location.pathname
      currentUrl =
        currentUrl === "/" ? currentUrl : removeTrailingSlash(currentUrl)

      return this.pagesToShowOn.some(({ link, component: linkType }) => {
        if (!link) return

        const resolvedPath = this.resolveLink({
          link,
          linkType
        })
        return resolvedPath === currentUrl
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.trustpilot-banner {
  &__container {
    text-align: initial;
    margin-top: -$spacing-2;
    @include md {
      text-align: center;
    }
  }

  &__inline-content {
    vertical-align: middle;
  }
  &__action-button {
    display: flex;
    justify-content: right;
  }
  &__img {
    height: 24px;
    @include sm {
      height: 100%;
    }
  }
}
</style>
