import Vue from "vue"
import ErrorReporter from "@soenergy/frontend-library/src/services/ErrorReporter"
import store from "@/store/store"

export default () =>
  ErrorReporter.init({
    Vue,
    apiKey: process.env.VUE_APP_BUGSNAG_TOKEN,
    onError: function (event) {
      try {
        const { state, getters } = store
        const id = getters["home/accountNumber"]
        const email = state.junifer.customer.primaryContact.email
        event.setUser(id, email)
      } catch (err) {} // eslint-disable-line no-empty
    },
  })

window.ErrorReporterClient = ErrorReporter.client
