export const namespaced = true

export const state = () => ({
  whdRegionEligibility: false
})

export const mutations = {
  SET_WHD_REGION_ELIGIBILITY(state, whdRegionEligibility) {
    state.whdRegionEligibility = whdRegionEligibility
  }
}

export const getters = {
  isWHDRegionEligible: state => state.whdRegionEligibility
}

export default { namespaced, state, mutations, getters }
