export const groupObjectsByProperty = <T>(
  array: Array<T>,
  property: string
): Array<Array<T>> => {
  const grouped = {}

  for (const obj of array) {
    const key = obj[property]
    if (!grouped[key]) {
      grouped[key] = []
    }
    grouped[key].push(obj)
  }

  return Object.values(grouped)
}
