import CardPaymentService from "@soenergy/frontend-library/src/services/CardPaymentService"
import RailsService from "@/services/RailsService"
import logPaymentAmountUpdate from "@/methods/logPaymentAmountUpdate"
import { storePayment } from "@/methods/paymentPendingUpdates"
import MixpanelService from "@soenergy/frontend-library/src/services/Mixpanel"

export default {
  // FETCH
  async fetchDirectDebit({ commit, rootState }) {
    const response = await RailsService.getDirectDebit(rootState.currentAccount)
    if (!response?.data?.success) {
      throw new Error("Failed to fetch Direct Debit details")
    }

    commit("SET_DIRECT_DEBIT_MANDATE", response.data)
  },

  async fetchSuggestedPayment({ commit, rootState }) {
    const response = await RailsService.getSuggestedPayment(
      rootState.currentAccount
    )
    const data = response?.data?.response
    const suggestedPayment = data?.suggested_payment
    const minimumPaymentAmount = data?.minimum_payment
    const maximumPaymentAmount = data?.maximum_payment

    if (suggestedPayment && minimumPaymentAmount && maximumPaymentAmount) {
      commit("SET_SUGGESTED_PAYMENT", suggestedPayment)
      commit("SET_MINIMUM_PAYMENT_AMOUNT", minimumPaymentAmount)
      commit("SET_MAXIMUM_PAYMENT_AMOUNT", maximumPaymentAmount)
    } else {
      throw new Error("Endpoint response missing payment amounts")
    }
  },

  async fetchUsageProfileForecast({ commit, rootState }) {
    const response = await RailsService.getUsageProfileForecast(
      rootState.juniferCurrentAccountId
    )
    commit("SET_USAGE_PROFILE", {
      gas: response.data.gas,
      electricity: response.data.electricity,
    })
    commit("SET_SHOW_BALANCE_PREDICTION", response.data.showForecast)
  },

  async getPaymentDetails({ rootState, dispatch }, ckoSessionId) {
    const { confirmationNumber, amountInPence, authorisedAt } =
      await CardPaymentService.getPaymentDetails({
        ckoSessionId,
      })

    dispatch("recordPayment", {
      accountId: rootState.currentAccount,
      confirmationNumber,
      amountInPence,
      authorisedAt,
    })

    storePayment(
      rootState.user.customer.id,
      confirmationNumber,
      amountInPence,
      authorisedAt
    )
  },

  // UPDATE
  async recordPayment(
    { commit, dispatch },
    { confirmationNumber, amountInPence, authorisedAt, status }
  ) {
    commit("SET_PAYMENT_SUMMARY", {
      paymentReference: confirmationNumber,
      authorisedAt: authorisedAt,
      amountInPence,
      status,
    })
    await dispatch("fetchJuniferData", null, { root: true })
  },

  async createPaymentSchedule({ commit, rootState }, payload) {
    commit("SET_NEW_PAYMENT_SCHEDULE", null)
    const response = await RailsService.updatePaymentSchedule(
      rootState.currentAccount,
      {
        new_payment_amount: payload.amount,
        new_payment_schedule_date: payload.date,
        new_payment_seasonal_flag: payload.isSeasonal,
      }
    )

    if (!response?.data?.success) {
      throw new Error("Failed to update payment schedule")
    }
    commit("SET_NEW_PAYMENT_SCHEDULE", response.data.response)
  },

  async updatePaymentAmount(
    { state, getters, commit, dispatch, rootState },
    newAmount
  ) {
    commit("SET_NEW_PAYMENT_SCHEDULE", null)
    const response = await RailsService.updatePaymentSchedule(
      rootState.currentAccount,
      { new_payment_amount: newAmount }
    )

    if (!response?.data?.success) {
      throw new Error("Failed to update payment amount")
    }

    await dispatch("fetchJuniferData", null, { root: true })
    commit("SET_NEW_PAYMENT_SCHEDULE", response.data.response)
    const currentAmount = getters.latestPaymentSchedule?.equalAmount ?? 0

    logPaymentAmountUpdate(
      currentAmount,
      newAmount,
      state.minimumPaymentAmount.equal,
      state.maximumPaymentAmount.equal
    )
  },

  async updatePaymentDay({ commit, dispatch, rootState }, newPaymentDate) {
    commit("SET_NEW_PAYMENT_SCHEDULE", null)
    const response = await RailsService.updatePaymentSchedule(
      rootState.currentAccount,
      {
        new_payment_schedule_date: newPaymentDate,
      }
    )

    if (!response?.data?.success) {
      throw new Error("Failed to update payment day")
    }

    await dispatch("fetchJuniferData", null, { root: true })
    commit("SET_NEW_PAYMENT_SCHEDULE", response.data.response)
  },

  async updateSeasonalAdjustment({ commit, dispatch, rootState }, isSeasonal) {
    commit("SET_NEW_PAYMENT_SCHEDULE", null)
    const response = await RailsService.updatePaymentSchedule(
      rootState.currentAccount,
      { new_payment_seasonal_flag: isSeasonal }
    )

    if (!response?.data?.success) {
      throw new Error("Failed to update seasonal adjustment")
    }

    await dispatch("fetchJuniferData", null, { root: true })
    commit("SET_NEW_PAYMENT_SCHEDULE", response.data.response)
  },

  async createDirectDebit({ rootState, getters, commit }, ddDetails) {
    const ddUpdateOrAdd = getters.hasActiveDirectDebit ? "Updated" : "Added"

    const response = await RailsService.newDirectDebit({
      account_id: rootState.currentAccount,
      bank_account_name: ddDetails.accountHolderName,
      bank_account_number: ddDetails.accountNumber,
      bank_sort_code: ddDetails.sortCode.replace(/-/g, ""),
    })

    if (!response?.data?.success) {
      throw new Error("Failed to update Direct Debit details")
    }

    let methodStatus = "Unknown"
    if (getters.hasActiveDirectDebit) {
      methodStatus = "Active"
    } else if (getters.latestPaymentMethod) {
      methodStatus = getters.latestPaymentMethod.status
    }

    MixpanelService.sendEvent(`Direct Debit details - ${ddUpdateOrAdd}`, {
      "Payment Method Status": methodStatus,
    })
    commit("SET_DIRECT_DEBIT_MANDATE", {
      account_name: ddDetails.accountHolderName,
      account_number_ending: ddDetails.accountNumber.slice(-3),
    })
  },

  async validateDirectDebit({ commit }, directDebitDetails) {
    const response = await RailsService.validateDirectDebit(directDebitDetails)
    return !!response?.data?.success
  },
}
