export default {
  emit(eventName) {
    if (typeof Event !== "function") return // Fix for IE
    let event = new Event(eventName)
    document.getElementById("app").dispatchEvent(event)
  },
  subscribe(eventName, callback) {
    document.getElementById("app").addEventListener(eventName, callback, false)
  },
}
