import HttpClient from "@soenergy/frontend-library/src/services/http/HttpClient"
import authenticate from "@soenergy/frontend-library/src/services/http/requestInterceptors/authenticate"

const apiClient = HttpClient({
  baseURL: process.env.VUE_APP_API_GATEWAY_URL,
  requestInterceptors: [authenticate],
})

export default {
  getHasRequestedQuoteInTimeRange(accountNumber, dateRange) {
    return apiClient.get("solar-quote/" + accountNumber, {
      params: dateRange,
    })
  },
}
