import RailsService from "../../services/RailsService.js"
import dayjs from "dayjs"

export const namespaced = true

const GAS = "gas"
const ELECTRICITY = "elec"

export const state = {
  meters: {},
  eligibleForReadLogin: null,
  isDataFetched: false,
  firstName: null,
}

const filterMetersByFuel = (meters, fuel) =>
  Object.entries(meters).reduce((meters, [meterIdentifier, meterData]) => {
    if (meterData.fuel === fuel) {
      meters[meterIdentifier] = meterData
    }
    return meters
  }, {})

export const getters = {
  electricityMeters(state) {
    return filterMetersByFuel(state.meters, ELECTRICITY)
  },
  gasMeters(state) {
    return filterMetersByFuel(state.meters, GAS)
  },
  // Returns true if meters have readings for today's date - not necessarily submitted today
  allMetersReceivedReadingsToday(state) {
    const meterData = Object.values(state.meters)
    if (!meterData.length) {
      return false
    }

    const today = dayjs().startOf("day")
    return meterData.every(({ registers }) => {
      const allRegistersSubmittedToday = registers.every(
        (register) =>
          register.lastReadDate &&
          today.isSame(dayjs(register.lastReadDate), "day")
      )
      return allRegistersSubmittedToday
    })
  },
  communicatingSmartMeter(state) {
    let allSM = true

    for (const meterpoint in state.meters) {
      let registers = state.meters[meterpoint].registers
      let meterpointSM = registers.every(
        (register) =>
          register.isSmartMeter &&
          register.lastSmartReadDate &&
          dayjs(register.lastSmartReadDate).isSameOrAfter(
            dayjs().subtract(31, "day")
          )
      )
      allSM = allSM && meterpointSM
    }

    return allSM
  },
}

export const mutations = {
  SET_METERS(state, meters) {
    const normaliseRegisters = (registers) =>
      registers.map((register) => ({
        ...register,
        lastRead: register.lastRealRead,
        lastReadDate: register.lastRealReadDate,
      }))
    state.meters = Object.entries(meters).reduce(
      (meters, [meterIdentifier, meterData]) => {
        meters[meterIdentifier] = {
          ...meterData,
          registers: normaliseRegisters(meterData.registers),
        }
        return meters
      },
      {}
    )
  },
  SET_CAN_ACCESS_READ_LOGIN(state, eligibleForReadLogin) {
    state.eligibleForReadLogin = eligibleForReadLogin
  },
  SET_FIRST_NAME(state, firstName) {
    state.firstName = firstName
  },
  SET_DATA_FETCHED(state, isDataFetched) {
    state.isDataFetched = isDataFetched
  },
}

export const actions = {
  async fetchReadLoginData({ commit }) {
    const { data } = await RailsService.getReadLoginData()
    commit("SET_METERS", data.meters)
    commit("SET_CAN_ACCESS_READ_LOGIN", data.canAccessLightMeterRead)
    commit("SET_FIRST_NAME", data.firstName)
    commit("SET_DATA_FETCHED", true)
  },
  async ensureReadLoginData({ state, dispatch }) {
    if (!state.isDataFetched) {
      await dispatch("fetchReadLoginData")
    }
  },
  async setReadLogin({ commit }, enable) {
    commit("SET_CAN_ACCESS_READ_LOGIN", enable)
  },
}
