import { AnalyticsService } from "@soenergy/frontend-library/src/plugins/analytics"
import { runOnceCookieIsAllowed } from "@soenergy/frontend-library/src/services/CookiePreferences"
import MixpanelService from "@soenergy/frontend-library/src/services/Mixpanel"
import store from "../store/store.js"

export default {
  registerUserProperties() {
    runOnceCookieIsAllowed("performance", () => {
      AnalyticsService.markUserTypeAsCustomer()
      let productType = null
      if (
        store.getters["meters/hasElectricity"] &&
        store.getters["meters/hasGas"]
      ) {
        productType = "dual fuel"
      } else if (store.getters["meters/hasGas"]) {
        productType = "gas only"
      } else if (store.getters["meters/hasElectricity"]) {
        productType = "electricity only"
      }
      MixpanelService.register({ "Product Type": productType })
    })
  },
}
