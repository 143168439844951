export const namespaced = true

export const state = {
  logoutModalOpen: false,
  genericModalOpen: false,
  genericModalMessage: null,
}

export const mutations = {
  SET_LOG_OUT_MODAL(state) {
    state.logoutModalOpen = true
  },
  DELETE_LOG_OUT_MODAL(state) {
    state.logoutModalOpen = false
  },
  SET_GENERIC_MODAL(state, { message }) {
    state.genericModalOpen = true
    state.genericModalMessage = message
  },
  DELETE_GENERIC_MODAL(state) {
    state.genericModalOpen = false
    state.genericModalMessage = null
  },
}

export const actions = {
  showLogoutModal({ commit }) {
    commit("SET_LOG_OUT_MODAL")
  },
  closeLogoutModal({ commit }) {
    commit("DELETE_LOG_OUT_MODAL")
  },
  showGenericModal({ commit }, { message }) {
    commit("SET_GENERIC_MODAL", { message })
  },
  closeGenericModal({ commit }) {
    commit("DELETE_GENERIC_MODAL")
  },
}

export const getters = {
  isLogoutModalOpen(state) {
    return state.logoutModalOpen
  },
  isGenericModalOpen(state) {
    return state.genericModalOpen
  },
  genericModalMessage(state) {
    return state.genericModalMessage
  },
}
