/*
  ESB tariff code mapping
  Fuel - splitting the product code by "-"
  - If the first section is 1 character long, the first letter determines the fuel (E/G)
  - If the first section is longer than 1 character, the first letter of the second section determines the fuel (E/G)
  Rates - splitting the product code by "-", taking the second section, and taking the last 2 characters
  - If they are "SR" or "B1" then the product is single rate
  - If they are "E7" then the product is 2-rate
  Tariff type - splitting the product code by "-", taking the 3rd section, and taking the first 3 characters
  - If the first 3 characters are "SVT", the tariff is variable
  - Otherwise it is fixed
*/

import ErrorReporter from "@soenergy/frontend-library/src/services/ErrorReporter"
import { FuelType } from "@/constants"

export default {
  isElec(reference) {
    return this.getFuelType(reference) !== FuelType.GAS
  },
  isElecSingleRate(reference) {
    return this.getFuelType(reference) === FuelType.ELEC1
  },
  isElecMultiRate(reference) {
    return this.getFuelType(reference) === FuelType.ELEC2
  },
  isGas(reference) {
    return this.getFuelType(reference) === FuelType.GAS
  },
  isExport(reference) {
    return reference.split("-")[1].includes("EX")
  },
  isVariable({ internalName, reference }) {
    const referenceChunks = reference.split("-")
    const isSoEnergyVariable = internalName.includes("SOOC")
    const isSoEnergyVariableNonDD = internalName.includes("SOOCNDD")
    const isEsbVariable =
      referenceChunks.length > 2 && referenceChunks[2].startsWith("SVT")

    return isSoEnergyVariable || isSoEnergyVariableNonDD || isEsbVariable
  },
  isVariableNonDD({ internalName }) {
    return internalName.includes("SOOCNDD")
  },
  isValidFuelType(reference) {
    return this.getFuelType(reference) != null
  },
  getFuelType(reference) {
    const [firstChunk, secondChunk] = reference.split("-")

    // So Energy product references
    if (firstChunk === "E1R") return FuelType.ELEC1
    if (firstChunk === "E2R") return FuelType.ELEC2
    if (firstChunk === "G1R") return FuelType.GAS

    // ESB product references
    const fuelType =
      firstChunk.length === 1 ? firstChunk : secondChunk && secondChunk[0]
    const rateChunk = secondChunk && secondChunk.slice(-2)

    if (fuelType === "G") return FuelType.GAS
    if (rateChunk === "SR" || rateChunk === "B1") return FuelType.ELEC1
    if (rateChunk === "E7") return FuelType.ELEC2

    ErrorReporter.report(new Error("Unknown product reference"), {
      reference,
    })

    return null
  },
}
