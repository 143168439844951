import store from "@/store/store"
import { identifyUser } from "@soenergy/frontend-library/src/services/datadog"

export default () => {
  const { state, getters } = store
  const id = getters["home/accountNumber"]
  const email = state.junifer.customer.primaryContact.email
  const name = state.junifer.customer.name

  identifyUser({
    id,
    email,
    name,
  })
}
