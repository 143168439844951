import store from "@/store/store.js"
import { RouteLocationNormalized } from "vue-router"

export default (to: RouteLocationNormalized): boolean | undefined => {
  const isNexusLoginEnabled = process.env.VUE_APP_USE_NEXUS_LOGIN === "true"
  if (!isNexusLoginEnabled) return

  if (to.name === "login") {
    let loginRedirectUrl = new URL(window.location.href)

    // Nexus login page is not served from localhost address
    const isDevelopment = process.env.NODE_ENV === "development"
    if (isDevelopment) {
      const queryString = window.location.search || ""
      const myaccountHostUrl = process.env.VUE_APP_ACCOUNT_APP_URL
      loginRedirectUrl = new URL(`${myaccountHostUrl}/login${queryString}`)
      loginRedirectUrl.searchParams.set("redirect", window.location.href)
    }

    loginRedirectUrl.pathname = "/myaccount/login"
    if (store.state.loginRedirectPath) {
      loginRedirectUrl.searchParams.set(
        "redirect",
        store.state.loginRedirectPath
      )
    }
    window.location.assign(loginRedirectUrl.toString())
    return false
  }
}
