import Rails from "../RailsService.js"
import { Tariff } from "@/models/Tariff"
import { FuelType } from "@/constants"
import { Meterpoint } from "@/models/Meterpoint.js"
import {
  parseTariffEndpointResponseObject,
  parseProductEndpointResponseObject,
  ITariffEndpointResponse,
  IProductEndpointResponse,
} from "./parsers"

interface ITariffsPayload {
  gsp_group: string
  account_id: number
  gas_only: boolean
  elec_only: boolean
  two_rate: boolean
  for_renewal: boolean
  active: boolean
  code: string
}

const getTariffsPayload = (
  currentAccount: number,
  meterpoint: Meterpoint,
  agreement,
  tariffCode?: string
) => {
  const tariff = {
    gsp_group: meterpoint.ukGspGroup,
    account_id: currentAccount,
  } as ITariffsPayload

  if (agreement.type === FuelType.GAS) {
    tariff.gas_only = true
  } else if (agreement.type === FuelType.ELEC1) {
    tariff.elec_only = true
    tariff.two_rate = false
  } else if (agreement.type === FuelType.ELEC2) {
    tariff.elec_only = true
    tariff.two_rate = true
  }
  if (tariffCode) {
    tariff.code = agreement.productReference
  } else {
    tariff.for_renewal = true
    tariff.active = true
  }

  return tariff
}

export default {
  async getRenewTariffsNotParsed(
    currentAccount,
    meterpoint,
    agreement,
    tariffCode
  ) {
    const parsedInputData = getTariffsPayload(
      currentAccount,
      meterpoint,
      agreement,
      tariffCode
    )

    const fetchedTariff = await Rails.getTariffs(parsedInputData)

    return fetchedTariff.data.map((tariff) => {
      tariff.availableTariffKey = agreement.availableTariffKey
      return tariff
    })
  },
  async getRenewTariffs(currentAccount, meterpoint, agreement) {
    const parsedInputData = getTariffsPayload(
      currentAccount,
      meterpoint,
      agreement
    )

    type IData = {
      data: ITariffEndpointResponse[]
    }

    const fetchedTariff: IData = await Rails.getTariffs(parsedInputData)

    return getUniqueTariffs(fetchedTariff.data).map((tariff) => {
      return new Tariff(parseTariffEndpointResponseObject(tariff))
    })
  },
  async getTariff(meterpoint, agreement) {
    const parsedInputData = {
      type: agreement.type,
      gspGroup: meterpoint.ukGspGroup,
      code: agreement.productReference,
    }

    type IData = {
      data: IProductEndpointResponse[]
    }

    const fetchedTariff: IData = await Rails.getProduct(parsedInputData)

    return new Tariff(parseProductEndpointResponseObject(fetchedTariff.data[0]))
  },
}

const getUniqueTariffs = (quotes: ITariffEndpointResponse[]) => [
  ...new Map(quotes.map((quote) => [quote["code"], quote])).values(),
]
