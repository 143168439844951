import dayjs from "dayjs"
import { showAccount } from "@/methods/storeHelper"
import isBetween from "dayjs/plugin/isBetween"

dayjs.extend(isBetween)

export const namespaced = true

export const getters = {
  accountIDs(state, getters, rootState) {
    return rootState.junifer.accounts
      ? Object.keys(rootState.junifer.accounts)
      : []
  },
  accounts(state, getters, rootState, rootGetters) {
    return getters.accountIDs
      .map((accountID) => {
        const account = rootState.junifer.accounts[accountID]
        const acct = account.account
        let switchIn = false
        for (const property in account.supply_status) {
          let fuelSwitchIn = dayjs(
            account.supply_status[property].createdDttm
          ).isAfter(dayjs())
          if (fuelSwitchIn) switchIn = true
        }
        let hadElectricity = false
        let hadGas = false
        for (const fuel in account.meterpoints) {
          if (account.meterpoints[fuel].type === "MPAN") {
            hadElectricity = true
          } else if (account.meterpoints[fuel].type === "MPRN") {
            hadGas = true
          }
        }

        const currentAgreements = getCurrentAgreements(account.agreements)
        const filteredSupplyAddress = Object.values(
          account.meterpoint_addresses
        ).filter((address) => address.active)

        // TODO: remove these props because they permanently modify the store, while being a getter
        return Object.assign(acct, {
          supplyAddress: filteredSupplyAddress.length
            ? filteredSupplyAddress[0]
            : {},
          showAccount: showAccount(acct, account.agreements),
          isSelected: acct.id === getters.currentAccount,
          hasGas: hasGas(currentAgreements),
          hadGas,
          hasElectricity: hasElectricity(currentAgreements),
          hadElectricity,
          switchIn: switchIn,
        })
      })
      .sort(sortShowAndAcctId)
  },
  oldAccounts(state, getters) {
    return getters.accounts.filter((account) => !account.showAccount)
  },
  currentAccounts(state, getters) {
    return getters.accounts.filter((account) => account.showAccount)
  },
  hasHiddenAccounts(state, getters) {
    return getters.hasOldAccounts & getters.hasCurrentAccounts
  },
  hasOldAccounts(state, getters) {
    return getters.oldAccounts.length
  },
  hasCurrentAccounts(state, getters) {
    return getters.currentAccounts.length
  },
  currentAccount(state, getters, rootState) {
    return rootState.currentAccount
  },
}
const sortShowAndAcctId = (acct1, acct2) => {
  if (acct1.showAccount && !acct2.showAccount) return -1
  if (!acct1.showAccount && acct2.showAccount) return 1
  if (acct1.id < acct2.id) return -1
  if (acct1.id > acct2.id) return 1
  return 0
}
const getCurrentAgreements = (agreements) => {
  const today = dayjs().startOf("day").hour(12)
  return agreements.filter(
    (a) =>
      today.isBetween(
        dayjs(a.fromDt),
        a.toDt ? dayjs(a.toDt) : dayjs().endOf("day")
      ) && !a.cancelled
  )
}
const hasElectricity = (agreements) => {
  return agreements.some((a) =>
    a.products.some((product) => product.internalName.startsWith("Electricity"))
  )
}
const hasGas = (agreements) => {
  return agreements.some((a) =>
    a.products.some((product) => product.internalName.startsWith("Gas"))
  )
}
