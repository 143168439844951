<template>
  <footer
    v-track-click-group="trackingClickGroup"
    :class="footerContainerClasses"
  >
    <div class="container">
      <div class="columns">
        <div class="footer-logo column is-4">
          <img
            class="footer-logo__image"
            src="../images/logo-so-energy-white.svg"
            alt="So Energy logo"
            width="70"
            height="52"
          />
        </div>
        <div class="column is-8">
          <div class="columns is-multiline is-mobile">
            <nav
              v-editable="supportLinks"
              class="column is-half-mobile is-one-quarter-widescreen"
              aria-label="Support Links"
            >
              <h4
                class="is-size-7 has-text-weight-bold"
                :class="isV2 ? 'mb-2' : 'mb-6'"
              >
                {{ supportLinks.title }}
              </h4>
              <ul class="footer-link-list">
                <li
                  v-for="(item, index) in supportLinks.navLinks"
                  :key="'support' + index"
                  class="footer-link-list__item my-1"
                >
                  <NavLink
                    v-editable="item"
                    v-track="item.tracking"
                    :link="item.link"
                    :link-type="item.component"
                    class="footer-link-list__link"
                  >
                    {{ item.name }}
                  </NavLink>
                </li>
              </ul>
              <div v-editable="socialLinks">
                <h4
                  v-editable="socialLinks"
                  class="is-size-7 has-text-weight-bold mb-6 mt-8"
                >
                  {{ socialLinks.title }}
                </h4>
                <ul class="footer-social-list">
                  <li
                    v-for="(item, index) in socialLinks.navLinks"
                    :key="'social' + index"
                    class="footer-social-list__item"
                  >
                    <a
                      v-track="item.tracking"
                      v-editable="item"
                      :href="item.link.url"
                      class="footer-social-list__link"
                      :title="item.title"
                      target="_blank"
                      rel="noopener"
                    >
                      <i :class="['icon', item.icon.name]"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <nav
              v-editable="companyLinks"
              class="column is-half-mobile is-half-widescreen"
              aria-label="Company Links"
            >
              <h4
                class="is-size-7 has-text-weight-bold"
                :class="isV2 ? 'mb-2' : 'mb-6'"
              >
                {{ companyLinks.title }}
              </h4>
              <ul class="footer-link-list footer-link-list--columns">
                <li
                  v-for="(item, index) in companyLinksFiltered"
                  :key="'company' + index"
                  class="footer-link-list__item my-1"
                >
                  <template v-if="item.emitEvent">
                    <a
                      class="footer-link-list__link"
                      href="javascript:;"
                      @click="$emit(item.emitEvent)"
                    >
                      {{ item.text }}
                    </a>
                  </template>
                  <template v-else>
                    <NavLink
                      v-editable="item"
                      v-track="item.tracking"
                      :link="item.link"
                      :link-type="item.component"
                      class="footer-link-list__link"
                    >
                      {{ item.name }}
                    </NavLink>
                  </template>
                </li>
              </ul>
            </nav>
            <div class="footer-legal column is-full">
              <p v-editable="copyright" class="footer-legal__copyright">
                {{ copyright }}
              </p>
              <p v-editable="companyInfo">
                {{ companyInfo }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import NavLink from "./NavLink"
import stubDirectives from "../mixins/stubDirectives"
import { PROJECTS } from "../config/projectRouting"

export default {
  inject: {
    currentProject: {
      default: PROJECTS.Website
    }
  },
  components: {
    NavLink
  },
  mixins: [stubDirectives],
  props: {
    supportLinks: {
      type: Object,
      default: () => {
        return {}
      }
    },
    companyLinks: {
      type: Object,
      default: () => {
        return {}
      }
    },
    socialLinks: {
      type: Object,
      default: () => {
        return {}
      }
    },
    copyright: {
      type: String,
      default: ""
    },
    companyInfo: {
      type: String,
      default: ""
    },
    trackingClickGroup: {
      type: [Array, Object],
      default: null
    }
  },
  computed: {
    isV2() {
      return process.env.VUE_APP_NEW_BRANDING === "true"
    },
    footerContainerClasses() {
      return process.env.VUE_APP_NEW_BRANDING === "true"
        ? "footer-container-v2 is-size-7"
        : "footer-container pt-8 pb-9 px-4 is-size-7"
    },
    companyLinksFiltered() {
      let filteredList = []
      if (this.companyLinks && this.companyLinks.navLinks) {
        this.companyLinks.navLinks.map(item => {
          if (
            !item.hideForSites ||
            !item.hideForSites.includes(this.currentProject)
          ) {
            filteredList.push(item)
          }
        })
      }
      return filteredList
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-container {
  background-color: $night;
  color: $day;
}
.footer-container-v2 {
  background-color: $night;
  color: $day;
  margin: $space-11 $space-4 $space-4;
  border-radius: 30px;
}

.footer-logo {
  margin-bottom: 4rem;

  @include md {
    margin: 0;
  }

  &__image {
    width: 155px;
    height: auto;
    display: block;
    margin: 0 auto;

    @include md {
      margin: 0;
    }
  }
}

.footer-link-list {
  list-style: none;
  padding: 0;

  &--columns {
    @include xl {
      column-count: 2;
      column-gap: 1rem;
    }
  }

  &__link {
    text-decoration: none;
    color: $day;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.footer-social-list {
  list-style: none;
  padding: 0;

  &__item {
    display: inline;
  }

  &__link {
    display: inline-block;
    margin-right: 0.75rem;
    line-height: 1em;
    color: $day;
    font-size: 1.5rem;
    width: 1.5rem;
    text-align: center;
  }
}

.footer-legal {
  margin-top: 2.5rem;

  &__copyright {
    margin-bottom: 0.75rem;
  }
}

// FOOTER V2
.footer-container-v2 {
  padding: $space-5;
  margin: $space-11 $space-4 $space-4;
  border-radius: 30px;

  .container {
    max-width: unset !important;
  }
}
</style>
