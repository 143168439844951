import {
  FuelType,
  MINIMUM_ANNUAL_CONSUMPTION,
  SEASONAL_ADJUSTMENT_SUMMER,
  SEASONAL_ADJUSTMENT_WINTER,
} from "@/constants"
import { groupObjectsByProperty } from "@/methods/groupObjectsByProperty"
import { Tariff } from "@/models/Tariff"
import TariffService from "@/services/TariffService"
import { IEstimatedUsage } from "@/store/types"
import { IAgreement } from "@/types/IAgreement"
import { IUsageEstimation } from "@/types/IUsageEstimation"
import dayjs from "dayjs"

export const getAnnualCost = (
  isTwoRate: boolean,
  consumption: IUsageEstimation,
  tariff: Tariff
): number => {
  if (isTwoRate) {
    return (
      (consumption.annualUsageElecDay * tariff.dayUnitRate +
        consumption.annualUsageElecNight * tariff.nightUnitRate) /
        100 +
      getAnnualStandingChargeCost(tariff.standingCharge)
    )
  } else {
    return (
      ((consumption.annualUsageElec || consumption.annualUsageGas) *
        tariff.standardUnitRate) /
        100 +
      getAnnualStandingChargeCost(tariff.standingCharge)
    )
  }
}

export const getAnnualStandingChargeCost = (standingCharge: number): number =>
  (standingCharge * 365) / 100

export const sumEstimatedUsage = (
  meterpointUsage,
  rateName = "Standard"
): number => {
  if (meterpointUsage) {
    return meterpointUsage?.rates?.reduce(
      (total, rate) =>
        rate.rateName === rateName ? total + rate.usage : total,
      0
    )
  } else {
    return MINIMUM_ANNUAL_CONSUMPTION
  }
}

export const getAnnualMeterpointConsumptions = (
  fuelType: string,
  estimatedUsage: IEstimatedUsage
): IUsageEstimation => {
  const consumption = {} as IUsageEstimation
  if (fuelType === FuelType.ELEC2) {
    consumption.annualUsageElecDay = sumEstimatedUsage(estimatedUsage, "Day")
    consumption.annualUsageElecNight = sumEstimatedUsage(
      estimatedUsage,
      "Night"
    )
    consumption.annualUsageElec =
      consumption.annualUsageElecDay + consumption.annualUsageElecNight
  } else if (fuelType === FuelType.ELEC1) {
    consumption.annualUsageElec = sumEstimatedUsage(estimatedUsage)
  } else if (fuelType === FuelType.GAS) {
    consumption.annualUsageGas = sumEstimatedUsage(estimatedUsage)
  }

  return consumption
}
// gas to electricity ratio
export const getAnnualConsumptionsFuelRatio = (
  agreements: { meterpoint: { id: string }; agreement: IAgreement }[],
  estimatedUsages: IEstimatedUsage[]
): number => {
  const acPerFuel = agreements.reduce(
    (total, { meterpoint, agreement }) => {
      const consumption = getAnnualMeterpointConsumptions(
        agreement.type,
        estimatedUsages[meterpoint.id]
      )
      total.gas += consumption.annualUsageGas ?? 0
      total.elec += consumption.annualUsageElec ?? 0
      return total
    },
    { gas: 0, elec: 0 }
  )
  const acTotal = acPerFuel.gas + acPerFuel.elec
  if (!acTotal) return 0
  return acPerFuel.gas / acTotal
}

export const fetchTariffsForAgreements = async (
  meterpointAndAgreementPairs
) => {
  if (!meterpointAndAgreementPairs.length) return Promise.resolve([])

  const agreementsWithTariffs = await Promise.all(
    meterpointAndAgreementPairs.map(async ([meterpoint, agreement]) => {
      const tariff = await TariffService.getTariff(meterpoint, agreement)
      return {
        tariff,
        bundleCode: tariff.tariffCode,
        meterpoint,
        agreement,
      }
    })
  )

  return groupObjectsByProperty(agreementsWithTariffs, "bundleCode")
}

export const calcCostsForAgreements = (agreements, estimatedUsage) => {
  const annualCost = agreements.reduce(
    (total, { meterpoint, agreement, tariff }) => {
      const consumption = getAnnualMeterpointConsumptions(
        agreement.type,
        estimatedUsage[meterpoint.id]
      )
      const annualAgreementCost = getAnnualCost(
        agreement.isTwoRate,
        consumption,
        tariff
      )
      return total + annualAgreementCost
    },
    0
  )

  const fuelRatio = getAnnualConsumptionsFuelRatio(agreements, estimatedUsage)

  const monthlyCost = annualCost / 12.0

  const isSameStartDate = agreements.every((agr) => {
    return dayjs(agr.agreement.fromDt).isSame(
      dayjs(agreements[0].agreement.fromDt)
    )
  })

  return {
    tariff: agreements[0].tariff,
    tariffs: agreements.map((agr) => agr.tariff),
    hasElec: agreements.some((agreement) => agreement.meterpoint.isElectricity),
    hasGas: agreements.some((agreement) => !agreement.meterpoint.isElectricity),
    meterpoints: agreements.map((agreement) => agreement.meterpoint),
    hasOnlySmartMeters: agreements.every(
      (agreement) => agreement.meterpoint.hasOnlySmartMeters
    ),
    hasSmartMeter: agreements.some(
      (agreement) => agreement.meterpoint.hasSmartMeter
    ),
    monthlyCost,
    monthlyCostSummer: SEASONAL_ADJUSTMENT_SUMMER * monthlyCost,
    monthlyCostWinter: SEASONAL_ADJUSTMENT_WINTER * monthlyCost,
    isSameStartDate,
    annualCost,
    fuelRatio,
  }
}
