import mutations from "./mutations"
import actions from "./actions"
import getters from "./getters"

const getBalancePredictionDebugMode = () =>
  localStorage.getItem("balancePredictionDebugMode") === "true"

export const getDefaultState = () => {
  return {
    directDebitMandate: null,
    paymentSummary: null,
    newPaymentSchedule: null,
    suggestedPayment: 0,
    usageProfile: null,
    showBalancePrediction: false,
    balancePredictionDebugMode: getBalancePredictionDebugMode(),
    minimumPaymentAmount: {
      equal: 0,
      summer: 0,
      winter: 0,
    },
    maximumPaymentAmount: {
      equal: 0,
      summer: 0,
      winter: 0,
    },
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations,
  getters,
  actions,
}
