import { PROJECT_URLS } from "@soenergy/frontend-library/src/config/projectRouting"
import store from "@/store/store.js"

export const removeLeadingSlash = (str) => str.replace(/^\/+/, "")

export default (to, from, next) => {
  const initialPageLoad = from.name === undefined
  const redirectParameter = to.query.redirect

  if (initialPageLoad && redirectParameter) {
    let redirectUrl = removeLeadingSlash(redirectParameter)
    const [targetProject, ...targetRoute] = redirectUrl.split("/")
    const targetProjectRootUrl = PROJECT_URLS[targetProject]
    if (targetProjectRootUrl) {
      redirectUrl = `${targetProjectRootUrl}/${targetRoute}`
    }

    store.commit("SET_LOGIN_REDIRECT_PATH", redirectUrl)
  }

  next()
}
