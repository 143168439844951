<template>
  <teleport to="head title">{{ pageTitle }} - So Energy</teleport>
  <div id="account-app" class="app" :style="layoutStyles">
    <AppHeader
      v-if="headerContent"
      :nav="isLinkedToMarketingSite ? headerNavItems : headerContent.links"
      :log-in-link="headerContent.logInLink[0]"
      :sign-out-link="headerContent.signOutLink[0]"
      :user-status="userStatus"
      :user="user"
      :account-number="account.number"
      :is-prospect-switch-journey-enabled="isProspectSwitchJourneyEnabled"
      :hide-nav-menu="eligibleForReadLogin"
      :is-promote-renewal-enabled="isPromoteRenewalEnabled"
      :move-out-record-fetched="moveOutRecordFetched"
      :deployment-url="vercelUrl"
      @logout="logout"
    />
    <GlobalBanner
      v-if="bannerContent"
      v-editable="bannerContent"
      :enabled="bannerContent.enabled"
      :heading="bannerContent.heading"
      :dropdown-content="bannerContent.dropdownContent"
      :pages-to-show-on="bannerContent.pagesToShowOn"
      :expiry-date="bannerContent.expiryDate"
    />
    <main class="page-content">
      <BannerWrapper v-if="!eligibleForReadLogin" />
      <AppGenericModal v-if="isGenericModalOpen" />
      <AppLogout v-if="isLogoutModalOpen" :show="isLogoutModalOpen" />
      <router-view />
    </main>
    <AppFooter
      v-if="footerContent"
      v-editable="footerContent"
      :support-links="
        isLinkedToMarketingSite ? footerNavItems[0] : footerContent.nav[0]
      "
      :company-links="
        isLinkedToMarketingSite ? footerNavItems[1] : footerContent.nav[1]
      "
      :social-links="
        isLinkedToMarketingSite ? footerNavItems[2] : footerContent.nav[2]
      "
      :company-info="footerContent.companyInfo"
      :copyright="footerContent.copyright"
      @reopen-cookie-modal="reopenCookieModal"
    />
    <LoadingOverlay
      :is-active="isLoading"
      :transition="firstRouteLoaded ? 'fade-in-out' : 'fade-out'"
      :style="{ transitionDelay: ready && MIN_LOADING_TIME_TO_SHOW_SPINNER }"
    />
    <template
      v-if="
        cookieBannerContent &&
        cookieModalContent &&
        !isThirdPartyCookieConsentEnabled
      "
    >
      <CookieManager
        ref="CookieManager"
        :banner-text="cookieBannerContent.bannerText[0].textContent"
        :accept-all-button="cookieBannerContent.buttons[0]"
        :manage-cookies-button="cookieBannerContent.buttons[1]"
        :has-manage-cookies="true"
        :modal-title="cookieModalContent.modalTitle"
        :modal-sub-title="cookieModalContent.modalSubTitle"
        :accept-button="cookieModalContent.acceptButton"
        :save-button="cookieModalContent.saveButton"
        :modal-description="cookieModalContent.modalDescription[0].textContent"
        :necessary-description="
          cookieModalContent.necessaryDescription[0].textContent
        "
        :performance-description="
          cookieModalContent.performanceDescription[0].textContent
        "
        :functional-description="
          cookieModalContent.functionalDescription[0].textContent
        "
        :advertising-description="
          cookieModalContent.advertisingDescription[0].textContent
        "
      >
      </CookieManager>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex"
import store from "@/store/store.js"
import AppLogout from "./components/AppLogout.vue"
import AppGenericModal from "./components/AppGenericModal.vue"
import BannerWrapper from "./components/BannerWrapper.vue"
import LoadingOverlay from "@soenergy/frontend-library/src/components/LoadingOverlay"
import AppHeader from "@soenergy/frontend-library/src/components/AppHeader"
import AppFooter from "@soenergy/frontend-library/src/components/AppFooter"
import GlobalBanner from "@soenergy/frontend-library/src/components/GlobalBanner"
import CookieManager from "@soenergy/frontend-library/src/components/CookieBanner/CookieManager"
import UserStatusService from "@soenergy/frontend-library/src/services/UserStatusService"

import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import headerContent from "soenergy-cms-loader!?path=common/header"
import footerContent from "soenergy-cms-loader!?path=layout/footer"
import cookieModalContent from "soenergy-cms-loader!?path=common/cookie-modal"
import cookieBannerContent from "soenergy-cms-loader!?path=common/cookie-banner"
import globalBannerContent from "soenergy-cms-loader!?path=layout/global-banner"
import maintenanceBannerContent from "soenergy-cms-loader!?path=layout/maintenance-banner"
import commonCmsSettings from "soenergy-cms-loader!?path=common/settings&force_prerender=true"
import accountCmsSettings from "soenergy-cms-loader!?path=account/account-settings&force_prerender=true"
import { PROJECTS } from "@soenergy/frontend-library/src/config/projectRouting"
import * as CookieConsentPlugin from "@soenergy/frontend-library/src/services/CookieConsentPlugin"
import { headerNavItems } from "@soenergy/frontend-library/src/config/headerNavItems"
import { footerNavItems } from "@soenergy/frontend-library/src/config/footerNavItems"

store.dispatch("setAccountCmsSettings", accountCmsSettings.content)

export default {
  components: {
    AppLogout,
    AppGenericModal,
    AppHeader,
    AppFooter,
    BannerWrapper,
    CookieManager,
    GlobalBanner,
    LoadingOverlay,
  },
  mixins: [
    cmsPreviewMixin({ story: headerContent, name: "headerContent" }),
    cmsPreviewMixin({ story: footerContent, name: "footerContent" }),
    cmsPreviewMixin({
      story: globalBannerContent,
      name: "globalBannerContent",
    }),
    cmsPreviewMixin({
      story: maintenanceBannerContent,
      name: "maintenanceBannerContent",
    }),
    cmsPreviewMixin({ story: cookieModalContent, name: "cookieModalContent" }),
    cmsPreviewMixin({
      story: cookieBannerContent,
      name: "cookieBannerContent",
    }),
  ],
  provide() {
    return {
      currentProject: PROJECTS.AccountApp,
    }
  },
  data() {
    return {
      isThirdPartyCookieConsentEnabled:
        process.env.VUE_APP_USE_THIRD_PARTY_COOKIE_CONSENT === "true",
      vercelUrl: process.env.VERCEL_URL,
      newBranding: process.env.VUE_APP_NEW_BRANDING === "true",
      isLinkedToMarketingSite:
        process.env.VUE_APP_LINKED_TO_MARKETING_SITE === "true",
      MIN_LOADING_TIME_TO_SHOW_SPINNER: "0.7s",
      registration: null,
      bottomNavHeight: 64,
      bottomNavHeightLg: 64,
      headerNavItems,
      footerNavItems,
    }
  },
  computed: {
    ...mapGetters({
      ready: "ready",
      isLoggedIn: "isLoggedIn",
      isLogoutModalOpen: "modals/isLogoutModalOpen",
      isGenericModalOpen: "modals/isGenericModalOpen",
      account: "account",
    }),
    ...mapState({
      featureData: (state) => state.help.featureData,
      customer: (state) => state.junifer.customer,
      isLoading: (state) => state.loading,
      userStatus: (state) => state.userStatus.userStatus,
      user: (state) => state.user,
      isProspectSwitchJourneyEnabled: (state) =>
        state.isProspectSwitchJourneyEnabled,
      isPromoteRenewalEnabled: (state) => state.isPromoteRenewalEnabled,
      isReadLoginEnabled: (state) => state.isReadLoginEnabled,
      eligibleForReadLogin: (state) => state.readLogin.eligibleForReadLogin,
      moveOutRecordFetched: (state) => state.cotMoveOut.moveOutRecordFetched,
    }),
    bannerContent() {
      const isMaintenanceBannerEnabled = this.maintenanceBannerContent?.enabled

      return isMaintenanceBannerEnabled
        ? this.maintenanceBannerContent
        : this.globalBannerContent
    },
    firstRouteLoaded() {
      return this.$route.name !== null
    },
    layoutStyles() {
      return {
        "--bottom-nav-height": "0px",
        "--bottom-nav-height-lg": "0px",
      }
    },
    pageTitle() {
      const title = this.$route.meta.title || "Account Home"
      let env = ""
      if (!this.production) {
        env = this.local ? "LOCAL " : "STAGING "
      }
      return env + title
    },
  },
  created() {
    if (!this.ready) {
      this.$store.dispatch("help/requestFeatureData")
    }

    this.$store.dispatch("setCommonCmsSettings", commonCmsSettings.content)
  },
  mounted() {
    if (!this.isReadLoginEnabled) {
      UserStatusService.getUserStatusWithToken()
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("modals/showLogoutModal")
    },
    reopenCookieModal() {
      if (this.isThirdPartyCookieConsentEnabled) {
        CookieConsentPlugin.openPreferencesModal()
      } else {
        this.$refs.CookieManager.showModal()
      }
    },
  },
}
</script>

<style lang="scss">
#account-app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app {
  padding-top: 0;
  padding-top: constant(safe-area-inset-top, 0);
  padding-top: env(safe-area-inset-top, 0);
  padding-bottom: 0;
  padding-bottom: constant(safe-area-inset-bottom, 0);
  padding-bottom: env(safe-area-inset-bottom, 0);
}

.page-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: auto; // Fix for IE
  margin-bottom: 0;
  margin-bottom: calc(
    var(--bottom-nav-height, 0px) + var(--sticky-button-height-mobile, 0px)
  );

  @include sm {
    margin-bottom: 0;
    margin-bottom: calc(var(--bottom-nav-height, 0px));
  }

  @include lg {
    margin-bottom: 0;
    margin-bottom: calc(var(--bottom-nav-height-lg, 0px));
  }
}

.statusBar {
  height: 0;
  height: constant(safe-area-inset-top, 0);
  height: env(safe-area-inset-top, 0);
  background-color: $water-dark;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1040;
}
</style>
