import HttpClient from "../http/HttpClient"
import authenticate from "../http/requestInterceptors/authenticate"

const apiClient = HttpClient({
  baseURL: process.env.VUE_APP_ACCOUNT_SITE_URL,
  requestInterceptors: [authenticate]
})

export default {
  getWHDRegionEligibility(accountId) {
    return apiClient.get(
      `/api/v1/accounts/${accountId}/warm_home_discount/region_eligibility`,
      {
        withCredentials: false
      }
    )
  }
}
