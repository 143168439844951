import axios from "axios"
import ErrorReporter from "../ErrorReporter"

const apiClient = () => {
  const baseURL = process.env.VUE_APP_ACCOUNT_SITE_URL
  const header = {
    "Content-Type": "application/json"
  }

  const api = axios.create({
    baseURL,
    "Cache-Control": "no-cache",
    headers: header
  })

  return api
}

export default {
  getSuppliers(data) {
    return apiClient().get(
      "api/providers?postcode=" +
        data.postcode +
        "&energy_type=" +
        data.energyType +
        "&economy_7=false" // TODO: find out what difference this makes, currently looks like nothing
    )
  },
  getTariffs(data) {
    return apiClient().get(
      "api/providers/" +
        data.supplierId +
        "?postcode=" +
        data.postcode +
        "&energy_type=" +
        data.energyType +
        "&economy_7=false" // TODO: find out what difference this makes, currently looks like nothing
    )
  },
  getQuote(data) {
    return apiClient().post("/api/quotes", data, {
      withCredentials: true
    })
  },
  checkIfEmailInUse(email) {
    return apiClient().get(
      `/api/v1/user/validate_email_not_in_use?email=${email}`
    )
  },
  validateBankDetails({ accountNumber, accountSortCode }) {
    return apiClient().post(
      "/api/validate/bank_details",
      {
        bank_account_number: accountNumber,
        sort_code: accountSortCode
      },
      { skipErrorReporting: true }
    )
  },
  registerCustomer(details) {
    return axios({
      method: "post",
      url: "/enrolments",
      baseURL: process.env.VUE_APP_API_GATEWAY_URL,
      data: details,
      withCredentials: false,
      skipErrorReporting: true
    })
  },
  getNewReferralData(userId) {
    return apiClient().get("/api/v1/customer/voucher/" + userId)
  }
}
