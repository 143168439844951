import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import RailsService from "@/services/RailsService.js"
import CotMoveOut from "@/models/CotMoveOut.ts"

dayjs.extend(customParseFormat)

export const occupantType = {
  homeOwner: "HOME_OWNER",
  tenant: "TENANT",
  landlord: "LANDLORD",
  lettingAgency: "LETTING_AGENCY",
  unknown: "UNKNOWN",
}

const setAddressLine1ToNotEmpty = (address) => {
  let newAddress = { ...address }
  let addressKeys = [
    "address1",
    "address2",
    "address3",
    "address4",
    "address5",
    "address6",
    "address7",
    "address8",
    "address9",
  ]
  const notNullAddressInstance = addressKeys.find(
    (addressLine) => !!address[addressLine]
  )
  if (!notNullAddressInstance)
    throw new Error("Failed to create SupplyAddress: address lines are empty")

  newAddress.address1 = newAddress[notNullAddressInstance]
  newAddress[notNullAddressInstance] = ""
  return newAddress
}

const constructMoveOutRequestPayload = ({
  lastDateOfResponsibility,
  currentTenant,
  futureTenant,
  forwardingAddress,
}) => {
  const address = forwardingAddress.address1
    ? forwardingAddress
    : setAddressLine1ToNotEmpty(forwardingAddress)
  return {
    last_date_of_responsibility: dayjs(
      lastDateOfResponsibility,
      "DD/MM/YYYY"
    ).format("YYYY-MM-DD"),
    type: currentTenant.value,
    forwarding_address: {
      mpan: address.mpan,
      mprn: address.mprn,
      address_line_1: address.address1,
      address_line_2: address.address2,
      address_line_3: address.address3,
      address_line_4: address.address4,
      address_line_5: address.address5,
      address_line_6: address.address6,
      address_line_7: address.address7,
      address_line_8: address.address8,
      address_line_9: address.address9,
      country_code: "GB",
      postcode: address.postcode.toUpperCase(),
    },
    new_occupant_contact: {
      type: futureTenant.type.value,
      name: futureTenant.fullName,
      email: futureTenant.email,
      phone_number: futureTenant.phone,
    },
  }
}

export const getCotMoveOut = async (currentAccountId) => {
  try {
    const response = await RailsService.fetchCotMoveOut(currentAccountId)
    if (!response.data) return null
    return new CotMoveOut(response.data)
  } catch (error) {
    const isExpectedError = error?.response?.status == 400
    if (!isExpectedError) throw error

    return null
  }
}

export const submitMoveOut = (cotRequestBody, currentAccountId) =>
  RailsService.cotMoveOut(
    constructMoveOutRequestPayload(cotRequestBody),
    currentAccountId
  )
