import dayjs from "dayjs"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import RailsService from "../../services/RailsService.js"
import { isSmartMeter as isSmartMeterType } from "@soenergy/frontend-library/src/helpers/meterTypeHelper"
import { notReadyForSecondRead } from "@/methods/readingSubmissionEligibility"
import { Meterpoint } from "@/models/Meterpoint"
import { sumEstimatedUsage } from "@/helpers/agreementCostHelper"
import { datesMixin } from "@/mixins/datesMixin.js"
import { isEmptyObject } from "@soenergy/frontend-library/src/helpers/objectHelper"
import ErrorReporter from "@soenergy/frontend-library/src/services/ErrorReporter"

dayjs.extend(isSameOrAfter)

const GAS = "Gas",
  ELECTRICITY = "Electricity",
  CUBIC_FOOT = "ft3",
  DAYS_IN_YEAR = 365,
  MULTIPLIER_GAS = 6,
  MULTIPLIER_ELECTRICITY = 4,
  MIN_BOUND_GAS = 300,
  MIN_BOUND_ELECTRICITY = 900,
  MFAC_FT = 0.031412,
  MFAC_M = 0.089348,
  MFAC_NONE = 1,
  A_LONG_TIME_AGO = "6 Jan 1964"

export const namespaced = true

export const state = {
  meterReadFrequency: undefined,
  meterStatus: {},
  meterStatusPriorities: {
    PENDING: 1,
    ERROR: 2,
    CONTACTED: 3,
    NO_ACTION_REQUIRED: 4,
  },
  meterReadings: {},
  meterReadingsError: false,
}

export const mutations = {
  NEW_READ() {
    //console.log(read, register);
  },
  SET_READ_FREQUENCY(state, meterReadFrequency) {
    state.meterReadFrequency = meterReadFrequency
  },
  SET_METER_STATUS(state, data) {
    state.meterStatus[data.meterpointId] = {
      status: data.finalStatus,
      submissionValues: data.finalSubmissionValues,
    }
  },
  SET_METER_READINGS(state, data) {
    if (!state.meterReadings[data.currentAccount]) {
      state.meterReadings[data.currentAccount] = {}
    }
    state.meterReadings[data.currentAccount].electricity = data.electricity
    state.meterReadings[data.currentAccount].gas = data.gas
  },
  SET_METER_READINGS_ERROR(state, data) {
    state.meterReadingsError = data
  },
}

export const actions = {
  newRead({ commit, rootState }, newRead) {
    return RailsService.postRead(newRead).then(() => {
      commit("NEW_READ", newRead)
      commit("SET_METER_READINGS", {
        currentAccount: rootState.currentAccount,
        electricity: {},
        gas: {},
      })
    })
  },
  async fetchReadFrequency({ state, getters, commit }) {
    if (getters.hasLegacyMeter || state.meterReadFrequency !== undefined) {
      return
    }
    const { data } = await RailsService.getReadFrequency()
    if (data && data.current) {
      commit("SET_READ_FREQUENCY", data.current)
    }
  },
  async updateReadFrequency({ commit }, newFrequency) {
    if (newFrequency.frequency === "monthly") {
      await RailsService.updateReadFrequency({
        frequency: "half_hourly",
        setting: "false",
      })
      await RailsService.updateReadFrequency({
        frequency: "daily",
        setting: "false",
      })
    } else {
      await RailsService.updateReadFrequency(newFrequency)
    }
    commit("SET_READ_FREQUENCY", newFrequency.frequency)
  },
  getAllMeterReadStatus({ getters, dispatch }) {
    for (const serialNumber in getters.allCurrent) {
      getters.allCurrent[serialNumber].map((meterPoint) => {
        dispatch("getMeterReadStatus", meterPoint.endpointID)
      })
    }
  },
  async getMeterReadStatus({ commit, state }, meterpointId) {
    if (!process.env.METER_READING_ASYNC_FLOW) {
      commit("SET_METER_STATUS", { meterpointId, finalStatus: "SUBMITTED" })
    } else {
      let response = null
      try {
        response = await RailsService.getLastMeterReadStatus(meterpointId)
      } catch (error) {
        const isExpectedError = error?.response?.status == 404
        if (!isExpectedError) throw error
        return null
      }

      if (response && response.data) {
        let finalSubmissionValues = {}
        const lastReading = response.data

        if (
          lastReading.submissionValues &&
          lastReading.submissionValues.length
        ) {
          lastReading.submissionValues.map((submissionValue) => {
            const dateProp = submissionValue.type + "ReadDate"
            const valueProp = submissionValue.type + "ReadValue"
            finalSubmissionValues[dateProp] = datesMixin.methods.formatDate(
              submissionValue.date
            )
            finalSubmissionValues[valueProp] = submissionValue.value
          })
        }

        commit("SET_METER_STATUS", {
          meterpointId,
          finalStatus: lastReading.meterReadingSubmissionStatus,
          finalSubmissionValues,
        })
      }
    }
  },
  async getReadings({ commit, dispatch, rootState }) {
    commit("SET_METER_READINGS_ERROR", false)
    try {
      const { data } = await RailsService.getReadings()
      if (data.length) {
        const readingForAccount = data.find(
          (readings) => readings.billingAccountId === rootState.currentAccount
        )
        dispatch(
          "parseReadings",
          readingForAccount.meterPointToRegisterReadings
        )
      }
    } catch (error) {
      ErrorReporter.report(error)
      commit("SET_METER_READINGS_ERROR", true)
    }
  },
  parseReadings({ commit, getters, rootState }, readings) {
    const trimReadings = (readingList, type) => {
      return readingList.map((reading) => ({
        consumption: reading.consumption,
        cumulative: reading.cumulative,
        readingDate: reading.readingDttm,
        readingType: reading.source,
        meterPointIdentifier: reading.meterPointIdentifier,
        rateName: reading.rateName,
        unit: reading.unit,
        type,
        isMultiRate: reading.rateName === "Day" || reading.rateName === "Night",
      }))
    }
    // get the elec readings for the matching meterpoint ids
    const elecReadingsByMeterpoint = getters.allCurrentElectricityEndpoints.map(
      ({ id: meterPointId }) => readings[meterPointId]?.registerToMeterReadings
    )
    let elecReadingsByRegister = {}
    elecReadingsByMeterpoint.map((registers) => {
      for (let registerId in registers) {
        elecReadingsByRegister[registerId] = trimReadings(
          registers[registerId],
          "MPAN"
        )
      }
    })
    // get the gas readings for the matching meterpoint ids
    const gasReadingsByMeterpoint = getters.allCurrentGasEndpoints.map(
      ({ id: meterPointId }) => readings[meterPointId]?.registerToMeterReadings
    )
    let gasReadingsByRegister = {}
    gasReadingsByMeterpoint.map((registers) => {
      for (let registerId in registers) {
        gasReadingsByRegister[registerId] = trimReadings(
          registers[registerId],
          "MPRN"
        )
      }
    })
    commit("SET_METER_READINGS", {
      currentAccount: rootState.currentAccount,
      electricity: elecReadingsByRegister,
      gas: gasReadingsByRegister,
    })
  },
}

export const getters = {
  allCurrentElectricityEndpoints(state, getters, rootState, rootGetters) {
    return rootGetters.allCurrentElectricityProducts.reduce(
      (meters, products) => {
        products.assets.forEach((asset) => {
          meters.push(
            Object.assign({}, asset, rootGetters.meterpoints[asset.id])
          )
        })
        return meters
      },
      []
    )
  },
  allCurrentGasEndpoints(state, getters, rootState, rootGetters) {
    return rootGetters.allCurrentGasProducts.reduce((meters, products) => {
      products.assets.forEach((asset) => {
        meters.push(Object.assign({}, asset, rootGetters.meterpoints[asset.id]))
      })
      return meters
    }, [])
  },
  allCurrentElectricity(state, getters, rootState, rootGetters) {
    return getMetersFromProducts(
      rootGetters.allCurrentElectricityProducts,
      getters,
      rootGetters,
      ELECTRICITY
    )
  },
  hasElectricity(state, getters) {
    return Object.keys(getters.allCurrentElectricity).length > 0
  },
  allCurrentGas(state, getters, rootState, rootGetters) {
    return getMetersFromProducts(
      rootGetters.allCurrentGasProducts,
      getters,
      rootGetters,
      GAS
    )
  },
  allCurrent(state, getters) {
    return Object.assign(
      {},
      getters.allCurrentElectricity,
      getters.allCurrentGas
    )
  },
  meterpointAgreementPairs(state, getters, rootState, rootGetters) {
    const meterpoints = rootGetters.meterpoints

    let meterpointAgreementPairs = []

    const fuelAgreements = rootGetters["agreements/agreementsArray"].reduce(
      (agreements, current) => {
        agreements.push(
          ...current.fuels.map((fuel) => ({ ...current, ...fuel }))
        )
        return agreements
      },
      []
    )

    for (let agreement of fuelAgreements) {
      agreement.endpoints.forEach((endpoint) => {
        meterpointAgreementPairs.push([
          new Meterpoint(meterpoints[endpoint.id]),
          agreement,
        ])
      })
    }

    return meterpointAgreementPairs
  },
  lastReadMinMax(state, getters) {
    return getLastReadMinMax(getters.allCurrent)
  },
  hasGas(state, getters) {
    return Object.keys(getters.allCurrentGas).length > 0
  },
  hasMeterpoints(state, getters) {
    return getters.hasElectricity || getters.hasGas
  },
  getLastRealRead(state, getters, rootState, rootGetters) {
    return (assetID, meter, register, readSource) => {
      return rootGetters.readings[assetID]
        .filter(
          (read) =>
            read.meter === meter &&
            read.quality !== "Estimated" &&
            (readSource ? read.source === readSource : true) &&
            (register ? read.register === register : true)
        )
        .sort(readingsAscending)
        .pop()
    }
  },
  getFirstRead(state, getters, rootState, rootGetters) {
    return (assetID, meter, register) => {
      return rootGetters.readings[assetID].find(
        (read) =>
          read.meter === meter &&
          (register ? read.register === register : true) &&
          read.sequenceType === "First"
      )
    }
  },
  getUnit(state, getters, rootState, rootGetters) {
    return (type, assetID) => {
      let readings = rootGetters.readings[assetID]

      if (type === ELECTRICITY) return "kWh"
      if (readings.length) {
        let last = readings
          .filter((read) => read.sequenceType !== "Last")
          .sort(readingsAscending)
          .pop()
        if (last.unit === "hcf") return "ft3"
        return last.unit
      }
      return null
    }
  },
  getEstimatedUsage(state, getters, rootState, rootGetters) {
    return (assetID, rateName) => {
      return (
        sumEstimatedUsage(rootGetters.estimated_usage[assetID], rateName) ||
        sumEstimatedUsage(rootGetters.estimated_usage[assetID])
      )
    }
  },
  readGivenInLast90Days(state, getters, rootState, rootGetters) {
    const reads = rootGetters.readings

    if (Object.keys(reads).length > 0) {
      const key = Object.keys(reads)[Object.keys(reads).length - 1]
      const lastRead = reads[key][reads[key].length - 1]
      const lastReadDate = dayjs(lastRead.receivedDttm).startOf("day")

      return lastReadDate.isSameOrAfter(dayjs().subtract(90, "day"))
    }

    return false
  },
  hasNonCommunicatingSmartMeter(state, getters, rootState, rootGetters) {
    return nonCommunicatingSmartMeter(
      getters.allCurrent,
      Object.values(rootGetters.meterpoints)
    )
  },
  hasCommunicatingSmartMeter(state, getters) {
    return communicatingSmartMeter(getters.allCurrent)
  },
  hasLegacyMeter(state, getters) {
    return legacyMeter(getters.allCurrent)
  },
  hasSmartMeter(state, getters) {
    return Object.values(getters.allCurrent).some((meters) =>
      meters.some((meter) => meter.isSmartMeter)
    )
  },
  hasPrepaymentMeter(state, getters, rootstate, rootgetters) {
    const meterpoints = rootgetters.meterpoints
    if (!meterpoints) return

    return Object.keys(meterpoints).some(
      (meterpoint) =>
        meterpoints[meterpoint] &&
        meterpoints[meterpoint].operationType &&
        meterpoints[meterpoint].operationType === "SmartPAYG"
    )
  },
  allEndpointsAreLeaving(state, getters, rootState, rootGetters) {
    let endpointsAreLeaving = true
    for (const endpointId in rootGetters.supply_status) {
      const endpoint = rootGetters.supply_status[endpointId]
      let notInCooling = dayjs()
        .subtract(15, "days")
        .isAfter(dayjs(endpoint.createdDttm))

      endpointsAreLeaving =
        endpointsAreLeaving &&
        (endpoint.supplyStatus === "LossConfirmed" ||
          (endpoint.eventType === "LossInitiated" && notInCooling))
    }
    return endpointsAreLeaving
  },
  meterStatusPerFuel(state, getters, rootState, rootGetters) {
    const meterpoints = {
      electricity: getters.allCurrentElectricity,
      gas: getters.allCurrentGas,
    }
    let meterStatusPerFuel = {}

    for (const fuel in meterpoints) {
      const meters = meterpoints[fuel]
      const meterpointStatus = {
        hasLegacyMeter: legacyMeter(meters),
        hasNonCommunicatingSmartMeter: nonCommunicatingSmartMeter(
          meters,
          Object.values(rootGetters.meterpoints)
        ),
        hasCommunicatingSmartMeter: communicatingSmartMeter(meters),
        lastReadMinMax: getLastReadMinMax(meters),
      }

      meterStatusPerFuel[fuel] = meterpointStatus
    }

    return meterStatusPerFuel
  },
  // Returns true if meters received readings today - not necessarily for today's date, the readings may have been submitted for different date
  allMetersReceivedReadingsToday(state, getters, rootState, rootGetters) {
    if (isEmptyObject(getters.allCurrent)) return false

    const meters = Object.values(getters.allCurrent)

    const today = dayjs().startOf("day")
    return meters.every(([{ endpointID, meterUID }]) => {
      const isSubmittedToday = !!rootGetters.readings[endpointID].find(
        (read) =>
          read.receivedDttm && today.isSame(dayjs(read.receivedDttm), "day")
      )
      const isSubmittedTodayAndPending =
        rootGetters[
          "pendingReadingSubmissions/metersWithTodayReading"
        ].includes(meterUID)

      return isSubmittedToday || isSubmittedTodayAndPending
    })
  },
  notReadyForSecondRead(state, getters) {
    const meters = getters.allCurrent
    if (!meters) return
    return Object.values(meters).every(notReadyForSecondRead)
  },
  getReadingsFiltered:
    (state, getters, rootState) => (fuel, pages, selectedMeterPoint) => {
      const recordAmount = pages * 10
      let reads =
        state.meterReadings[rootState.currentAccount][fuel][selectedMeterPoint]
      if (!reads) reads = []
      return {
        reads: reads.slice(0, recordAmount),
        remainingReads: reads.length - recordAmount,
      }
    },
}

// Private functions
const readingsAscending = (read1, read2) => {
  const r1 = dayjs(read1.readingDttm)
  const r2 = dayjs(read2.readingDttm)

  if (r1.isBefore(r2)) return -1
  if (r1.isAfter(r2)) return 1
  if (read1.cumulative < read2.cumulative) return -1
  if (read1.cumulative > read2.cumulative) return 1
  return 0
}
const registersAscending = (register1, register2) => {
  if (register1.meterIdentifier < register2.meterIdentifier) return -1
  if (register1.meterIdentifier > register2.meterIdentifier) return 1
  if (register1.registerIdentifier < register2.registerIdentifier) return -1
  if (register1.registerIdentifier > register2.registerIdentifier) return 1
  return 0
}

const getUpperBound = (type, unit, read, readDate, estimatedUsage, digits) => {
  if (!readDate) return 0

  const mfac =
    type === GAS ? (unit === CUBIC_FOOT ? MFAC_FT : MFAC_M) : MFAC_NONE

  const daysSinceRead = dayjs().diff(dayjs(readDate), "day")
  const registerRollover = 10 ** digits // 10 to the power of digits
  const upperBound =
    Math.ceil(
      Math.max(
        type === GAS ? MIN_BOUND_GAS : MIN_BOUND_ELECTRICITY,
        (daysSinceRead *
          estimatedUsage *
          (type === GAS ? MULTIPLIER_GAS : MULTIPLIER_ELECTRICITY) *
          mfac) /
          DAYS_IN_YEAR
      )
    ) + read

  return upperBound >= registerRollover
    ? upperBound - registerRollover
    : upperBound
}
const getMetersFromProducts = (products, getters, rootGetters, type) => {
  return products
    .reduce((registers, product) => {
      product.assets.forEach((asset) => {
        const meterpoint = rootGetters.meterpoints[asset.id]
        if (meterpoint.meters.length) {
          meterpoint.meters.forEach((meter) => {
            meter.registers.forEach((register) => {
              const lastRead = getters.getLastRealRead(
                asset.id,
                meter.identifier,
                register.identifier
              )
              const hasFirstRead = !!getters.getFirstRead(
                asset.id,
                meter.identifier,
                register.identifier
              )
              const lastAutomatedRead = getters.getLastRealRead(
                asset.id,
                meter.identifier,
                register.identifier,
                "SMR"
              )
              const unit = getters.getUnit(type, asset.id)
              const estimatedUsage = getters.getEstimatedUsage(
                asset.id,
                register.rateName
              )
              const lr = lastRead ? lastRead.cumulative : lastRead
              const lrd = lastRead ? lastRead.readingDttm : lastRead
              const isSmartMeter = isSmartMeterType(meter.meterType)
              const lastAutomatedReadDate =
                lastAutomatedRead && lastAutomatedRead.readingDttm
              const isSendingAutomatedReads =
                isSmartMeter &&
                lastAutomatedReadDate &&
                dayjs(lastAutomatedReadDate).isSameOrAfter(
                  dayjs().subtract(31, "day")
                )

              registers.push({
                endpointID: asset.id,
                meterpointIdentifier: meterpoint.identifier,
                meterpointType: meterpoint.type,
                meterIdentifier: meter.identifier,
                meterType: meter.meterType,
                meterUnit: unit,
                registerIdentifier: register.identifier,
                digits: register.digits,
                supplyStartDate: meterpoint.supplyStartDate,
                tariffStartDate: product.fromDt,
                rateName: register.rateName,
                estimatedUsage: estimatedUsage,
                upperBound: getUpperBound(
                  type,
                  unit,
                  lr,
                  lrd,
                  estimatedUsage,
                  register.digits
                ),
                lastRead: lr,
                lastReadDate: lrd ? dayjs(lrd).format("D MMM YYYY") : lrd,
                hasFirstRead: hasFirstRead,
                isSmartMeter,
                isSendingAutomatedReads,
                meterUID: asset.id + meter.identifier,
                meterPointId: meterpoint.id,
              })
            })
          })
        } else {
          const unit = getters.getUnit(type, asset.id)

          let meterInfo = {
            endpointID: asset.id,
            meterpointIdentifier: meterpoint.identifier,
            meterpointType: meterpoint.type,
            meterIdentifier: "",
            meterType: "",
            meterUnit: unit,
            registerIdentifier: "",
            digits: 7,
            supplyStartDate: meterpoint.supplyStartDate,
            tariffStartDate: product.fromDt,
            estimatedUsage: 0,
            upperBound: undefined,
            lastRead: undefined,
            lastReadDate: undefined,
            hasFirstRead: false,
            isSmartMeter: undefined,
            isSendingAutomatedReads: undefined,
            meterUID: asset.id,
          }

          if (meterpoint.type === "MPAN" && meterpoint.ukProfileClass == 2) {
            registers.push({ ...meterInfo, rateName: "Day" })
            registers.push({ ...meterInfo, rateName: "Night" })
          } else {
            registers.push(meterInfo)
          }
        }
      })
      return registers
    }, [])
    .sort(registersAscending)
    .reduce((meters, register) => {
      // eslint-disable-next-line no-prototype-builtins
      if (!meters.hasOwnProperty(register.meterIdentifier)) {
        meters[register.meterIdentifier] = []
      }
      meters[register.meterIdentifier].push(register)
      return meters
    }, {})
}

const getLastReadMinMax = (meterpoints) => {
  let lrMinMax = Object.values(meterpoints).reduce(
    (minMax, meter) => {
      const df = "D MMM YYYY"
      return meter.reduce((mM, register) => {
        const { lastReadDate } = register
        // test > max
        if (
          lastReadDate &&
          (!mM.max || dayjs(lastReadDate, df).isAfter(dayjs(mM.max, df)))
        ) {
          mM.max = register.lastReadDate
          mM.meterType = register.meterType
        }

        // test < min or null
        if (!lastReadDate) {
          mM.min = A_LONG_TIME_AGO
        } else if (
          !mM.min ||
          dayjs(lastReadDate, df).isBefore(dayjs(mM.min, df))
        ) {
          mM.min = register.lastReadDate
        }
        return mM
      }, minMax)
    },
    { min: null, max: null }
  )

  if (lrMinMax.min === A_LONG_TIME_AGO) {
    lrMinMax.min = null
  }
  return lrMinMax
}

const nonCommunicatingSmartMeter = (meterpoints, meters) => {
  if (!Object.keys(meterpoints).length) return

  return Object.keys(meterpoints).some((meterpoint) => {
    if (!meterpoints[meterpoint] || !meterpoints[meterpoint][0]) return
    const meter = meterpoints[meterpoint][0]
    const meterDetails = meters.find(
      (meter) =>
        meter.meterpointIdentifier === meterpoints[meterpoint].identifier
    )
    return (
      !meter.isSendingAutomatedReads &&
      meter.isSmartMeter &&
      dayjs(meter.supplyStartDate).add(31, "day").isBefore(dayjs()) &&
      meterDetails.meterPointServiceType === "DCC"
    )
  })
}

const communicatingSmartMeter = (meterpoints) => {
  if (!Object.keys(meterpoints).length) return

  return Object.keys(meterpoints).some((meterpoint) => {
    return (
      meterpoints[meterpoint] &&
      meterpoints[meterpoint][0] &&
      meterpoints[meterpoint][0].isSendingAutomatedReads
    )
  })
}

const legacyMeter = (meterpoints) => {
  if (!Object.keys(meterpoints).length) return

  return Object.keys(meterpoints).some((meterpoint) => {
    return (
      meterpoints[meterpoint] &&
      meterpoints[meterpoint][0] &&
      !meterpoints[meterpoint][0].isSmartMeter
    )
  })
}
