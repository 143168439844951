<template>
  <div class="banner">
    <div class="container banner-container">
      <div class="columns is-desktop">
        <div class="column">
          <section class="section banner__overview">
            <h1 class="h3 banner__title" data-testid="title">
              {{ overviewTitle }}
            </h1>
            <Article
              v-bind="overviewContent[0]"
              data-testid="overview-content"
              class="banner__overview-content"
            />
          </section>
        </div>
        <div class="column is-6-desktop">
          <section class="section banner__action">
            <Checkbox
              v-if="isConsentRequired"
              v-model="isActionAccepted"
              :label="checkboxContent"
              :has-error="isAcceptanceErrorShown"
              :error-message="isAcceptanceErrorShown && checkboxErrorMessage"
              input-id="marketingOptInCheck"
              class="mb-5"
            />
            <Article
              v-bind="actionContent[0]"
              data-testid="action-content"
              class="mb-5"
            />
            <Article
              v-bind="actionDisclaimer[0]"
              data-testid="action-disclaimer"
              class="banner__disclaimer"
            />
            <FeedbackDialog
              v-if="SubmissionStatus.ERROR === submissionStatus"
              ref="generic-error-message"
              :message="actionErrorMessage"
              variant="negative"
              class="mb-5"
            />
            <AppButton
              :label="actionButtonLabel"
              variant="invertedPrimary"
              :loading="SubmissionStatus.PENDING === submissionStatus"
              @click="submitAction"
            />
          </section>
        </div>
      </div>
    </div>
    <button class="banner__close-icon" aria-label="close" @click="closeBanner">
      <i class="icon icon-close"></i>
    </button>
  </div>
</template>

<script>
import Article from "@soenergy/frontend-library/src/components/Article"
import AppButton from "@soenergy/frontend-library/src/components/AppButton"
import Checkbox from "@soenergy/frontend-library/src/components/Checkbox"
import FeedbackDialog from "@soenergy/frontend-library/src/components/FeedbackDialog"
import MixpanelService from "@soenergy/frontend-library/src/services/Mixpanel"

const SubmissionStatus = {
  PENDING: "pending",
  ERROR: "error",
}

const BannerEvents = {
  DISPLAY: "Impression",
  CLOSE: "Closed",
  "OPT-IN": "Opt-in",
}

const BannerNames = {
  "marketing-opt-in-banner": "Marketing Banner",
}

export default {
  components: { Article, AppButton, Checkbox, FeedbackDialog },
  props: {
    overviewTitle: {
      type: String,
      required: true,
    },
    overviewContent: {
      type: Object,
      required: true,
    },
    overviewBackgroundColor: {
      type: String,
      required: true,
    },
    overviewBackgroundImage: {
      type: Object,
      required: true,
    },
    checkboxContent: {
      type: String,
      default: null,
    },
    actionDisclaimer: {
      type: Object,
      required: true,
    },
    actionContent: {
      type: Object,
      required: true,
    },
    actionButtonLabel: {
      type: String,
      default: null,
    },
    actionErrorMessage: {
      type: String,
      default: null,
    },
    checkboxErrorMessage: {
      type: String,
      default: null,
    },
    action: {
      type: Function,
      default: null,
    },
    bannerId: {
      type: String,
      default: null,
    },
  },
  emits: ["close"],
  data() {
    return {
      isActionAccepted: false,
      isAcceptanceErrorShown: false,
      submissionStatus: null,
      submissionErrorMessage: null,
    }
  },
  computed: {
    SubmissionStatus() {
      return SubmissionStatus
    },
    backgroundImageSrc() {
      if (this.newBranding) {
        return ""
      }

      return `url(${this.overviewBackgroundImage.filename})`
    },
    isConsentRequired() {
      return !!this.checkboxContent
    },
  },
  mounted() {
    this.bannerEvent(BannerEvents.DISPLAY)
  },
  methods: {
    closeBanner() {
      this.$emit("close")
      this.bannerEvent(BannerEvents.CLOSE)
    },
    async submitAction() {
      if (this.isConsentRequired) {
        this.isAcceptanceErrorShown = !this.isActionAccepted
        if (!this.isActionAccepted) {
          return
        }
      }
      try {
        this.submissionStatus = SubmissionStatus.PENDING
        await this.action()
        this.bannerEvent(BannerEvents["OPT-IN"])
        this.$emit("close")
      } catch (error) {
        this.submissionStatus = SubmissionStatus.ERROR
      }
    },
    bannerEvent(event) {
      if (this.bannerId in BannerNames) {
        MixpanelService.sendEvent(`${event} - ${BannerNames[this.bannerId]}`)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.banner {
  position: fixed;
  background: white;
  left: 0;
  min-height: 100%;
  height: 100%;
  min-width: 100vw;
  text-align: left;
  z-index: zindex(fullscreenBanner);
  border-bottom: none;
  top: $spacing-8;
  overflow: auto;
  padding-bottom: $spacing-8;

  &__separator {
    margin-bottom: $spacing-5;
    background-color: $night;
  }

  &__close-icon {
    position: absolute;
    padding: 0 $spacing-2;
    background-color: transparent;
    color: $night;
    border: 0;
    font-size: $font-size-6;
    right: $spacing-4;
    top: $spacing-7;
    cursor: pointer;

    @include xxl {
      top: $spacing-5;
      right: $spacing-9;
    }
  }

  @include lg {
    min-width: 100%;
  }

  @include xl {
    top: $spacing-9;
  }

  &__overview {
    padding-right: $spacing-8;
    position: relative;

    @include lg {
      position: static;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
      background: v-bind(backgroundImageSrc), v-bind(overviewBackgroundColor);
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;

      background-size: 100%;
      background-position: bottom;

      @include sm {
        background-size: contain;
        background-position: bottom right;
      }

      @include lg {
        background-size: 100%;
        width: 50vw;
        background-position: top;
      }
    }
  }

  &__action {
    @include lg {
      padding-left: $spacing-8;
    }

    @include xxl {
      padding-left: $spacing-10;
      padding-right: $spacing-9;
    }
  }

  &__overview,
  &__action {
    padding-top: $spacing-5;

    @include lg {
      padding-top: $spacing-9;
    }
  }

  &__title {
    text-transform: none;
  }
}
.banner-container {
  position: static;
}
</style>
