import styleVars from "../../stylesheets/_export.module.scss"
import { isServer } from "../../helpers/isServer"

export const watchBreakpointChanges = (
  breakpoints,
  breakpointChangedCallback
) => {
  if (isServer()) return

  const addMediaListener = (name, width) => {
    const onBreakpointChange = e => breakpointChangedCallback(name, e.matches)
    const query = window.matchMedia(`(min-width: ${width})`)
    if (query?.addEventListener) {
      query.addEventListener("change", onBreakpointChange)
    } else {
      query.addListener(onBreakpointChange)
    }
    breakpointChangedCallback(name, query.matches)
  }
  for (const [name, width] of Object.entries(breakpoints)) {
    addMediaListener(name, width)
  }
}

export const getDefaultBreakpoints = () => ({
  xs: styleVars["breakpoint-screenXs"],
  sm: styleVars["breakpoint-screenSmMin"],
  md: styleVars["breakpoint-screenMdMin"],
  lg: styleVars["breakpoint-screenLgMin"],
  xl: styleVars["breakpoint-screenXlMin"],
  xxl: styleVars["breakpoint-screenXxlMin"]
})
