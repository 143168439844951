<template>
  <div class="show-password">
    <input
      :id="inputName + '-show'"
      v-model="showPassword"
      class="show-password__input"
      type="checkbox"
      aria-label="Show password"
    />
    <label class="show-password__label" :for="inputName + '-show'">
      <template v-if="showPassword">
        <i class="show-password__icon icon icon-view"></i>
      </template>
      <template v-else>
        <i class="show-password__icon icon icon-hide"></i>
      </template>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    inputName: {
      type: String,
      default: "",
    },
  },
  emits: ["showPassChanged"],
  data() {
    return {
      showPassword: false,
    }
  },
  watch: {
    showPassword(value) {
      this.$emit("showPassChanged", value)
    },
  },
}
</script>

<style scoped lang="scss">
.show-password {
  position: absolute;
  top: $space-5;
  right: $space-2;
  float: right;
  font-size: $size-6;
  margin-bottom: $spacing-2;

  &__input {
    opacity: 0;
    position: absolute;
    z-index: -1;

    &:focus {
      outline: 0;

      + label {
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
      }
    }
  }

  &__label {
    line-height: 1.5;
    cursor: pointer;
    padding: 2px;
    user-select: none;
    font-size: $size-6;
  }

  &__icon {
    color: $night-light;
    width: 24px;
    text-align: center;
    font-weight: $weight-bold;

    &:hover {
      color: darken($night-light, 8%);
    }
  }
}
</style>
