import dayjs from "dayjs"
import store from "@/store/store.js"
import PersistentStoreService from "@soenergy/frontend-library/src/services/PersistentStoreService"

let inRenewalIframe = false
let iFrameReloaded = false

window.addEventListener("message", (e) => {
  var key = e.message ? "message" : "data"
  var messageContent = e[key]

  if (messageContent && messageContent.inRenewalIframe) {
    inRenewalIframe = true
  }
  if (messageContent && messageContent.iFrameReloaded) {
    iFrameReloaded = true
  }
})
export default (to, from, next) => {
  const initialPageLoad = from.name === undefined
  const isStandaloneRenewal = !!to.query.standalone_renewal
  if (initialPageLoad && isStandaloneRenewal) {
    PersistentStoreService.disablePersistence()
    PersistentStoreService.clearStoredState()

    const { token, account_id } = to.query

    store.commit("SET_RENEW_STANDALONE_MODE", true)

    store.commit(`authentication/SET_AUTHENTICATION_DATA`, {
      token,
      expiryDate: dayjs().add(1, "day").format("MM-DD-YYYY HH:MM"),
    })
    store.dispatch("setCurrentAccount", account_id)
    next("/renew")
    return
  }
  if (
    !initialPageLoad &&
    isStandaloneRenewal &&
    to.name !== "renew-confirmation"
  ) {
    store.commit("UPDATE_LOADING", true)
    window.history.back()
    return
  }

  const navigatingOutsideOfRenewal =
    to.name !== "renew-confirmation" &&
    to.name !== "renew" &&
    to.name !== "logout"

  if ((inRenewalIframe || isStandaloneRenewal) && navigatingOutsideOfRenewal) {
    if (iFrameReloaded) {
      showGenericErrorPage() // eslint-disable-line no-undef
    } else {
      window.parent.postMessage({ eventType: "refresh_renewal" }, "*")
    }
    return
  }

  next()
}
