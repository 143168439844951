import { reactive } from "vue"
import { watchBreakpointChanges, getDefaultBreakpoints } from "./utils"
/**
 * Plugin allowing to observe active breakpoints from Vue HTML and JS templates
 * Usage:
 * - Install plugin:
 *  Vue.use(BreakpointsPlugin, { lg: '1200px' })
 * - Check breakpoint state:
 *  v-if="$breakpoints.lg"
 */
export default {
  /**
   * Creates plugin instance and injects it into Vue prototype
   * @param {object} breakpoints - Map of breakpoint names and sizes
   */
  install(app, breakpoints = getDefaultBreakpoints()) {
    const breakpointStates = reactive({})
    watchBreakpointChanges(breakpoints, (name, matches) => {
      breakpointStates[name] = matches
    })
    app.config.globalProperties.$breakpoints = breakpointStates
  }
}
