import dayjs from "dayjs"
import ErrorReporter from "@soenergy/frontend-library/src/services/ErrorReporter"
import { getCotMoveOut } from "@/methods/cotMoveOutHelper"
export const namespaced = true

const getDefaultState = () => {
  return {
    readSubmissionStepHidden: false,
    moveOutRecord: null,
    moveOutRecordFetched: false,
    form: {
      lastDateOfResponsibility: null,
      currentTenant: null,
      futureTenant: { type: null, fullName: null, email: null, phone: null },
      forwardingAddress: null,
    },
  }
}

export const state = getDefaultState()
export const getters = {
  allMetersReceivedReadingsInCotFlow: (state, getters, _, rootGetters) => {
    const meters = rootGetters["meters/allCurrent"]
    if (!meters) return

    return Object.values(meters).every(([{ endpointID, meterUID }]) => {
      const hasAcceptedReading = rootGetters.readings[endpointID]?.find(
        (read) =>
          read.receivedDttm &&
          state.moveOutRecord.createdDate.isSame(
            dayjs(read.receivedDttm),
            "day"
          )
      )
      const hasPendingReading =
        rootGetters[
          "pendingReadingSubmissions/metersWithTodayReading"
        ].includes(meterUID)
      return hasAcceptedReading || hasPendingReading
    })
  },
  allMetersReceivedReadingsForLDOR: (state, getters, _, rootGetters) => {
    const meters = rootGetters["meters/allCurrent"]
    if (!meters) return

    const lastDateOfResponsibility =
      state.form.lastDateOfResponsibility ||
      state.moveOutRecord.lastDateOfResponsibility

    return Object.values(meters).every(([{ endpointID }]) =>
      rootGetters.readings[endpointID]?.find(
        (read) =>
          read.readingDttm &&
          lastDateOfResponsibility.isSame(dayjs(read.readingDttm), "day")
      )
    )
  },
}
export const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  UPDATE_FORM(state, form) {
    state.form = { ...state.form, ...form }
  },
  UPDATE_READ_SUBMISSION_STEP_HIDDEN(state, value) {
    state.readSubmissionStepHidden = value
  },
  UPDATE_MOVE_OUT_RECORD(state, value) {
    state.moveOutRecord = value
  },
  UPDATE_MOVE_OUT_RECORD_FETCHED(state, value) {
    state.moveOutRecordFetched = value
  },
}

export const actions = {
  disableReadSubmissionStepIfHasReadsForLDOR({ commit, state, getters }) {
    const hasReadsForLDOR = state.form.lastDateOfResponsibility
      ? getters.allMetersReceivedReadingsForLDOR
      : false
    commit("UPDATE_READ_SUBMISSION_STEP_HIDDEN", hasReadsForLDOR)
  },
  async ensureCotMoveOutRecordFetched({ commit, state, dispatch, rootState }) {
    if (state.moveOutRecordFetched) return
    try {
      const record = await getCotMoveOut(rootState.currentAccount)
      if (record) {
        commit("UPDATE_MOVE_OUT_RECORD", record)
        commit("UPDATE_MOVE_OUT_RECORD_FETCHED", true)
      }
    } catch (error) {
      dispatch(
        "modals/showGenericModal",
        {
          message:
            "Sorry, an unexpected error occurred while trying to fetch move out details. Please check back later.",
        },
        { root: true }
      )
      ErrorReporter.report(error)
      throw error
    }
  },
}
