import dayjs, { Dayjs } from "dayjs"
import { CotAddressRaw, CotAddress } from "./CotAddress"
import { Fuel } from "@soenergy/frontend-library/src/services/Quote/QuoteFormConstants"
import TariffQuote from "@soenergy/frontend-library/src/models/TariffQuote"

interface FuelUsage {
  usage?: string
  period: "year" | "month"
}

export type FuelType = (typeof Fuel)[keyof typeof Fuel]

export const CotMoveWithUsStatus = {
  PENDING: "PENDING",
  FAILED_IN_JUNIFER: "FAILED_IN_JUNIFER",
  REGISTERED_IN_JUNIFER: "REGISTERED_IN_JUNIFER",
  FAILED_TO_CREATE_CORE_ACCOUNTS: "FAILED_TO_CREATE_CORE_ACCOUNTS",
  ENROLMENT_COMPLETED: "ENROLMENT_COMPLETED",
} as const
export type CotMoveWithUsStatusType =
  (typeof CotMoveWithUsStatus)[keyof typeof CotMoveWithUsStatus]

export interface DirectDebitDetailsRaw {
  use_existing_direct_debit: boolean
  bank_account_name?: string
  bankAccountNumber?: string
  bankAccountSortCode?: string
  payment_day_of_month?: number
  payment_amount: number
  seasonalPayment?: boolean
}

export interface ConsumptionEstimationRequest {
  consumption: number
  estimatedCost?: number
  period: string
  e7DaytimeConsumptionProportion?: number
}

export interface ProductRequest {
  code: string
  meterPointIdentifiers: string[]
  type: string
  consumptionEstimation?: ConsumptionEstimationRequest
}

export interface CotMoveWithUsForm {
  postcode: string
  supplyAddress: CotAddress
  supplyAddressGas?: CotAddress
  mprn?: string
  mpan?: string
  fuel: FuelType
  estimatedUsage: string
  exactAmountElec?: FuelUsage
  exactAmountGas?: FuelUsage
  moveInDate: Dayjs
  seasonalPayments: boolean
  useExistingDirectDebit: boolean
  hasAcceptedTerms: boolean
  isAuthorisedToSetupDD: boolean
  billingAddress: CotAddress
  bankAccountHolderName?: string
  sortCode?: string
  bankAccountNumber?: string
  paymentDay: number
}

export interface CotMoveWithUsRequest {
  form: CotMoveWithUsForm
  quote: TariffQuote
}

export interface CotMoveWithUsPayload {
  supply_address: CotAddressRaw
  direct_debit_details: DirectDebitDetailsRaw
  products: ProductRequest[]
  move_in_date: string
}

interface CotMoveWithUsRaw {
  status: CotMoveWithUsStatusType
  moveInDate: string
  enrolmentId: number
  errorMessage: string
  newSupplyAddress: CotAddressRaw
}

export class CotMoveWithUs {
  moveInDate: Dayjs
  enrolmentId: number
  status: CotMoveWithUsStatusType
  errorMessage: string
  address: CotAddress

  constructor(rawCotMoveWithUs: CotMoveWithUsRaw) {
    this.status = rawCotMoveWithUs.status
    this.moveInDate = dayjs(rawCotMoveWithUs.moveInDate)
    this.enrolmentId = rawCotMoveWithUs.enrolmentId
    this.errorMessage = rawCotMoveWithUs.errorMessage
    this.address = new CotAddress(rawCotMoveWithUs.newSupplyAddress)
  }

  get mwuCompleted() {
    return [
      CotMoveWithUsStatus.ENROLMENT_COMPLETED,
      CotMoveWithUsStatus.REGISTERED_IN_JUNIFER,
      CotMoveWithUsStatus.FAILED_TO_CREATE_CORE_ACCOUNTS,
    ].some((s) => s === this.status)
  }

  get canCancel() {
    return [
      CotMoveWithUsStatus.FAILED_IN_JUNIFER,
      CotMoveWithUsStatus.PENDING,
    ].some((s) => s === this.status)
  }
}
