<template>
  <GenericImageLayout
    v-if="pageContent"
    v-editable="pageContent"
    :image-src="newBranding ? pageContent?.image?.filename : imageSrc"
  >
    <div :class="['login section']">
      <h2 class="auth-modal__title">{{ pageContent.heading }}</h2>
      <FeedbackDialog
        v-if="$route.query.account_activated"
        ref="account-activated-message"
        class="mb-5"
        message="Your account has been activated - you can now log in below"
        variant="positive"
      />
      <FeedbackDialog
        v-if="showEmailChangeMessage"
        ref="email-change-message"
        class="mb-5"
        :message="pageContent.emailChangeCopy"
        variant="positive"
      />
      <div class="login__form">
        <form novalidate @submit.prevent="login">
          <InputField
            autofocus
            name="email"
            label="Email"
            type="email"
            autocomplete="email"
            :value="emailField.value"
            :has-error="v$.emailField.value.$fire || submitStatus === 'ERROR'"
            :error-message="getErrorMessage('emailField')"
            required
            @blur="
              emailField.value !== null ? v$.emailField.value.$touch() : null
            "
            @input="(value) => (emailField.value = value)"
          />

          <PasswordInput
            name="password"
            label="Password"
            :value="passwordField.value"
            :has-error="
              v$.passwordField.value.$fire || submitStatus === 'ERROR'
            "
            :error-message="getErrorMessage('passwordField')"
            required
            @input="(value) => (passwordField.value = value)"
            @blur="
              passwordField.value !== null
                ? v$.passwordField.value.$touch()
                : null
            "
          />
          <FeedbackDialog
            v-if="isAccountLocked"
            variant="negative"
            class="mb-2"
          >
            <Article
              ref="activeAccountText"
              :no-margin="true"
              :text-content="pageContent.lockedAccountText"
            />
          </FeedbackDialog>

          <div class="login__form-group login__group-flex">
            <Checkbox
              v-model="longLivedRefreshToken"
              :input-id="'remember-me'"
            />
            <label for="remember-me">Remember me</label>

            <router-link
              class="login__forgot-password"
              :to="{
                name: 'password-reset',
                query: { user_email: emailField.value || undefined },
              }"
            >
              Forgot password?
            </router-link>
          </div>

          <AppButton
            class="form-group__button"
            type="submit"
            :loading="submitStatus === 'PENDING'"
            v-bind="cmsBlockToProps(pageContent.loginButton)"
          />
        </form>

        <Article
          ref="activeAccountText"
          :text-content="pageContent.activeAccountText"
          class="login__active-text"
          no-margin
        />

        <AppButton
          v-if="pageContent.switchButton.length"
          v-bind="cmsBlockToProps(pageContent.switchButton)"
          class="login__switch-button"
        />
      </div>
    </div>
  </GenericImageLayout>
</template>

<script>
import { mapState } from "vuex"
import useVuelidate from "@vuelidate/core"
import { required, helpers } from "@vuelidate/validators"
import email from "@/validators/email"
import EnergyVote from "@/services/EnergyVote.js"
import GenericImageLayout from "@soenergy/frontend-library/src/components/GenericImageLayout"
import AppButton from "@soenergy/frontend-library/src/components/AppButton"
import InputField from "@soenergy/frontend-library/src/components/InputField"
import Checkbox from "@soenergy/frontend-library/src/components/Checkbox"
import PasswordInput from "@/components/PasswordInput.vue"
import Article from "@soenergy/frontend-library/src/components/Article"
import FeedbackDialog from "@soenergy/frontend-library/src/components/FeedbackDialog"

import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import loginContent from "soenergy-cms-loader!?path=account/login"

const fields = {
  emailField: {
    errorMessage: "",
    messages: {
      empty: "Please enter an email address",
      format: "Please enter a valid email address",
    },
    value: null,
  },
  passwordField: {
    type: "password",
    name: "password",
    errorMessage: "",
    messages: {
      empty: "Please enter a password",
      format: "Please enter a valid password",
    },
    value: null,
  },
}

export default {
  components: {
    GenericImageLayout,
    AppButton,
    InputField,
    Checkbox,
    PasswordInput,
    Article,
    FeedbackDialog,
  },
  mixins: [cmsPreviewMixin({ story: loginContent })],
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      newBranding: process.env.VUE_APP_NEW_BRANDING === "true",
      imageSrc: require("../assets/images/couple-tablet-laughing-halftone-web.jpg"),
      submitStatus: null,
      ...fields,
      longLivedRefreshToken: false,
      isAccountLocked: false,
    }
  },
  validations: {
    emailField: {
      value: {
        required: helpers.withMessage(
          fields.emailField.messages.empty,
          required
        ),
        email: helpers.withMessage(fields.emailField.messages.format, email),
      },
    },
    passwordField: {
      value: {
        required: helpers.withMessage(
          fields.passwordField.messages.empty,
          required
        ),
      },
    },
  },
  computed: {
    ...mapState({
      loginRedirectPath: (state) => state.loginRedirectPath,
    }),
    voteKey() {
      return this.$route.query.vote
    },
    showEmailChangeMessage() {
      return (
        this.loginRedirectPath &&
        this.loginRedirectPath.includes("confirm-email-change")
      )
    },
  },
  methods: {
    login() {
      this.emailField.errorMessage = ""
      this.passwordField.errorMessage = ""
      this.submitStatus = null
      this.isAccountLocked = false
      this.v$.$touch()

      if (!this.v$.$invalid) {
        this.submitStatus = "PENDING"
        this.$store
          .dispatch("login", {
            email: this.emailField.value,
            password: this.passwordField.value,
            short_lived_refresh_token: String(!this.longLivedRefreshToken),
          })
          .then(() => {
            if (this.voteKey) {
              this.handleEnergyVote(this.voteKey)
            } else if (this.$router) {
              this.$router.push({ name: "account-home" }).catch(() => {})
            }
          })
          .catch((err) => {
            this.submitStatus = "ERROR"
            this.emailField.errorMessage = ""
            this.passwordField.errorMessage = ""

            if (err.response && err.response.status == 401) {
              if (
                err.response.data &&
                err.response.data.error === "account locked"
              ) {
                this.isAccountLocked = true
              } else {
                this.passwordField.errorMessage =
                  "Email or password is incorrect. Please try again."
              }
            } else if (err.message == "Network Error") {
              this.passwordField.errorMessage =
                "Cannot reach the So Energy server"
            } else {
              this.passwordField.errorMessage = "Unknown error!"
              throw err
            }
          })
      } else {
        this.emailField.errorMessage =
          this.v$.emailField.$errors?.[0]?.$message ?? ""
        this.passwordField.errorMessage =
          this.v$.passwordField.$errors?.[0]?.$message ?? ""
      }
    },
    getErrorMessage(field) {
      if (this[field].errorMessage) {
        return this[field].errorMessage
      }
      if (!this.v$[field].value.required && this.v$[field].value.$fire) {
        return this[field].messages.empty
      }
      if (!this.v$[field].value.email && this.v$[field].value.$fire) {
        return this[field].messages.format
      }
      return null
    },
    async handleEnergyVote(value) {
      await EnergyVote.sendVote(value)
      this.$router.push("/personal-details").catch(() => {})
    },
  },
}
</script>

<style scoped lang="scss">
.login {
  margin: auto;
  padding-top: $spacing-8;

  @include lg {
    margin: unset;
    padding-top: $spacing-9;
  }
  &__form {
    max-width: 408px;
  }
  &__form-group {
    margin-bottom: $spacing-4;

    @include lg {
      margin-bottom: $spacing-5;
    }

    label {
      font-size: $size-7;
      line-height: 1.8;
    }
  }
  &__group-flex {
    display: flex;

    .login__forgot-password {
      margin-left: auto;
    }
  }
  &__forgot-password {
    font-size: $size-7;
    line-height: 1.8;
  }
  &__active-text {
    margin-top: $spacing-4;
    font-size: $size-7;

    @include lg {
      margin-top: $spacing-5;
    }
  }

  &__switch-button {
    margin-top: $spacing-6;
  }

  &__show-password {
    float: right;
    font-size: $size-6;
    margin-bottom: $spacing-2;

    &__input {
      opacity: 0;
      position: absolute;
      z-index: -1;

      &:focus {
        outline: 0;

        + label {
          box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
        }
      }
    }

    &__label {
      line-height: 1.5;
      cursor: pointer;
      padding: 2px;
      user-select: none;
      font-size: $size-6;
    }

    &__icon {
      color: $night-light;
      width: 24px;
      text-align: center;
      font-weight: $weight-bold;

      &:hover {
        color: darken($night-light, 8%);
      }

      &--active {
        color: $water;

        &:hover {
          color: darken($water, 8%);
        }
      }
    }
  }
}
</style>
