import dayjs from "dayjs"

const DayJSSerializer = {
  typeTag: "dayjs",
  isMatchingSerializer: obj => dayjs.isDayjs(obj),
  toJSON: obj => obj.toJSON(),
  fromJSON: value => dayjs(value)
}

export default DayJSSerializer
