import { ITariffRaw } from "@/models/Tariff"

export interface ITariffEndpointResponse {
  code: string
  display: string
  dual_fuel_discount: number
  variable: boolean
  gsp_group: string
  duration_months: number
  start_date: string
  future_sooc_tariff: boolean
  future_sooc_pricing_date: string
  recommend_for_renewal: boolean
  day_rate: number
  night_rate: number
  standard_rate: number
  standing_charge: number
  early_termination_charge: number
}

export interface IProductEndpointResponse {
  code: string
  display: string
  dualFuelDiscount: number
  variable: boolean
  gspGroup: string
  durationMonths: number
  unitRates: IProductUnitRates
  unitRate?: number
  standingCharge: number
  earlyTerminationCharge: number
}

interface IProductUnitRates {
  Day: number
  Night: number
  Standard: number
}

export const parseTariffEndpointResponseObject = (
  rawQuote: ITariffEndpointResponse
): ITariffRaw => {
  return {
    code: rawQuote.code,
    display: rawQuote.display,
    dualFuelDiscount: rawQuote.dual_fuel_discount,
    variable: rawQuote.variable,
    ukGspGroup: rawQuote.gsp_group,
    durationMonths: rawQuote.duration_months,
    startDate: rawQuote.start_date,
    isFutureSoocTariff: rawQuote.future_sooc_tariff,
    futureSoocPricingDate: rawQuote.future_sooc_pricing_date,
    recommended: rawQuote.recommend_for_renewal,
    dayUnitRate: rawQuote.day_rate,
    nightUnitRate: rawQuote.night_rate,
    standardUnitRate: rawQuote.standard_rate,
    standingCharge: rawQuote.standing_charge,
    earlyTerminationCharge: rawQuote.early_termination_charge,
  }
}

export const parseProductEndpointResponseObject = (
  rawQuote: IProductEndpointResponse
): ITariffRaw => {
  return {
    code: rawQuote.code,
    display: rawQuote.display,
    dualFuelDiscount: rawQuote.dualFuelDiscount,
    variable: rawQuote.variable,
    ukGspGroup: rawQuote.gspGroup,
    durationMonths: rawQuote.durationMonths,
    startDate: "",
    isFutureSoocTariff: false,
    futureSoocPricingDate: "",
    recommended: false,
    dayUnitRate: rawQuote.unitRates?.Day ?? 0,
    nightUnitRate: rawQuote.unitRates?.Night ?? 0,
    standardUnitRate: rawQuote.unitRate ?? rawQuote.unitRates?.Standard ?? 0,
    standingCharge: rawQuote.standingCharge,
    earlyTerminationCharge: rawQuote.earlyTerminationCharge,
  }
}
