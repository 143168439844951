import {
  getCotMoveWithUs,
  cancelCotMoveWithUs,
} from "@/methods/cotMoveWithUsHelper"
import TariffQuoteParser from "@soenergy/frontend-library/src/services/Quote/TariffQuoteParser"

export const namespaced = true

const getDefaultState = () => {
  return {
    tariffQuotes: null,
    isFetchingQuotes: null,
    tariffFetchError: null,
    referralDetails: null,
    selectedQuote: null,
    isContentFetched: false,
    furthestCompletedStep: 0,
    moveWithUsRecordFetched: false,
    moveWithUsRecord: null,
    invalidQuoteTariffName: null,
    form: {
      postcode: null,
      fuel: null,
      meterType: null,
      isMeterMultirate: null,
      supplyAddress: null,
      supplyAddressGas: null,
      estimatedUsage: null,
      exactAmountGas: { usage: null, period: "year" },
      exactAmountElec: { usage: null, period: "year" },
      seasonalPayments: null,
      bankAccountHolderName: null,
      bankAccountNumber: null,
      sortCode: null,
      paymentDay: null,
      hasAcceptedTerms: null,
      isAuthorisedToSetupDD: null,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      dateOfBirth: null,
      priorityServiceRegister: null,
      marketingOptIn: null,
      useExistingDirectDebit: null,
      paymentAmount: null,
      moveInDate: null,
    },
  }
}

export const state = getDefaultState()

export const getters = {}

export const mutations = {
  UPDATE_FORM(state, form) {
    state.form = { ...state.form, ...form }
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  RESET_FORM(state) {
    Object.assign(state, getDefaultState())
  },
  SET_TARIFF_QUOTES(state, tariffQuotes) {
    state.tariffQuotes = tariffQuotes
  },
  SET_TARIFF_FETCH_ERROR(state, error) {
    state.tariffFetchError = error
  },
  SET_TARIFF_FETCH_STATE(state, isFetching) {
    state.isFetchingQuotes = isFetching
  },
  UPDATE_MPAN(state, mpan) {
    state.form.supplyAddress.mpan = mpan
  },
  UPDATE_MPRN(state, mprn) {
    state.form.supplyAddressGas.mprn = mprn
  },
  UPDATE_FURTHEST_STEP_COMPLETED(state, furthestCompletedStep) {
    state.furthestCompletedStep = furthestCompletedStep
  },
  SET_QUOTE(state, quote) {
    state.selectedQuote = quote
  },
  UPDATE_MOVE_WITH_US_RECORD(state, value) {
    state.moveWithUsRecord = value
  },
  UPDATE_MOVE_WITH_US_RECORD_FETCHED(state, value) {
    state.moveWithUsRecordFetched = value
  },
  SET_INVALID_TARIFF(state, invalidQuoteTariffName) {
    state.invalidQuoteTariffName = invalidQuoteTariffName
  },
}

export const actions = {
  async fetchTariffQuotes({ state, commit }) {
    commit("SET_TARIFF_FETCH_STATE", true)
    commit("SET_TARIFF_FETCH_ERROR", null)
    try {
      const { quotes, invalidQuoteTariffName } =
        await TariffQuoteParser.getQuotes(state.form)
      commit("SET_INVALID_TARIFF", invalidQuoteTariffName)
      commit("SET_TARIFF_QUOTES", quotes)
    } catch (error) {
      commit("SET_TARIFF_FETCH_ERROR", error)
      throw error
    } finally {
      commit("SET_TARIFF_FETCH_STATE", false)
    }
  },
  async ensureCotMoveWithUsRecordFetched({ commit, state, dispatch }) {
    if (state.moveWithUsRecordFetched) return
    try {
      const record = await getCotMoveWithUs()
      if (record) {
        commit("UPDATE_MOVE_WITH_US_RECORD", record)
        commit("UPDATE_MOVE_WITH_US_RECORD_FETCHED", true)
      }
    } catch (error) {
      dispatch(
        "modals/showGenericModal",
        {
          message:
            "Sorry, an unexpected error occurred while trying to fetch you move details. Please check back later.",
        },
        { root: true }
      )
      throw error
    }
  },
  async cancelMoveWithUs({ commit, dispatch }) {
    try {
      await cancelCotMoveWithUs()
      commit("UPDATE_MOVE_WITH_US_RECORD", null)
      commit("UPDATE_MOVE_WITH_US_RECORD_FETCHED", false)
    } catch (error) {
      dispatch(
        "modals/showGenericModal",
        {
          message:
            "Sorry, an unexpected error occurred while trying to cancel your switch. Please try again later.",
        },
        { root: true }
      )
      throw error
    }
  },
}
