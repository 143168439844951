export interface CotAddressRaw {
  address_line_1: string
  address_line_2: string
  address_line_3: string
  postcode: string
  country_code: string
  mpan?: string
  mprn?: string
  careOf?: string
}

export class CotAddress {
  postcode: string
  addressLine1: string
  addressLine2: string
  addressLine3: string
  mpan?: string
  mprn?: string

  constructor(rawCotMoveWithUsAddress: CotAddressRaw) {
    this.postcode = rawCotMoveWithUsAddress.postcode
    this.addressLine1 = rawCotMoveWithUsAddress.address_line_1
    this.addressLine2 = rawCotMoveWithUsAddress.address_line_2
    this.addressLine3 = rawCotMoveWithUsAddress.address_line_3
    this.mpan = rawCotMoveWithUsAddress.mpan
    this.mprn = rawCotMoveWithUsAddress.mprn
  }
}
