import { getDefaultState } from "."

export default {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  SET_DIRECT_DEBIT_MANDATE(state, directDebitMandate) {
    state.directDebitMandate = directDebitMandate
  },
  SET_PAYMENT_SUMMARY(state, paymentSummary) {
    state.paymentSummary = paymentSummary
  },
  SET_NEW_PAYMENT_SCHEDULE(state, newPaymentSchedule) {
    state.newPaymentSchedule = newPaymentSchedule
  },
  SET_SUGGESTED_PAYMENT(state, suggestedPayment) {
    state.suggestedPayment = suggestedPayment
  },
  SET_MAXIMUM_PAYMENT_AMOUNT(state, maximumPaymentAmount) {
    state.maximumPaymentAmount = maximumPaymentAmount
  },
  SET_MINIMUM_PAYMENT_AMOUNT(state, minimumPaymentAmount) {
    state.minimumPaymentAmount = minimumPaymentAmount
  },
  SET_USAGE_PROFILE(state, usageProfile) {
    state.usageProfile = usageProfile
  },
  SET_SHOW_BALANCE_PREDICTION(state, showBalancePrediction) {
    state.showBalancePrediction = showBalancePrediction
  },
}
