import GatewayPortal from "@/services/GatewayPortal"
import RailsService from "@/services/RailsService"
import ErrorReporter from "@soenergy/frontend-library/src/services/ErrorReporter"
import dayjs from "dayjs"
export const namespaced = true

export const state = {
  eligibility: null,
  smbpCampaignStatus: null,
  hasAvailableSmbpSlots: false,
  smbpAvailableSlots: null,
  bookings: null,
  bookingStatus: {
    loading: false,
    error: false,
  },
}

export const mutations = {
  SET_CAMPAIGN_STATUS(state, campaignStatus) {
    state.smbpCampaignStatus = campaignStatus
  },
  SET_SMART_METER_ELIGIBILITY(state, eligibility) {
    state.eligibility = eligibility
  },
  SET_AVAILABLE_SLOTS(state, slots) {
    state.hasAvailableSmbpSlots = slots
  },
  SET_SMBP_BOOKING_SLOTS(state, slots) {
    state.smbpAvailableSlots = slots
  },
  SET_BOOKINGS(state, bookingDetails) {
    state.bookings = bookingDetails
  },
}

export const actions = {
  async fetchAvailableSlots({ commit, rootGetters, getters }) {
    if (getters.isEligibleForSmartMeter && rootGetters.validatedPostcode) {
      await GatewayPortal.getSmbpAvailableSlots(rootGetters.validatedPostcode)
        .then((response) => {
          if (response?.data?.days?.length) {
            const filteredSlots = response.data.days.filter(
              (day) => day.slots.length
            )
            if (filteredSlots.length) {
              commit("SET_AVAILABLE_SLOTS", true)
              commit("SET_SMBP_BOOKING_SLOTS", filteredSlots)
            }
          }
        })
        .catch((err) => {
          if (err.response && err.response.status !== 404) {
            ErrorReporter.report(err)
          }
        })
    }
  },
  async fetchBookings({ commit, rootState }) {
    try {
      const fetchedBookings = await GatewayPortal.getBookings(
        rootState.currentAccount
      )
      commit("SET_BOOKINGS", fetchedBookings.data.bookings)
    } catch (err) {
      ErrorReporter.report(err)
    }
  },
  async fetchSmartMeterEligibility({ commit }, billingAccountId) {
    await RailsService.getSmartMeterEligibility(billingAccountId)
      .then((res) => {
        if (res.data?.reason) {
          commit("SET_SMART_METER_ELIGIBILITY", res.data.reason)
          return
        }
        throw Error("Eligibility: No reason returned")
      })
      .catch((err) => ErrorReporter.report(err))
  },
}

export const getters = {
  isEligibleForSmartMeter(state, getters, rootState, rootGetters) {
    const eligibleValues = [
      "ELIGIBLE_ELEC_EXCHANGE",
      "ELIGIBLE_GAS_EXCHANGE",
      "ELIGIBLE_DUAL_EXCHANGE",
    ]
    return (
      (rootGetters["agreements/anyAgreementIsOutOfContract"] ||
        rootGetters["agreements/hasActiveAgreements"]) &&
      !rootState.userStatus.userStatus.smbpData?.booked &&
      eligibleValues.includes(state.eligibility)
    )
  },
  isSmbpPopupShown(state, getters, rootState, rootGetters) {
    return (
      rootState.isSmbpPopupEnabled &&
      getters.isEligibleForSmartMeter &&
      state.hasAvailableSmbpSlots &&
      rootGetters["meters/allMetersReceivedReadingsToday"]
    )
  },
  latestBooking(state) {
    if (!state.bookings.length) return null
    const filteredBookings = state.bookings.filter(
      (booking) => booking["created-at"]
    )
    if (!filteredBookings.length) return null
    return filteredBookings.reduce((latestBooking, currentBooking) => {
      return dayjs(currentBooking["created-at"]).isAfter(
        dayjs(latestBooking["created-at"])
      )
        ? currentBooking
        : latestBooking
    })
  },
  gasMeter(state, getters, rootState, rootGetters) {
    return Object.values(rootGetters["meters/allCurrentGas"])
      .flat()
      .find((meter) => meter.meterpointIdentifier && meter.meterIdentifier)
  },
  electricMeter(state, getters, rootState, rootGetters) {
    return Object.values(rootGetters["meters/allCurrentElectricity"])
      .flat()
      .find((meter) => meter.meterpointIdentifier && meter.meterIdentifier)
  },
  electricSerialNumber(state, getters) {
    return getters.electricMeter?.meterIdentifier || ""
  },
  gasSerialNumber(state, getters) {
    return getters.gasMeter?.meterIdentifier || ""
  },
  mpan(state, getters) {
    return getters.electricMeter?.meterpointIdentifier || ""
  },
  mprn(state, getters) {
    return getters.gasMeter?.meterpointIdentifier || ""
  },
}
