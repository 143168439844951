import ErrorReporter from "@soenergy/frontend-library/src/services/ErrorReporter"
import Rails from "../../services/RailsService.js"
import dayjs from "dayjs"
import isBetween from "dayjs/plugin/isBetween"

dayjs.extend(isBetween)

export const namespaced = true

const getDefaultState = () => {
  return {
    bills: null,
    latestBillLine: null,
  }
}

export const state = getDefaultState()

export const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  SET_BILLS(state, bills) {
    state.bills = bills
  },
  SET_LATEST_BILL_LINE(state, billLine) {
    state.latestBillLine = billLine
  },
}

export const actions = {
  fetchBills({ getters, commit, dispatch }, accountId) {
    if (!getters.bills) {
      return Rails.fetchBills(accountId)
        .then((response) => {
          const bills = response.data && response.data.bills

          if (bills) {
            commit("SET_BILLS", bills)
          }
        })
        .catch((error) => {
          dispatch(
            "modals/showGenericModal",
            {
              message: `
              Sorry, an error occurred while loading your account details.
              The data shown might be out of sync.
            `,
            },
            { root: true }
          )
          ErrorReporter.report(error)
        })
    }
  },
  fetchLatestBillLine({ commit, getters, dispatch }) {
    const billId = getters.latestBillId
    if (billId) {
      return Rails.fetchBillLine(billId)
        .then((response) => {
          const latestBillLine = response.data && response.data.bill_lines

          if (latestBillLine) {
            commit("SET_LATEST_BILL_LINE", latestBillLine)
          }
        })
        .catch((error) => {
          dispatch(
            "modals/showGenericModal",
            {
              message: `
              Sorry, an error occurred while getting your latest bill.
              Please try again later.
            `,
            },
            { root: true }
          )
          ErrorReporter.report(error)
        })
    }
  },
}

export const getters = {
  bills(state) {
    return state.bills
  },
  latestBill(state, getters) {
    const bill = getters.billsSortedByDateDesc
    const lines = getters.latestBillLine

    if (bill.length && lines && lines.length) {
      const paymentsForBill = getters.paymentsForBill(bill)

      return {
        bill,
        summary: summariseBill(lines, paymentsForBill),
      }
    }

    return {}
  },
  latestBillCreatedDate(state, getters) {
    return getters.latestBill?.bill && getters.latestBill?.bill[0].createdDttm
  },
  latestBillLine(state) {
    return state.latestBillLine
  },
  paymentsForBill(state, getters, rootState, rootGetters) {
    return (bill) => {
      const from = dayjs(bill.periodFrom),
        to = dayjs(bill.periodTo)
      return rootGetters.payments.filter((payment) =>
        dayjs(payment.acceptedDttm).isBetween(from, to, null, "[]")
      )
    }
  },
  billsKeyedByCreatedDttm(state, getters) {
    return getters.bills && getters.bills.length
      ? getters.bills.reduce((billsByCreatedDttm, bill) => {
          billsByCreatedDttm[bill.createdDttm] = bill
          return billsByCreatedDttm
        }, {})
      : {}
  },
  billsKeyedByBillId(state, getters) {
    return getters.bills && getters.bills.length
      ? getters.bills.reduce((billsKeyedByBillId, bill) => {
          billsKeyedByBillId[bill.id] = bill
          return billsKeyedByBillId
        }, {})
      : {}
  },
  billsSortedByDateDesc(state, getters) {
    return getters.bills && getters.bills.length
      ? getters.bills.sort(billsDecending)
      : []
  },
  latestBillId(state, getters) {
    return (
      getters.billsSortedByDateDesc &&
      getters.billsSortedByDateDesc[0] &&
      getters.billsSortedByDateDesc[0].id
    )
  },
}
// Private functions
const billsDecending = (bill1, bill2) => {
  const b1 = dayjs(bill1.periodTo)
  const b2 = dayjs(bill2.periodTo)

  if (b1.isBefore(b2)) return 1
  if (b1.isAfter(b2)) return -1
  return 0
}
const summariseBill = (lines, payments) => {
  return lines.reduce(
    (summary, line) => {
      switch (line.type) {
        case "reduced_vat":
          summary.VAT += parseFloat(line.billCurrencyAmount)
          break
        case "gas_charge":
          summary.gasCharges += parseFloat(line.billCurrencyAmount)
          break
        case "electricity_charge":
          summary.electricityCharges += parseFloat(line.billCurrencyAmount)
          break
        case "standing_charge":
          if (line.code.includes("Gas Supply")) {
            summary.gasCharges += parseFloat(line.billCurrencyAmount)
          } else {
            summary.electricityCharges += parseFloat(line.billCurrencyAmount)
          }
          break
        default:
          summary.other.push(line)
      }
      return summary
    },
    {
      gasCharges: 0,
      electricityCharges: 0,
      VAT: 0,
      payments: payments.reduce(
        (total, payment) => (total += payment.amount),
        0
      ),
      other: [],
    }
  )
}
