import dayjs from "dayjs"
import isToday from "dayjs/plugin/isToday"
dayjs.extend(isToday)
import { localStore } from "@soenergy/frontend-library/src/services/StorageFactory"
import wait from "@soenergy/frontend-library/src/helpers/wait"

export const namespaced = true

const getPersistedSubmissions = () =>
  JSON.parse(localStore.getItem("lastReadingSubmissions")) || {}
const persistSubmissions = (submissions) =>
  localStore.setItem("lastReadingSubmissions", JSON.stringify(submissions))

export const state = {
  lastReadingSubmissions: getPersistedSubmissions(),
}
export const getters = {
  metersWithTodayReading(state) {
    return Object.entries(state.lastReadingSubmissions)
      .filter(([_, submissionTime]) =>
        dayjs(submissionTime).isSame(dayjs(), "day")
      )
      .map(([meterUID]) => meterUID)
  },
}

export const mutations = {
  ADD_PENDING_SUBMISSION(state, meterUID) {
    state.lastReadingSubmissions[meterUID] = dayjs()
    persistSubmissions(state.lastReadingSubmissions)
  },
  DELETE_PENDING_SUBMISSION(state, meterUID) {
    delete state.lastReadingSubmissions[meterUID]
    persistSubmissions(state.lastReadingSubmissions)
  },
}
export const actions = {
  async processPendingSubmission({ state, commit }, meterUID) {
    const lastReadingSubmitted = state.lastReadingSubmissions[meterUID]
    const refreshJuniferAvailable = dayjs(lastReadingSubmitted).add(3, "minute")

    const remainingMs = Math.max(refreshJuniferAvailable.diff(dayjs()), 0)
    await wait(remainingMs)
    if (!dayjs(lastReadingSubmitted).isToday()) {
      commit("DELETE_PENDING_SUBMISSION", meterUID)
    }
  },
  async processPendingSubmissions(
    { dispatch, getters },
    { meterUID, meterFinishedProcessingCallback }
  ) {
    const hasUnprocessedReading =
      getters.metersWithTodayReading.includes(meterUID)
    if (hasUnprocessedReading) {
      await dispatch("processPendingSubmission", meterUID)
      meterFinishedProcessingCallback(meterUID)
    }
  },
}
