import ErrorReporter from "../services/ErrorReporter"
import GetQuote from "../services/Quote/GetQuote"

export default {
  async validateBankingDetails({ accountSortCode, accountNumber }) {
    try {
      await GetQuote.validateBankDetails({
        accountSortCode,
        accountNumber
      })
    } catch (payload) {
      const errors = payload.response?.data?.errors

      const isExpectedError = payload.response.status === 400
      if (!isExpectedError) {
        ErrorReporter.report(
          payload,
          errors ? { serverResponseData: errors } : {}
        )
      }
      if (errors) {
        // Display errors received from the server, with minor corrections
        // TODO: Remove once backend error handling is improved
        const extractMessage = (error, fieldName) => {
          let message = error && error.message && error.message.toLowerCase()
          if (message) {
            if (message.startsWith("is") || message.startsWith("does")) {
              message = `This ${fieldName} ${message}`
            }
            return message.replace(
              "sort code for this sort code",
              "the provided sort code"
            )
          }
        }

        const accountNumberError = errors.find(
          error => error.field === "bank_account_number"
        )
        const sortCodeError = errors.find(
          error => error.field === "account_sort_code"
        )
        const fieldErrors = {}

        fieldErrors.accountSortCode = extractMessage(sortCodeError, "sort code")
        fieldErrors.accountNumber = extractMessage(
          accountNumberError,
          "account number"
        )

        if (!accountNumberError && !sortCodeError) {
          fieldErrors.accountSortCode =
            "An error occurred while trying to verify your payment details, please ensure they are correct"
        }

        return {
          success: false,
          errors: { fields: fieldErrors }
        }
      }
      return {
        success: false,
        errors: {
          message: "Unexpected error occurred"
        }
      }
    }
    return { success: true }
  }
}
