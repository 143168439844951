import dayjs from "dayjs"
import {
  SEASONAL_ADJUSTMENT_SUMMER,
  SEASONAL_ADJUSTMENT_WINTER,
} from "@/constants"

export const getSeasonalAmounts = (date, amount, isSeasonal) => {
  const paymentMonth = dayjs(date).month()
  let seasonalAmounts = {
    equal: amount,
    summer: null,
    winter: null,
  }

  if (isSeasonal) {
    if (paymentMonth >= 3 && paymentMonth <= 8) {
      seasonalAmounts.equal = amount / SEASONAL_ADJUSTMENT_SUMMER
    } else {
      seasonalAmounts.equal = amount / SEASONAL_ADJUSTMENT_WINTER
    }
  }
  seasonalAmounts.summer = seasonalAmounts.equal * SEASONAL_ADJUSTMENT_SUMMER
  seasonalAmounts.winter = seasonalAmounts.equal * SEASONAL_ADJUSTMENT_WINTER

  return seasonalAmounts
}
