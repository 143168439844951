<template>
  <AppModal ref="app-modal" @close-modal="closeGenericModal">
    <template #body>{{ genericModalMessage }}</template>
    <template #cancelButton>Close</template>
  </AppModal>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import AppModal from "./AppModal.vue"

export default {
  name: "AppGenericModal",
  components: {
    AppModal,
  },
  computed: {
    ...mapGetters({
      genericModalMessage: "modals/genericModalMessage",
    }),
  },
  methods: {
    ...mapActions({
      closeGenericModal: "modals/closeGenericModal",
    }),
  },
}
</script>
