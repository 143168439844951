import dayjs from "dayjs"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
dayjs.extend(isSameOrAfter)

const GAS_OMR_PERIOD = 10
const ELEC_OMR_PERIOD = 5

// TODO: Refactor into a Meterpoint model

export const endOfFirstReadPeriodForFuel = (supplyStartDate, type) => {
  return dayjs(supplyStartDate).add(
    type === "electricity" ? ELEC_OMR_PERIOD : GAS_OMR_PERIOD,
    "day"
  )
}
export const inFirstReadPeriod = (supplyStartDate, meterpointType) => {
  if (meterpointType === "MPRN") {
    return inFirstReadPeriodGas(supplyStartDate)
  } else {
    return inFirstReadPeriodElec(supplyStartDate)
  }
}
export const inFirstReadPeriodElec = (supplyStartDate) => {
  const currentDate = dayjs()
  const start = dayjs(supplyStartDate).subtract(ELEC_OMR_PERIOD, "day")
  const end = endOfFirstReadPeriodForFuel(supplyStartDate, "electricity")

  return (
    currentDate.isAfter(start.startOf("day")) &&
    currentDate.isBefore(end.endOf("day"))
  )
}
export const inFirstReadPeriodGas = (supplyStartDate) => {
  const currentDate = dayjs()
  const start = dayjs(supplyStartDate)
  const end = endOfFirstReadPeriodForFuel(supplyStartDate, "gas")

  return (
    currentDate.isAfter(start.startOf("day")) &&
    currentDate.isBefore(end.endOf("day"))
  )
}

export const notReadyForSecondRead = (register, type) => {
  return (
    register.hasFirstRead &&
    endOfFirstReadPeriodForFuel(register.supplyStartDate, type).isAfter(dayjs())
  )
}

export const acceptingOpeningReadDate = (register, type) => {
  if (type === "electricity") {
    return dayjs(register.supplyStartDate).subtract(ELEC_OMR_PERIOD, "day")
  } else {
    return dayjs(register.supplyStartDate)
  }
}

export const notReadyForOpeningRead = (register, type) =>
  !acceptingOpeningReadDate(register, type).isBefore(dayjs())
