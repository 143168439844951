import store from "@/store/store.js"
import AuthenticationService from "@soenergy/frontend-library/src/services/AuthenticationService"

const applyExternalFormReadLogin = async (nextRoute, next) => {
  const isAdmin = store.state.authentication.email.endsWith("so.energy")
  if (isAdmin) {
    await AuthenticationService.logout(false)
    window.location.href = `${process.env.VUE_APP_ACCOUNT_SITE_URL}/admin/sign_in`
    next()
    return
  }

  store.dispatch("readLogin/setReadLogin", true)
  store.commit("userStatus/SET_USER_STATUS", { userSignedIn: true })
  store.commit("UPDATE_LOADING", false)
  if (nextRoute !== "read-login") {
    next("read-login")
  } else {
    next()
  }
}

export default async (to, from, next) => {
  const isRedirection = !!to.query.redirect
  const routeRequiresAuth = to.meta.requiresAuth
  const goesToReadLogin = to.name === "submit-read" || to.name === "read-login"
  const attemptReadLogin =
    !isRedirection && routeRequiresAuth && store.state.isReadLoginEnabled
  if (attemptReadLogin) {
    try {
      store.commit("UPDATE_LOADING", true)
      const isAuthenticated =
        await AuthenticationService.checkIfAuthenticatedAndFetchTokenIfNeeded()
      if (isAuthenticated) {
        if (store.state.isExternalFormReadLoginEnabled) {
          applyExternalFormReadLogin(to.name, next)
          return
        }
        await store.dispatch("readLogin/ensureReadLoginData")
        if (store.state.readLogin.eligibleForReadLogin) {
          store.commit(`userStatus/SET_USER_STATUS`, { userSignedIn: true })
        }
        if (!goesToReadLogin && store.state.readLogin.eligibleForReadLogin) {
          store.commit("UPDATE_LOADING", false)
          next("read-login")
          return
        }
      }
      store.commit("UPDATE_LOADING", false)
    } catch (error) {
      store.commit("UPDATE_LOADING", false)
      next("login-error")
      store.dispatch("logout")
      throw error
    }
  }

  if (goesToReadLogin && !store.state.readLogin.eligibleForReadLogin) {
    next("/")
    return
  }

  next()
}
