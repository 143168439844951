<template>
  <div
    :[ifAriaExpanded]="hasDropdown && showDropdown ? 'true' : 'false'"
    :[ifAriaHaspopup]="true"
    class="banner"
    :style="fullScreenStyle"
    :class="{ 'full-screen': isFullScreen, 'new-branding': newBranding }"
  >
    <div class="banner__text">
      <div :class="{ section: isFullScreen }">
        <div class="container">
          <button
            v-if="!hideCloseIcon"
            class="banner__close-icon"
            aria-label="close"
            :style="fullScreenStyle"
            @click="closeBanner"
          >
            <i class="icon icon-close"></i>
          </button>

          <div
            :class="[
              { banner__content: !isFullScreen },
              { 'banner__full-screen-content': isFullScreen }
            ]"
          >
            <slot name="content" />

            <template
              v-if="
                showCloseButton &&
                  Object.keys(closeButtonProps) &&
                  Object.keys(closeButtonProps).length
              "
            >
              <AppButton
                class="banner__close-button"
                aria-label="close"
                v-bind="cmsBlockToProps(closeButtonProps)"
                @click="closeBanner"
              />
            </template>
          </div>
        </div>
      </div>
      <slot />
      {{ heading }}
      <a
        v-if="hasDropdown"
        class="banner__link"
        href="#"
        @click.prevent="showDropdown = !showDropdown"
      >
        Find out more<i
          class="banner__icon icon"
          :class="showDropdown ? 'icon-chevron-up' : 'icon-chevron-down'"
        ></i>
      </a>
    </div>
    <div v-if="hasDropdown && showDropdown" class="banner-dropdown">
      <slot name="dropdown" />
      <Article
        v-if="dropdownContent"
        data-testid="dropdown-content"
        :text-content="dropdownContent"
        no-margin
        style-variant="light-blue-link"
      />
    </div>
  </div>
</template>

<script>
import Article from "./Article"
import AppButton from "./AppButton"

export default {
  components: {
    Article,
    AppButton
  },
  props: {
    heading: {
      type: String,
      default: null
    },
    dropdownContent: {
      type: Object,
      default: () => {}
    },
    fullScreenStyle: {
      type: Object,
      default: () => {}
    },
    isFullScreen: {
      type: Boolean,
      default: false
    },
    hideCloseIcon: {
      type: Boolean,
      default: true
    },
    showCloseButton: {
      type: Boolean,
      default: false
    },
    closeButtonProps: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      showDropdown: false,
      newBranding: process.env.VUE_APP_NEW_BRANDING === "true"
    }
  },
  computed: {
    hasDropdown() {
      return this.$slots.dropdown || this.dropdownContent
    },
    ifAriaExpanded() {
      return this.hasDropdown ? "aria-expanded" : null
    },
    ifAriaHaspopup() {
      return this.hasDropdown ? "aria-haspopup" : null
    }
  },
  methods: {
    cmsBlockToProps: props => props,
    closeBanner() {
      this.$emit("handleCloseBanner")
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  background-color: $night;
  border-bottom: 1px solid $night-light;
  color: $day;
  text-align: center;
  position: relative;
  z-index: zindex(banner);

  &__close-icon {
    position: absolute;
    right: 0;
    border: 0;
    width: 28px;
    height: 26px;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    font-size: $size-7;
    line-height: 20px;
    color: $day;

    @include lg {
      top: 4px;
      font-size: $size-6;
    }

    .icon {
      line-height: 26px;
      font-weight: $weight-bold;
    }
  }

  &__content {
    margin-right: $spacing-5;

    @include lg {
      margin-right: $size-5;
    }
  }

  &__text {
    padding: $spacing-3 $spacing-4;
    box-shadow: 0px 3px 6px 0px rgba($night, 0.2);
  }

  &__link {
    white-space: nowrap;
    color: $day;
  }

  &__icon {
    vertical-align: text-bottom;
    font-weight: bold;
    margin-left: $spacing-2;
  }
}

.banner-dropdown {
  background-color: $night;
  padding: $spacing-3 $spacing-4;
  box-shadow: 0px 3px 6px 0px rgba($night, 0.2);
  position: absolute;
  left: 0;
  right: 0;

  &__title {
    font-weight: $weight-medium;
  }
}

.banner.full-screen {
  position: fixed;
  left: 0;
  min-height: 100%;
  height: 100%;
  min-width: 100vw;
  text-align: left;
  z-index: zindex(fullscreenBanner);
  border-bottom: none;
  top: $spacing-8;

  @include lg {
    min-width: 100%;
  }

  @include xl {
    top: $spacing-9;
  }

  &.new-branding {
    top: 0;
    .banner__text {
      padding: 4rem 0;
    }
  }

  .banner__text {
    box-shadow: none;
    padding: 0;
  }

  .banner__full-screen-content {
    padding: 3.5rem 3.25rem $spacing-6 1.75rem;
    max-width: 830px;
    margin: auto;

    @include lg {
      padding: 5.5rem 0 3.5rem 0;
    }
  }

  .banner__close-icon {
    top: 0;
    font-size: $size-5;
  }
}
</style>
