import loadScriptFromUrl from "../helpers/loadScriptFromUrl"

export default {
  isScriptLoaded: false,
  isScriptLoading: false,
  async initWidget(userStatus) {
    if (
      (typeof process !== "undefined" && process.server) ||
      this.isScriptLoading
    ) {
      return
    }

    this.isScriptLoading = true
    await loadScriptFromUrl(`//eu.fw-cdn.com/10651841/360783.js`)
    window.fwcrm.on("widget:loaded", () => {
      if (window.fcWidget) {
        this.isScriptLoaded = true
        this.isScriptLoading = false
        // show widget if user is logged in, otherwise hide
        if (userStatus && userStatus.userSignedIn) {
          this.setUserForWidget(userStatus)
        }
      }
    })
  },
  activateWidget(userStatus) {
    this.setUserForWidget(userStatus)
    this.showWidget()
  },
  setUserForWidget(userStatus) {
    if (
      this.isScriptLoaded &&
      window.fcWidget &&
      userStatus &&
      userStatus.userSignedIn
    ) {
      window.fcWidget.user.setProperties({
        firstName: userStatus.firstName,
        lastName: userStatus.lastName,
        email: userStatus.email,
        cf_junifer_account: userStatus.juniferAccountNumber
      })
    }
  },
  showWidget() {
    if (typeof process !== "undefined" && process.server) return

    if (this.isScriptLoaded && window.fcWidget) {
      window.fcWidget.setConfig({
        headerProperty: {
          hideChatButton: false
        }
      })
    }
  },
  hideWidget() {
    if (typeof process !== "undefined" && process.server) return

    if (this.isScriptLoaded && window.fcWidget) {
      window.fcWidget.setConfig({
        headerProperty: {
          hideChatButton: true
        }
      })
    }
  }
}
