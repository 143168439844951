export const namespaced = true

const getDefaultState = () => {
  return {
    fullscreenBannerDismissed: false,
    daysToRenew: "soon!",
  }
}

export const state = getDefaultState()

export const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  SET_FULLSCREEN_BANNER_DISMISSED(state, fullscreenBannerDismissed) {
    state.fullscreenBannerDismissed = fullscreenBannerDismissed
  },
  SET_DAYS_TO_RENEW(state, text) {
    state.daysToRenew = text
  },
}

export const getters = {
  accountNumber(state, getters, rootState, rootGetters) {
    if (rootGetters.account && rootGetters.account.number) {
      return rootGetters.account.number
    } else {
      return 0
    }
  },
  customerForename(state, getters, rootState) {
    return rootState.junifer.customer ? rootState.junifer.customer.forename : ""
  },
  supplyStartDates(state, getters, rootState) {
    const meterpoints =
      rootState.junifer.accounts[rootState.currentAccount].meterpoints

    return Object.keys(meterpoints).map((key) => {
      return meterpoints[key].supplyStartDate
    })
  },
  outCode(state, getters, rootState) {
    const postcode =
      rootState.junifer.accounts[rootState.currentAccount]?.postcode
    if (!postcode) return null
    return postcode.replace(/\s+/g, "").slice(0, -3)
  },
}
