import { createRouter, createWebHistory } from "vue-router"
import AuthenticationService from "@soenergy/frontend-library/src/services/AuthenticationService"
import { isRefreshTokenAvailable } from "@soenergy/frontend-library/src/services/AuthenticationService/refreshToken"
import ErrorLayout from "@soenergy/frontend-library/src/components/ErrorLayout"
import { isAbsoluteUrl } from "@soenergy/frontend-library/src/helpers/urlHelper"
import renewalStatusHelper from "@soenergy/frontend-library/src/helpers/renewalStatusHelper"
import store from "./store/store.js"
import processStandaloneRenewalParameter from "./router/processStandaloneRenewalParameter"
import redirectToNexusLoginPage from "./router/redirectToNexusLoginPage"
import meterpointStatus from "./methods/meterpointStatus"
import EventBus from "./methods/eventBus"
import processRedirectParameter from "./router/processRedirectParameter"
import redirectToReadLogin from "./router/redirectToReadLogin"
import TrackingService from "@soenergy/frontend-library/src/services/TrackingService"

// Route imports
import Login from "./views/Login.vue"
const ReadLoginIntro = () =>
  import(/* webpackChunkName:  "home" */ "./views/ReadLoginIntro.vue")
const ReadLoginSubmit = () =>
  import(/* webpackChunkName:  "home" */ "./views/ReadLoginSubmit.vue")
const Home = () => import(/* webpackChunkName:  "home" */ "./views/Home.vue")
const More = () => import(/* webpackChunkName: "more" */ "./views/More.vue")
const ChooseAccount = () =>
  import(/* webpackChunkName:  "choose" */ "./views/ChooseAccount.vue")
const LoginError = () =>
  import(/* webpackChunkName:  "login-error" */ "./views/LoginError.vue")
const ActivateAccount = () =>
  import(
    /* webpackChunkName: "activate-account" */ "./views/ActivateAccount.vue"
  )
const PasswordReset = () =>
  import(/* webpackChunkName: "password-reset" */ "./views/PasswordReset.vue")
const PasswordChange = () =>
  import(/* webpackChunkName: "password-change" */ "./views/PasswordChange.vue")
const PasswordCreate = () =>
  import(/* webpackChunkName: "set-password" */ "./views/PasswordCreate.vue")
const ConfirmEmailChange = () =>
  import(
    /* webpackChunkName: "confirm-email-change" */ "./views/ConfirmEmailChange.vue"
  )
const SwitchCancellation = () =>
  import(
    /* webpackChunkName: "switch-cancellation" */ "./views/SwitchCancellation.vue"
  )
const SwitchProgress = () =>
  import(/* webpackChunkName: "switch-progress" */ "./views/SwitchProgress.vue")
const SwitchCancelled = () =>
  import(
    /* webpackChunkName: "switch-cancelled" */ "./views/SwitchCancelled.vue"
  )
const Refer = () => import(/* webpackChunkName: "refer" */ "./views/Refer.vue")
const PersonalDetails = () =>
  import(
    /* webpackChunkName: "personal-details" */ "./views/PersonalDetails.vue"
  )
const Agreements = () =>
  import(/* webpackChunkName: "agreements" */ "./views/Agreements.vue")
const EnergyUsage = () =>
  import(/* webpackChunkName: "usage" */ "./views/EnergyUsage.vue")
const MeterReading = () =>
  import(/* webpackChunkName: "readings" */ "./views/MeterReading.vue")
const Payments = () =>
  import(/* webpackChunkName: "payments" */ "./views/Payments.vue")
const MakePayment = () =>
  import(/* webpackChunkName: "make-payment" */ "./views/MakePayment.vue")
const PaymentSuccessful = () =>
  import(
    /* webpackChunkName: "payment-successful" */ "./views/PaymentSuccessful.vue"
  )
const PaymentFailed = () =>
  import(
    /* webpackChunkName: "payment-successful" */ "./views/PaymentFailed.vue"
  )
const DebtPaymentPlanIntro = () =>
  import(
    /* webpackChunkName: "debt-payment-plan-intro" */ "./views/DebtPaymentPlanIntro.vue"
  )
const BalanceStatements = () =>
  import(
    /* webpackChunkName: "balance-statements" */ "./views/BalanceStatements.vue"
  )
const Renew = () => import(/* webpackChunkName: "renew" */ "./views/Renew.vue")
const RenewError = () =>
  import(/* webpackChunkName: "renew-error" */ "./views/RenewError.vue")

const RenewConfirmation = () =>
  import(
    /* webpackChunkName: "renew-confirmation" */ "./views/RenewConfirmation.vue"
  )
const Help = () => import(/* webpackChunkName: "help" */ "./views/Help.vue")
const ReportBug = () =>
  import(/* webpackChunkName: "report-bug" */ "./views/ReportBug.vue")
const Emergency = () =>
  import(/* webpackChunkName: "emergency" */ "./views/Emergency.vue")
const ReferTerms = () =>
  import(/* webpackChunkName: "refer-terms" */ "./views/ReferTerms.vue")
const Psr = () =>
  import(/* webpackChunkName: "refer-terms" */ "./views/Psr.vue")
const HomeCover = () =>
  import(/*  webpackChunkName: "home-cover" */ "./views/HomeCover.vue")
const ContactUs = () =>
  import(/*  webpackChunkName: "contact-us" */ "./views/ContactUs.vue")
const CotMoveOutDate = () =>
  import(
    /* webpackChunkName:  "cot-move-out-date" */ "./views/cotMoveOut/CotMoveOutDate.vue"
  )
const CotMoveOutFlowContainer = () =>
  import(
    /* webpackChunkName:  "cot-move-out-container" */ "./views/cotMoveOut/CotMoveOutFlowContainer.vue"
  )
const COTMovedOut = () =>
  import(
    /*  webpackChunkName: "cot-customer-moved-out" */ "./views/cotMoveOut/COTMovedOut.vue"
  )
const CotMoveOutOccupancyInfo = () =>
  import(
    /*  webpackChunkName: "cot-occupancy-info" */ "./views/cotMoveOut/CotMoveOutOccupancyInfo.vue"
  )
const CotMoveOutSubmitRead = () =>
  import(
    /*  webpackChunkName: "cot-submit-read" */ "./views/cotMoveOut/CotMoveOutSubmitRead.vue"
  )
const CotMoveOutForwardingAddress = () =>
  import(
    /*  webpackChunkName: "cot-forwarding-address" */ "./views/cotMoveOut/CotMoveOutForwardingAddress.vue"
  )
const CotMoveOutSummary = () =>
  import(
    /*  webpackChunkName: "cot-summary" */ "./views/cotMoveOut/CotMoveOutSummary.vue"
  )
const CotMoveCompleted = () =>
  import(
    /*  webpackChunkName: "cot-completed" */ "./views/cotMoveOut/CotMoveOutCompleted.vue"
  )
const CotMoveOutError = () =>
  import(
    /*  webpackChunkName: "cot-error" */ "./views/cotMoveOut/CotMoveOutError.vue"
  )
const CotMoveWithUsContainer = () =>
  import(
    /*  webpackChunkName: "move-with-us" */ "./views/cotMoveWithUs/CotMoveWithUsContainer.vue"
  )
const CotMoveWithUsPropertyDetails = () =>
  import(
    /*  webpackChunkName: "property-details" */ "./views/cotMoveWithUs/CotMoveWithUsPropertyDetails.vue"
  )
const CotMoveWithUsSelectTariff = () =>
  import(
    /*  webpackChunkName: "select-tariff" */ "./views/cotMoveWithUs/CotMoveWithUsSelectTariff.vue"
  )
const CotMoveWithUsPaymentDetails = () =>
  import(
    /*  webpackChunkName: "payment-details" */ "./views/cotMoveWithUs/CotMoveWithUsPaymentDetails.vue"
  )
const RenewalInterest = () =>
  import(
    /*  webpackChunkName: "renewal-interest" */ "./views/RenewalInterest.vue"
  )
const CotMoveOutStatus = () =>
  import(
    /*  webpackChunkName: "cot-move-out-status" */ "./views/cotMoveOut/CotMoveOutStatus.vue"
  )
const CotMoveWithUsCompleted = () =>
  import(
    /*  webpackChunkName: "cot-move-with-us-completed" */ "./views/cotMoveWithUs/CotMoveWithUsCompleted.vue"
  )
const Unsubscribe = () =>
  import(/* webpackChunkName: "unsubscribe" */ "./views/Unsubscribe.vue")
const BookingConfirmed = () =>
  import(
    /*  webpackChunkName: "booking-confirmed" */ "./views/BookingConfirmed.vue"
  )

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/choose",
      name: "choose",
      component: ChooseAccount,
      meta: {
        requiresAuth: true,
        title: "Select account",
        showAppTitleBar: true,
        admitsIntoAccount: true,
      },
    },
    {
      path: "/",
      name: "account-home",
      component: Home,
      meta: {
        requiresAuth: true,
        showAppTitleBar: true,
        title: "Account home",
      },
    },
    {
      path: "/:pathMatch(.*)*",
      component: ErrorLayout,
      props: {
        variant: "notFoundError",
      },
    },
    {
      path: "/network-error",
      name: "network-error",
      component: ErrorLayout,
      props: {
        variant: "networkError",
      },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        requiresAuth: false,
        requiresAnon: true,
        admitsIntoAccount: true,
      },
    },
    {
      path: "/login-error",
      name: "login-error",
      component: LoginError,
      meta: {
        title: "Login error",
      },
    },
    {
      path: "/activate-account",
      name: "activate-account",
      component: ActivateAccount,
    },
    {
      path: "/password-reset",
      name: "password-reset",
      component: PasswordReset,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/password-change",
      name: "password-change",
      component: PasswordChange,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/set-password",
      name: "set-password",
      component: PasswordCreate,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/confirm-email-change",
      name: "confirm-email-change",
      component: ConfirmEmailChange,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/logout",
      name: "logout",
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/more",
      name: "more",
      component: More,
      meta: {
        requiresAuth: true,
        title: "More",
        showAppTitleBar: true,
      },
    },
    {
      path: "/switch-cancellation",
      name: "switch-cancellation",
      component: SwitchCancellation,
      meta: {
        requiresAuth: true,
        requiresCheck: false,
      },
    },
    {
      path: "/progress",
      name: "progress",
      component: SwitchProgress,
      meta: {
        requiresAuth: true,
        requiresCheck: true,
        hideAccountBanner: true,
      },
    },
    {
      path: "/switch-cancelled",
      name: "switch-cancelled",
      component: SwitchCancelled,
      meta: {
        requiresAuth: true,
        requiresCheck: true,
      },
    },
    {
      path: "/refer",
      name: "refer",
      component: Refer,
      meta: {
        requiresAuth: true,
        title: "Refer a friend",
        showAppTitleBar: true,
        requiresCheck: true,
      },
    },
    {
      path: "/personal-details",
      name: "personal-details",
      component: PersonalDetails,
      meta: {
        requiresAuth: true,
        title: "Personal details",
        showAppTitleBar: true,
      },
    },
    {
      path: "/tariff",
      name: "tariff",
      component: Agreements,
      meta: {
        requiresAuth: true,
        title: "Tariff details",
        showAppTitleBar: true,
        faqTags: ["tariffs"],
      },
    },
    {
      path: "/readings",
      name: "readings",
      meta: {
        requiresAuth: true,
        title: "Submit a meter reading",
        showAppTitleBar: true,
        faqTags: ["metering"],
      },
      component: MeterReading,
    },
    {
      path: "/usage",
      name: "usage",
      meta: {
        requiresAuth: true,
        title: "Energy Usage",
      },
      component: EnergyUsage,
    },
    {
      path: "/payments",
      name: "payments",
      component: Payments,
      meta: {
        requiresAuth: true,
        title: "Payments",
        showAppTitleBar: true,
        faqTags: ["payments"],
      },
    },
    {
      path: "/payments/new",
      name: "make-payment",
      component: MakePayment,
      meta: {
        requiresAuth: true,
        title: "Payments",
        showAppTitleBar: true,
        faqTags: ["payments"],
      },
    },
    {
      path: "/payments/payment-successful",
      name: "payment-successful",
      component: PaymentSuccessful,
      meta: {
        requiresAuth: true,
        title: "Payments",
        showAppTitleBar: true,
        faqTags: ["payments"],
      },
    },
    {
      path: "/payments/payment-failed",
      name: "payment-failed",
      component: PaymentFailed,
      meta: {
        requiresAuth: true,
        title: "Payments",
        showAppTitleBar: true,
        faqTags: ["payments"],
      },
    },
    {
      path: "/debt-payment-plan-intro",
      name: "debt-payment-plan-intro",
      component: DebtPaymentPlanIntro,
      meta: {
        requiresAuth: true,
        title: "Debt Payment Plan",
        showAppTitleBar: true,
        faqTags: ["payments"],
      },
    },
    {
      path: "/balance",
      name: "balance",
      component: BalanceStatements,
      meta: {
        requiresAuth: true,
        title: "Balance and statements",
        showAppTitleBar: true,
        faqTags: ["payments"],
      },
    },
    {
      path: "/renew",
      name: "renew",
      component: Renew,
      meta: {
        requiresAuth: true,
        requiresCheck: true,
        title: "Get a new fixed tariff",
        showAppTitleBar: true,
        faqTags: ["tariffs"],
      },
    },
    {
      path: "/renew-error",
      name: "renew-error",
      component: RenewError,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/renew-confirmation",
      name: "renew-confirmation",
      component: RenewConfirmation,
      meta: {
        requiresAuth: true,
        requiresCheck: true,
        faqTags: ["tariffs"],
      },
    },
    {
      path: "/help",
      name: "help",
      component: Help,
      meta: {
        requiresAuth: false,
        title: "Help",
        showAppTitleBar: true,
      },
    },
    {
      path: "/emergency",
      name: "emergency",
      component: Emergency,
      meta: {
        requiresAuth: false,
        title: "Emergency",
        showAppTitleBar: true,
      },
    },
    {
      path: "/report-bug",
      name: "report-bug",
      component: ReportBug,
      meta: {
        requiresAuth: false,
        title: "Report a bug",
        showAppTitleBar: true,
      },
    },
    {
      path: "/refer-terms",
      name: "refer-terms",
      component: ReferTerms,
      meta: {
        requiresAuth: false,
        title: "Refer a Friend Terms and Conditions",
        showAppTitleBar: true,
      },
    },
    {
      path: "/psr",
      name: "psr",
      component: Psr,
      meta: {
        requiresAuth: true,
        title: "Priority Service Register",
        showAppTitleBar: true,
      },
    },
    {
      path: "/read-login",
      name: "read-login",
      component: ReadLoginIntro,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/submit-read",
      name: "submit-read",
      component: ReadLoginSubmit,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/home-cover",
      name: "home-cover",
      component: HomeCover,
      meta: {
        requiresAuth: false,
        title: "Home Cover",
        showAppTitleBar: true,
      },
    },
    {
      path: "/contact-us",
      name: "contact-us",
      component: ContactUs,
      meta: {
        requiresAuth: false,
        title: "Contact Us",
        showAppTitleBar: true,
      },
    },
    {
      path: "/move-out/status",
      name: "move-out-status",
      component: CotMoveOutStatus,
      meta: {
        requiresAuth: true,
        hideAccountBanner: true,
      },
    },
    {
      path: "/move-out",
      name: "move-out",
      component: CotMoveOutFlowContainer,
      meta: {
        requiresCheck: true,
        requiresAuth: true,
      },
      children: [
        {
          path: "date",
          name: "move-out-date",
          component: CotMoveOutDate,
          meta: {
            requiresAuth: true,
            hideAccountBanner: true,
          },
        },
        {
          path: "occupancy-info",
          name: "move-out-occupancy-info",
          component: CotMoveOutOccupancyInfo,
          meta: {
            requiresAuth: true,
            hideAccountBanner: true,
          },
        },
        {
          path: "submit-reading",
          name: "move-out-submit-read",
          component: CotMoveOutSubmitRead,
          meta: {
            requiresAuth: true,
            hideAccountBanner: true,
          },
        },
        {
          path: "forwarding-address",
          name: "move-out-forwarding-address",
          component: CotMoveOutForwardingAddress,
          meta: {
            requiresAuth: true,
            hideAccountBanner: true,
          },
        },
        {
          path: "summary",
          name: "move-out-summary",
          component: CotMoveOutSummary,
          meta: {
            requiresAuth: true,
            hideAccountBanner: true,
          },
        },
      ],
    },
    {
      path: "/moved-out",
      name: "customer-moved-out",
      component: COTMovedOut,
      meta: {
        requiresAuth: true,
        showAppTitleBar: true,
        title: "You have moved out",
      },
    },
    {
      path: "/register-renewal-interest",
      name: "register-renewal-interest",
      component: RenewalInterest,
      meta: {
        requiresAuth: true,
        showAppTitleBar: true,
        title: "Register renewal interest",
      },
    },
    {
      path: "/move-out-completed",
      name: "move-out-completed",
      component: CotMoveCompleted,
      meta: {
        requiresAuth: true,
        showAppTitleBar: true,
        hideAccountBanner: true,
      },
    },
    {
      path: "/move-with-us-completed",
      name: "move-with-us-completed",
      component: CotMoveWithUsCompleted,
      meta: {
        requiresAuth: true,
        showAppTitleBar: true,
        hideAccountBanner: true,
      },
    },
    {
      path: "/move-out-error",
      name: "move-out-error",
      component: CotMoveOutError,
      meta: {
        requiresAuth: true,
        showAppTitleBar: true,
        hideAccountBanner: true,
      },
    },
    {
      path: "/move-with-us",
      name: "move-with-us",
      component: CotMoveWithUsContainer,
      meta: {
        requiresCheck: true,
        requiresAuth: true,
        showAppTitleBar: true,
        title: "Move with us",
      },
      children: [
        {
          path: "property-details",
          name: "property-details",
          component: CotMoveWithUsPropertyDetails,
          meta: {
            requiresAuth: true,
            hideAccountBanner: true,
          },
        },
        {
          path: "select-tariff",
          name: "select-tariff",
          component: CotMoveWithUsSelectTariff,
          meta: {
            requiresAuth: true,
            hideAccountBanner: true,
          },
        },
        {
          path: "payment-details",
          name: "payment-details",
          component: CotMoveWithUsPaymentDetails,
          meta: {
            requiresAuth: true,
            hideAccountBanner: true,
          },
        },
      ],
    },
    {
      path: "/unsubscribe",
      name: "unsubscribe",
      component: Unsubscribe,
      meta: {
        requiresAuth: false,
        showAppTitleBar: true,
        hideAccountBanner: true,
      },
    },
    {
      path: "/booking-confirmed",
      name: "booking-confirmed",
      component: BookingConfirmed,
      meta: {
        requiresAuth: true,
      },
    },
  ],

  scrollBehavior(to) {
    if (to.hash) {
      const targetEl = document.getElementById(to.hash.substring(1))
      if (targetEl) {
        const navbarHeight = 80

        return {
          el: to.hash,
          behavior: "smooth",
          top: navbarHeight,
        }
      } else {
        return { top: 0 }
      }
    } else {
      return { top: 0 }
    }
  },
})

router.beforeEach(redirectToReadLogin)
router.beforeEach(processStandaloneRenewalParameter)
router.beforeEach(processRedirectParameter)
router.beforeEach(redirectToNexusLoginPage)
// Display loading indicator when fetching lazy loaded route components
router.beforeEach((to, from, next) => {
  const initialPageLoad = from.name === undefined

  if (!initialPageLoad || isRefreshTokenAvailable()) {
    store.commit("UPDATE_LOADING", true)
  }
  next()
})

router.afterEach(() => {
  if (!store.state.juniferDataRefreshPending) {
    store.commit("UPDATE_LOADING", false)
  }
})

router.beforeEach(async (routeTo, routeFrom, next) => {
  actionsBeforeEach()
  const routeRequiresAuth = routeTo.matched.some(
    (record) => record.meta.requiresAuth
  )
  const routeRequiresAnon = routeTo.matched.some(
    (record) => record.meta.requiresAnon
  )
  const isFirstVisitedPage = routeFrom.name === undefined
  let isStorePopulated = store.state.junifer && store.state.currentAccount
  let isAuthenticated = null
  if (routeRequiresAuth || routeRequiresAnon) {
    isAuthenticated =
      await AuthenticationService.checkIfAuthenticatedAndFetchTokenIfNeeded()
  }
  if ((routeRequiresAuth && !isAuthenticated) || routeTo.name === "logout") {
    if (AuthenticationService.getAccessToken() || isStorePopulated) {
      await store.dispatch("tryLogout")
    }
    const blockedVisitToSubroute =
      routeTo.matched.length &&
      routeTo.path !== "/" &&
      routeTo.name !== "logout"
    if (blockedVisitToSubroute) {
      TrackingService.sendEvent("redirectToLogin")
      store.commit("SET_LOGIN_REDIRECT_PATH", routeTo.fullPath)
    }
    goToNextPage(routeFrom.name, "login", next)
  } else if (routeRequiresAnon && isAuthenticated) {
    goToNextPage(routeFrom.name, "/", next)
  } else {
    if (
      routeRequiresAuth &&
      isAuthenticated &&
      !store.state.readLogin.eligibleForReadLogin
    ) {
      if (!isStorePopulated) {
        try {
          await populateData()
        } catch (error) {
          if (error.message === "adminLogin") {
            return
          }
          goToNextPage(routeFrom.name, "login-error", next)
          store.dispatch("logout")
          throw error
        }
      } else if (isFirstVisitedPage && !store.getters["isJuniferDataCurrent"]) {
        await store.dispatch("fetchJuniferDataAndRefreshIfNeeded")
      }
      if (isFirstVisitedPage || !isStorePopulated) {
        EventBus.emit("userDataLoaded")
      }
      isStorePopulated = store.state.junifer && store.state.currentAccount
      if (
        isStorePopulated &&
        store.getters["meters/hasPrepaymentMeter"] &&
        routeFrom.name !== "login" &&
        !routeTo.meta.admitsIntoAccount
      ) {
        window.location.assign(`${process.env.VUE_APP_WEBSITE_URL}/prepayment`)
      } else if (
        routeTo.name === "account-home" &&
        store.state.loginRedirectPath
      ) {
        TrackingService.sendEvent("loginRedirectSuccessful")
        goToNextPage(routeFrom.name, store.state.loginRedirectPath, next)
        store.commit("SET_LOGIN_REDIRECT_PATH", null)
        return
      }
    }

    // check if the page requested requires any further validation
    if (
      routeTo.meta.requiresCheck ||
      (routeFrom.meta.admitsIntoAccount && routeTo.name === "account-home")
    ) {
      // validate route
      let pageToGo = await calculateRoute(routeTo, routeFrom)
      goToNextPage(routeFrom.name, pageToGo, next, routeTo)
    } else {
      // do not validate route
      next()
    }
  }
})

let actionsBeforeEach = () => {
  // run this before each route entry (before any checks)
  const logoutModalOpen = store.getters["modals/isLogoutModalOpen"]
  if (logoutModalOpen) {
    store.dispatch("modals/closeLogoutModal")
  }
}

let populateData = async () => {
  return await store.dispatch("afterLogin")
}

let calculateRoute = async (routeTo, routeFrom) => {
  let route = "/"

  switch (routeTo.name) {
    case "account-home":
      route = calculateHomeRoute()
      break
    case "progress":
      route = calculateProgressRoute()
      break
    case "switch-cancelled":
      route = calculateCancelledRoute(routeFrom)
      break
    case "renew":
    case "renew-confirmation":
      route = calculateRenewRoute(routeTo)
      break
    case "refer":
      route = calculateReferRoute()
      break
    case "move-out":
    case "move-out-date":
    case "move-out-occupancy-info":
    case "move-out-submit-read":
    case "move-out-forwarding-address":
    case "move-out-summary":
      route = await calculateMoveOutRoute(routeTo, routeFrom)
      break
    case "move-with-us":
    case "property-details":
    case "select-tariff":
    case "payment-details":
      route = await calculateMoveWithUsRoute(routeTo, routeFrom)
      break
    case "choose":
      route = calculateChooseAccountRoute(routeFrom)
  }
  const hasDestinationChanged = route !== routeTo.path
  if (hasDestinationChanged) return route
}

let calculateChooseAccountRoute = (routeFrom) => {
  if (routeFrom.name === "login" || !routeFrom.name) {
    return "choose"
  } else {
    return "/"
  }
}

let calculateMoveOutRoute = async (routeTo, routeFrom) => {
  if (!store.state.isCotMoveOutJourneyEnabled) return "/"
  const accounts = store.getters["chooseAccount/accounts"]
  const currentAccountClosingDate =
    store.getters["getCurrentAccountClosingDate"]

  const hasNotSelectedAccount = routeFrom.name === "login" || !routeFrom.name

  if (hasNotSelectedAccount && accounts.length > 1) {
    store.commit("SET_LOGIN_REDIRECT_PATH", routeTo.fullPath)
    return "choose"
  }
  const phone1 = store.state.user.customer?.primaryContact?.phoneNumber1
  if (!phone1) {
    window.location.assign(store.state.cotMoveOutTypeformUrl)
    return
  }

  const cameFromCotPage = routeFrom?.matched?.[0]?.name === "move-out"
  const customerMovedOut = !!currentAccountClosingDate
  const hasMeterpoints = store.getters["meters/hasMeterpoints"]

  if (cameFromCotPage) return
  if (customerMovedOut || !hasMeterpoints) return "moved-out"

  try {
    await store.dispatch("cotMoveOut/ensureCotMoveOutRecordFetched")
    await store.dispatch("cotMoveWithUs/ensureCotMoveWithUsRecordFetched")
  } catch (_) {
    return "/"
  }
  const moveOutRecord = store.state.cotMoveOut.moveOutRecord
  if (moveOutRecord && !moveOutRecord.isFailed) {
    return "/move-out/status"
  } else {
    return "/move-out/date"
  }
}

let calculateMoveWithUsRoute = async (routeTo, routeFrom) => {
  if (!store.state.isCotMoveWithUsJourneyEnabled) return "/"

  try {
    await store.dispatch("cotMoveOut/ensureCotMoveOutRecordFetched")
  } catch (error) {
    // Can continue without this data
  }

  const cameFromMWU = routeFrom?.matched?.[0]?.name === "move-with-us"
  if (cameFromMWU) return

  return "/move-with-us/property-details"
}

let calculateHomeRoute = () => {
  let route
  let accounts = store.getters["chooseAccount/accounts"]
  let isSwitchCancellationInProgress =
    store.getters["switchIn/isSwitchCancellationInProgress"]

  if (store.getters["meters/hasPrepaymentMeter"] && accounts.length === 1) {
    route = `${process.env.VUE_APP_WEBSITE_URL}/prepayment`
  } else if (isSwitchCancellationInProgress && accounts.length === 1) {
    route = isSwitchCancellationInProgress ? "switch-cancelled" : undefined
  } else if (accounts.length === 1) {
    const isSwitchInProgress = store.getters["switchIn/isSwitchInProgress"]
    route = isSwitchInProgress ? "progress" : undefined
  }
  return route
}

let calculateProgressRoute = () => {
  const switchPageEnter = store.getters["switchIn/isSwitchInProgress"]
  const isSwitchCancellationInProgress =
    store.getters["switchIn/isSwitchCancellationInProgress"]

  if (isSwitchCancellationInProgress) {
    return "switch-cancelled"
  } else {
    return switchPageEnter ? undefined : "/" // go to progress or home
  }
}

let calculateCancelledRoute = (routeFrom) => {
  const meterpoints = store.getters["switchIn/meterpoints"]
  const cancelledMeterpoints =
    meterpoints.length &&
    meterpoints.some((meter) => meterpointStatus.isSwitchCancelled(meter))
  const isSwitchCancellationInProgress =
    store.getters["switchIn/isSwitchCancellationInProgress"]

  const fromSwitchCancellation = routeFrom.name === "switch-cancellation"
  return fromSwitchCancellation ||
    cancelledMeterpoints ||
    isSwitchCancellationInProgress
    ? undefined
    : "/"
}

let calculateRenewRoute = (routeTo) => {
  let route
  const anyRenewal = store.getters["agreements/anyAgreementInRenewal"]
  const allInRenewalRenewed = store.getters["agreements/allInRenewalRenewed"]
  const anyOutOfContract =
    store.getters["agreements/anyAgreementIsOutOfContract"]
  const account = store.getters["account"]
  const user = store.state["user"]

  if (
    ((anyRenewal && !allInRenewalRenewed) || anyOutOfContract) &&
    !renewalStatusHelper.hasPendingRenewal(user, account.number)
  ) {
    const allRenewableAgreementsHaveValidConsumption =
      store.getters["renew/allRenewableAgreementsHaveValidConsumption"]
    if (!allRenewableAgreementsHaveValidConsumption) {
      route = "renew-error"
    } else if (
      routeTo.name === "renew" ||
      routeTo.name === "renew-confirmation"
    ) {
      route = null // continue to renew
    } else {
      route = "renew"
    }
  } else {
    route = "/"
  }
  return route
}

let calculateReferRoute = () => {
  const userStatus = store.state.userStatus.userStatus

  if (userStatus) {
    const referPageEnter =
      !userStatus.referDisabledGlobally && !userStatus.blacklistedVoucher
    return referPageEnter ? undefined : "/" // go to refer or home
  } else {
    return "/" // if user is logged in we should always have userStatus, but just to cover the case I added this line
  }
}

let isNewRouteDifferent = (routeFrom, pageToGo) => {
  return !(
    (routeFrom === "account-home" && pageToGo === "/") ||
    routeFrom === pageToGo
  )
}

let goToNextPage = (routeFrom, pageToGo, next, routeTo) => {
  // dont attempt to go to the same page
  if (
    isNewRouteDifferent(routeFrom, pageToGo) ||
    (!!routeTo && pageToGo !== routeTo.path)
  ) {
    if (isAbsoluteUrl(pageToGo)) {
      window.location.assign(pageToGo)
    } else {
      next(pageToGo)
    }
  } else {
    next()
    store.commit("UPDATE_LOADING", false)
  }
}

export default router
