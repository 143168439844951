<template>
  <div class="password-input">
    <InputField
      v-bind="$attrs"
      :type="passwordFieldType"
      @input="emitInputEvent"
    />
    <ShowPassword
      :input-name="$attrs.name"
      @show-pass-changed="showPassword = $event"
    />
  </div>
</template>

<script>
import InputField from "@soenergy/frontend-library/src/components/InputField"
import ShowPassword from "@/components/ShowPassword.vue"

export default {
  components: {
    InputField,
    ShowPassword,
  },
  inheritAttrs: false,
  emits: ["input"],
  data: () => ({ showPassword: false }),
  computed: {
    passwordFieldType() {
      return this.showPassword ? "text" : "password"
    },
  },
  methods: {
    emitInputEvent(value) {
      const trimmedValue = typeof value === "string" ? value.trim() : value
      this.$emit("input", trimmedValue)
    },
  },
}
</script>

<style scoped lang="scss">
.password-input {
  position: relative;

  @include sm {
    max-width: 408px;
  }
}
</style>
