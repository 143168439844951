import WHDEligibilityApiService from "./WHDEligibilityApiService"
import storeModule from "./storeModule"

let store = null
let moduleName = null

const init = ({
  store: applicationStore,
  storeModuleName = "whdRegionEligibility"
}) => {
  applicationStore.registerModule(storeModuleName, storeModule)
  store = applicationStore
  moduleName = storeModuleName
}

const getWHDRegionEligibility = async accountId => {
  const response = await WHDEligibilityApiService.getWHDRegionEligibility(
    accountId
  )
  let whdRegionEligibility =
    response.data && response.data.hasOwnProperty("eligible")
      ? response.data.eligible
      : false

  setWHDRegionEligibility(whdRegionEligibility)
  return whdRegionEligibility
}

const setWHDRegionEligibility = value => {
  store.commit(`${moduleName}/SET_WHD_REGION_ELIGIBILITY`, value)
}

export default {
  init,
  getWHDRegionEligibility
}
