import dayjs from "dayjs"

export const showAccount = (account, agreements) => {
  const maxDaysToIssueFinalBill = 40
  const agreementArray = agreements
    .map((agreement) => ({ ...agreement }))
    .filter((agreement) => !agreement.cancelled)
  const latestAgreement = agreementArray.reduce(
    (a, b) => (a.fromDt > b.fromDt ? a : b),
    {}
  )

  if (latestAgreement?.agreementType?.duration === 0) {
    latestAgreement.toDt = new Date()
  }

  const accountClosingDate =
    account.toDt ?? account.closedDttm ?? latestAgreement.toDt

  const finalBillDeadline = dayjs().subtract(maxDaysToIssueFinalBill, "day")

  const isAccountClosed = accountClosingDate
    ? dayjs(accountClosingDate, "YYYY-MM-DD").isBefore(finalBillDeadline)
    : false

  return !(account.balance === 0 && isAccountClosed)
}
