import { datesMixin } from "@/mixins/datesMixin.js"

export default {
  isSwitchRejected({ supplyEventType }) {
    return supplyEventType === "RegistrationRejected"
  },
  isSwitchObjected({ supplyEventType, supplyStatus }) {
    return (
      supplyEventType === "RegistrationObjectionRaised" ||
      supplyEventType === "RegistrationObjectionUpheld" ||
      supplyStatus === "RegistrationObjected"
    )
  },
  isSwitchCancelled({ supplyEventType }) {
    return [
      "RegistrationWithdrawn",
      "RegistrationWithdrawRequested",
      "RegistrationCancelled",
    ].includes(supplyEventType)
  },
  isSwitchStopped({ supplyEventType, supplyStatus }) {
    const stoppedManuallyByAgent =
      supplyEventType === "ManualUpdate" && supplyStatus === "NotSupplied"

    return (
      this.isSwitchRejected({ supplyEventType, supplyStatus }) ||
      this.isSwitchObjected({ supplyEventType, supplyStatus }) ||
      this.isSwitchCancelled({ supplyEventType, supplyStatus }) ||
      ["LossNotified", "LossCompleted"].includes(supplyStatus) ||
      stoppedManuallyByAgent
    )
  },
  isSwitchingIn({ supplyStartDate, supplyEventType }) {
    return (
      datesMixin.methods.daysFromStartDate(supplyStartDate) > 0 &&
      !this.isSwitchCancelled({ supplyEventType })
    )
  },
}
