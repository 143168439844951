import dayjs, { Dayjs } from "dayjs"
import {
  SEASONAL_ADJUSTMENT_WINTER,
  SEASONAL_ADJUSTMENT_SUMMER,
  MINIMUM_CREDIT_TO_RENEW_WITH_CREDIT_IN_POUNDS,
} from "@/constants"
import {
  getAnnualCost,
  getAnnualStandingChargeCost,
} from "@/helpers/agreementCostHelper"
import { IAgreement } from "@/types/IAgreement"
import { IUsageEstimation } from "@/types/IUsageEstimation"
import { Meterpoint } from "./Meterpoint"
import { Tariff } from "./Tariff"

export class RenewMeterpointAgreement {
  meterpoint: Meterpoint
  currentAgreement: IAgreement
  consumption: IUsageEstimation
  tariff: Tariff

  constructor(
    tariff: Tariff,
    agreement: IAgreement,
    meterpoint: Meterpoint,
    consumption: IUsageEstimation
  ) {
    this.meterpoint = meterpoint
    this.currentAgreement = agreement
    this.consumption = consumption
    this.tariff = tariff
  }

  get expectedStartDate(): Dayjs | null {
    const possibleDates: Dayjs[] = [this.tariff.expectedStartDate]

    if (this.currentAgreement.isVariable) possibleDates.push(dayjs())
    else possibleDates.push(dayjs(this.currentAgreement.toDt))

    return dayjs.max(possibleDates)
  }

  get expectedEndDate(): Dayjs | null {
    return this.tariff.durationMonths
      ? dayjs(this.expectedStartDate)
          .add(this.tariff.durationMonths, "months")
          .subtract(1, "days")
      : null
  }

  get annualCost(): number {
    return getAnnualCost(
      this.currentAgreement.isTwoRate,
      this.consumption,
      this.tariff
    )
  }

  get annualStandingChargeCost(): number {
    return getAnnualStandingChargeCost(this.tariff.standingCharge)
  }
}

export class RenewQuote {
  agreements: RenewMeterpointAgreement[]
  tariff: Tariff
  positiveAccountBalance: number

  constructor(tariff, meterpointAgreements, accountBalance) {
    this.agreements = meterpointAgreements.map(
      ({ tariff, agreement, meterpoint, consumption }) => {
        return new RenewMeterpointAgreement(
          tariff,
          agreement,
          meterpoint,
          consumption
        )
      }
    )
    this.tariff = tariff
    this.positiveAccountBalance = accountBalance > 0 ? accountBalance : 0
  }

  get hasGas(): boolean {
    return !!this.allGasAgreements.length
  }

  get hasElec(): boolean {
    return !!this.allElecAgreements.length
  }

  get allGasAgreements(): RenewMeterpointAgreement[] {
    return this.agreements.filter(
      (agreement) => !agreement.meterpoint.isElectricity
    )
  }

  get allElecAgreements(): RenewMeterpointAgreement[] {
    return this.agreements.filter(
      (agreement) => agreement.meterpoint.isElectricity
    )
  }

  get hasSmartMeter(): boolean {
    return this.agreements.some((agr) => agr.meterpoint.hasSmartMeter)
  }

  get hasOnlySmartMeters(): boolean {
    return this.agreements.every((agr) => agr.meterpoint.hasOnlySmartMeters)
  }

  get fuelTypeFormatted(): string {
    if (this.hasElec && this.hasGas) return "Dual Fuel"
    else if (this.hasElec) return "Electricity only"
    else return "Gas only"
  }

  get expectedStartDate(): Dayjs | null {
    return this.agreements[0].expectedStartDate
  }

  get expectedEndDate(): Dayjs | null {
    return this.agreements[0].expectedEndDate
  }

  get isSameStartDate(): boolean {
    return this.agreements.every((agr) => {
      return dayjs(agr.currentAgreement.fromDt).isSame(
        dayjs(this.agreements[0].currentAgreement.fromDt)
      )
    })
  }

  private get annualStandingChargeCost(): number {
    return this.agreements.reduce((total, agreement) => {
      return (total += agreement.annualStandingChargeCost)
    }, 0)
  }

  private get annualCost(): number {
    return this.agreements.reduce((total, agreement) => {
      return (total += agreement.annualCost)
    }, 0)
  }

  canUseExcessCredit = (isSeasonal): boolean => {
    return (
      this.excessCredit(isSeasonal) >
      MINIMUM_CREDIT_TO_RENEW_WITH_CREDIT_IN_POUNDS
    )
  }

  excessCredit(isSeasonal) {
    // If winter month then divide annual cost by 6
    // Otherwise divide by 12
    const isWinterMonth = dayjs().month() <= 1 || dayjs().month() >= 8
    const costDivisor = isWinterMonth && !isSeasonal ? 6 : 12
    const excessCredit =
      this.positiveAccountBalance - this.annualCost / costDivisor

    return excessCredit > 0 ? excessCredit : 0
  }

  annualCostAdjusted(includeCredit: boolean, isSeasonal: boolean) {
    const annualCostAdjusted =
      this.annualCost - (includeCredit ? this.excessCredit(isSeasonal) : 0)

    return Math.max(annualCostAdjusted, this.annualStandingChargeCost)
  }

  monthlyCost(includeCredit: boolean, isSeasonal: boolean): number {
    return this.annualCostAdjusted(includeCredit, isSeasonal) / 12
  }

  monthlyCostSummer(includeCredit: boolean): number {
    return this.getAdjustedMonthlyCost(
      SEASONAL_ADJUSTMENT_SUMMER,
      includeCredit
    )
  }

  monthlyCostWinter(includeCredit: boolean): number {
    return this.getAdjustedMonthlyCost(
      SEASONAL_ADJUSTMENT_WINTER,
      includeCredit
    )
  }

  getAdjustedMonthlyCost(adjustmentFactor, includeCredit): number {
    return this.monthlyCost(includeCredit, true) * adjustmentFactor
  }
}
